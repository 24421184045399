import type { Dispatch, SetStateAction } from 'react';
import type { UseMutationResult } from '@tanstack/react-query';
import { useEffect, useMemo, useRef, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useNavigate } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import {
  HIL_OPEN_END,
  HIL_TOOL_SUBMIT_START,
  HIL_TOOL_SUBMIT_END,
  HIL_TOOL_PATCH_START,
  HIL_TOOL_PATCH_END,
  HIL_TOOL_GET_NEXT_DOCUMENT_START,
  HIL_TOOL_GET_NEXT_DOCUMENT_END,
} from 'analytics/events.ts';
import {
  updateDataLayer,
  getEventTimestamp,
  updateWindowPerformanceObject,
} from 'analytics/utils.ts';
import type { Document } from 'types/Documents';
import type { CropState } from 'components/CanvasTool';
import { toast } from 'components/toast';
import { useTranslationRoot } from 'components/with-translation';
import { useFeatureFlag } from 'components/customHooks/useFeatureFlag';
import { useNextClaimParams } from 'components/customHooks/useNextClaimParams';
import { CanvasTool } from 'components/CanvasTool';
import { HITL_TOOL_PAGE } from 'constants/translation-keys';
import { HIL_TABLE } from 'constants/table-pagination';
import { CLAIM_TABLE } from 'constants/table-pagination.ts';
import { AWAITING_REVIEW } from 'constants/claims';
import { STAGE } from 'constants/route-keys';
import hitlRoute from 'pages/HitL/hitl.route';
import {
  useGetNextDocument,
  useLockDocument,
  useLockDocumentWithQueue,
  useRejectDocument,
  useSetDocumentType,
  useSubmitDocument,
  useUnlockDocument,
} from 'state/queries/documents';
import {
  getDocumentClientDocumentType,
  getDocumentLastLockedBy,
  getDocumentLocked,
  getDocumentReasonCode,
  getDocumentType,
  getIsDocumentAwaitingReview,
  getIsDocumentReviewInProgress,
} from 'state/selectors/documents';
import claimRoute from 'pages/Claim/claim.route';
import claimsRoute from 'pages/Claims/claims.route';
import { useHitLContext } from 'pages/HitL/useHitLContext';
import { generalConfig } from 'config';
import rollbar from 'rollbar-config';
import hitlToolRoute from 'pages/HitLTool/hitlTool.route.tsx';
import { useLocales } from 'locales/useLocales.ts';
import {
  DOCUMENT_TYPE,
  FILTERS,
  QUERY,
  PAGE_ID,
  SEARCH_FIELD,
  SORT,
} from 'constants/route-keys.ts';
import claimHILToolRoute from 'pages/ClaimHILTool/claimHilTool.route.tsx';
import { toFixed } from 'utils/numbers';
import { getIsClaimAwaitingReview } from 'state/selectors/claims';
import { preWarmImageCache } from 'utils/image-cache.ts';

import type { HitLFormValues, List, Resources } from './types';
import { DocumentDialog } from './DocumentDialog';
import { HitLForm } from './HitLForm';
import { HitLFormNew } from './HitLFormNew';
import { DocumentToolbar } from './DocumentToolbar';
import { HitLToolbar } from './HitLToolbar';
import { Fallback } from './Fallback';
import {
  filterValidationResultsByFailedResult,
  hasDocumentTypeItem,
  normaliseValidationResults,
  transformFormValuesToState,
} from './utils';

type State = {
  list: List;
  resources: Resources;
};

interface FormHandlerProps {
  closeClaimHilTool?: VoidFunction;
  currentPage: number;
  currentUserEmail: string;
  document: Document;
  fromClaimView?: boolean;
  isMultipage: boolean;
  list: List;
  resources: Resources;
  setPage: Dispatch<SetStateAction<number>>;
  specificHistoryData?: Document;
  startPerformanceMeasurement: number;
}

function FormHandler({
  closeClaimHilTool,
  currentUserEmail,
  document,
  fromClaimView,
  isMultipage,
  list: propList,
  resources: propResources,
  currentPage,
  setPage,
  specificHistoryData,
  startPerformanceMeasurement,
}: FormHandlerProps) {
  const {
    incrementLockedDocument,
    decrementLockedDocument,
    removeDocumentIdFromLocalStorage,
  } = useHitLContext();
  const { resolvedLanguage } = useLocales();
  const { enableOpeningHilInNewTab } = generalConfig;
  const isNewLayout = useFeatureFlag('enableHILNewLayout');
  const enableHilNextDocument = useFeatureFlag('enableHILNextDocument');
  const enableGetNextClaim = useFeatureFlag('enableGetNextClaim');

  const searchParams = window.localStorage.getItem(HIL_TABLE);
  const [crop, setCrop] = useState<CropState | undefined>(undefined);
  const navigate = useNavigate();
  const { t } = useTranslationRoot(HITL_TOOL_PAGE);
  const { claimId, id } = document;

  const hasSubmitted = useRef(false);
  const lockDocumentQueueAction = useLockDocumentWithQueue();
  const lockDocumentAction = useLockDocument();
  const unlockDocumentAction = useUnlockDocument();
  const rejectDocument = useRejectDocument();
  const submitDocument = useSubmitDocument({
    meta: {
      loadingMessage: t('form.submitDocumentLoading') as string,
      successMessage: t('form.submitDocumentSuccess') as string,
      errorMessage: t('form.submitDocumentFail') as string,
      gtmEventStart: HIL_TOOL_PATCH_START,
      gtmEventEnd: HIL_TOOL_PATCH_END,
      documentId: document.id,
    },
  });
  const saveDocument = useSubmitDocument({
    isSave: true,
    meta: {
      loadingMessage: t('form.saveDocumentLoading') as string,
      successMessage: t('form.saveDocumentSuccess') as string,
      errorMessage: t('form.saveDocumentFail') as string,
    },
  });
  const setDocumentType = useSetDocumentType({
    meta: {
      loadingMessage: t('form.submitDocumentLoading') as string,
      successMessage: t('form.submitDocumentSuccess') as string,
      errorMessage: t('form.submitDocumentFail') as string,
    },
  });

  const nextDocumentParams = new URLSearchParams(searchParams || '');
  const getNextDocument = useGetNextDocument({
    lang: resolvedLanguage,
    documentTypeFilter: nextDocumentParams.get(DOCUMENT_TYPE),
    order: nextDocumentParams.get(SORT),
    // TODO: add search field
    searchField: nextDocumentParams.get(SEARCH_FIELD),
    searchValue: nextDocumentParams.get(QUERY),
    filters: nextDocumentParams.get(FILTERS),
    claimId,
  });
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [{ list, resources }, setContent] = useState<State>({
    list: propList,
    resources: propResources,
  });

  const ocrHistoryResource = useMemo(() => {
    if (specificHistoryData) {
      return list.reduce((acc, id) => {
        const item = resources[id];
        const { content } = specificHistoryData;
        const found = content?.find(
          (c) =>
            c.sproutaiKey === item.sproutaiKey &&
            c.pageIdx === item.pageIdx &&
            c.lineIdx === item.lineIdx
        );

        return {
          ...acc,
          [id]: found,
        };
      }, {});
    }

    return {};
  }, [list, resources, specificHistoryData]);

  const validationResults = useMemo(() => {
    const { validationResults } = document;

    if (validationResults) {
      const filteredVr =
        filterValidationResultsByFailedResult(validationResults);
      return normaliseValidationResults({
        validationResults: filteredVr,
        list,
        resources,
      });
    }

    return {};
  }, [document, list, resources]);

  const locked = getDocumentLocked(document);
  const reasonCode = getDocumentReasonCode(document);
  const clientDocumentType = getDocumentClientDocumentType(document);
  const documentType = getDocumentType(document);
  const lastLockedBy = getDocumentLastLockedBy(document);
  const hasAwaitingReviewStage = getIsDocumentAwaitingReview(document);
  const hasSaveStage = getIsDocumentReviewInProgress(document);
  const isLockedByCurrentUser = lastLockedBy === currentUserEmail;

  const getNextClaim = useNextClaimParams();

  useEffect(
    function lockDocumentOnMount() {
      if (id && !locked && (hasAwaitingReviewStage || hasSaveStage)) {
        lockDocumentAction.mutate(id);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id, locked]
  );

  useEffect(
    function unlockDocument() {
      return () => {
        const unlockableStages =
          getIsDocumentAwaitingReview(document) ||
          getIsDocumentReviewInProgress(document);

        if (
          !hasSubmitted.current &&
          unlockableStages &&
          isLockedByCurrentUser
        ) {
          unlockDocumentAction.mutate({ id });
        }
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [document.stage, hasSubmitted]
  );

  useEffect(function lockedDocumentLocalStorage() {
    const decrementLock = () => {
      decrementLockedDocument(id);
    };

    if (enableOpeningHilInNewTab) {
      if (!locked && !isLockedByCurrentUser) {
        incrementLockedDocument(id);
      }
      window.addEventListener('beforeunload', decrementLock);
    }

    return () => {
      if (enableOpeningHilInNewTab) {
        decrementLockedDocument(id);
        window.removeEventListener('beforeunload', decrementLock);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    function beforeUnloadListener() {
      function unlockDocument() {
        if (document && isLockedByCurrentUser) {
          unlockDocumentAction.mutate({ id });
        }
      }

      // AXA doesn't want it to unlock...
      if (!enableOpeningHilInNewTab) {
        window.addEventListener('beforeunload', unlockDocument);
      }

      return () => {
        if (!enableOpeningHilInNewTab) {
          window.removeEventListener('beforeunload', unlockDocument);
        }
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(
    function loadNextDocument() {
      if (enableHilNextDocument && locked) {
        // doing this setTimeout because of a race condition with a locked doc
        const timeout = setTimeout(() => {
          const nextDocument = getNextDocument();

          nextDocument.then((doc) => {
            if (doc) {
              preWarmImageCache(doc.urls);
            }
          });
        }, 1000);

        return () => {
          clearTimeout(timeout);
        };
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [locked]
  );

  function updateList(id: string) {
    setContent(({ list, resources }) => ({
      list: [...new Set([...list, id])],
      resources,
    }));
  }

  function updateResources(id: string, state: any) {
    setContent(({ list, resources }) => ({
      list,
      resources: {
        ...resources,
        [id]: {
          ...resources[id],
          ...state,
        },
      },
    }));
  }

  function getResource(id: string | undefined) {
    if (id) {
      return resources[id];
    }

    return null;
  }

  function resetCrop() {
    setCrop(undefined);
  }

  function submitOrSaveDocument(
    mutationFn: UseMutationResult<Document, Error, Document, unknown>
  ) {
    return (values: HitLFormValues) => {
      setDisableSubmit(true);
      const newDocument = { ...document };
      const transformValues = transformFormValuesToState(list, resources);
      newDocument.content = transformValues(values);

      mutationFn.mutate(newDocument, {
        onSuccess: () => {
          hasSubmitted.current = true;

          if (enableOpeningHilInNewTab) {
            removeDocumentIdFromLocalStorage(id);
          }

          void navigateToNextContext();
        },
        onError: () => {
          setDisableSubmit(false);
        },
      });
    };
  }

  async function handleSubmit(values: HitLFormValues) {
    submitOrSaveDocument(submitDocument)(values);
  }

  function handleSave(values: HitLFormValues) {
    submitOrSaveDocument(saveDocument)(values);
  }

  function submitEndEvent() {
    updateDataLayer({
      event: HIL_TOOL_SUBMIT_END,
      performance:
        (Date.now() - getEventTimestamp(HIL_TOOL_SUBMIT_START)) / 1000,
      documentId: document.id,
    });
  }

  // TODO: this function is becoming monstrous. We should put this outside...
  async function navigateToNextContext() {
    submitEndEvent();
    const claimId = document.claimId;

    if (enableHilNextDocument) {
      updateWindowPerformanceObject(HIL_TOOL_GET_NEXT_DOCUMENT_START);
      const nextDocument = await getNextDocument(true);
      updateDataLayer({
        event: HIL_TOOL_GET_NEXT_DOCUMENT_END,
        performance:
          (Date.now() - getEventTimestamp(HIL_TOOL_GET_NEXT_DOCUMENT_START)) /
          1000,
        documentId: document.id,
      });
      if (nextDocument) {
        preWarmImageCache(nextDocument.urls);
        const to =
          fromClaimView && claimId
            ? claimHILToolRoute.createPath(claimId, nextDocument.id)
            : hitlToolRoute.createPath(nextDocument.id);
        navigate(to);
        //TODO: review this
        toast.remove();
        toast.success(t('form.nextDocumentLoaded') as string);
        return Promise.resolve();
      } else if (fromClaimView && enableGetNextClaim) {
        const nextClaim = await getNextClaim(true);
        if (nextClaim) {
          const isAwaitingReview = getIsClaimAwaitingReview(nextClaim);
          const to =
            isAwaitingReview && !generalConfig.disableClaimReviewScreen
              ? `${claimRoute.createPath(nextClaim.id)}?${PAGE_ID}=review`
              : claimRoute.createPath(nextClaim.id);
          navigate(to);
          return Promise.resolve();
        }
        const existingSearch = window.localStorage.getItem(CLAIM_TABLE);
        navigate(
          claimsRoute.createPath({
            search: existingSearch
              ? `?${existingSearch}`
              : `?${STAGE}=${AWAITING_REVIEW}`,
          })
        );
        return Promise.resolve();
      }
    }

    if (fromClaimView && closeClaimHilTool) {
      closeClaimHilTool();
    } else {
      const to = claimId
        ? claimRoute.createPath(claimId)
        : `${hitlRoute.createPath()}?${searchParams}`;
      navigate(to);
    }
  }

  function handleDocumentTypeChange(documentType: string) {
    setDocumentType.mutate(
      { id, documentType },
      {
        onSuccess: () => {
          navigateToNextContext();
        },
      }
    );
  }

  function handleReject(reason: string) {
    rejectDocument.mutateAsync({ id, reason }).then(() => {
      navigateToNextContext();
    });
  }

  function handleSkip() {
    navigateToNextContext().then(() => {
      unlockDocumentAction.mutate({ id });
    });
  }

  function lockDocument() {
    lockDocumentQueueAction.mutate(id);
  }

  const documentTypeItemId = list.find(hasDocumentTypeItem(resources));
  const documentTypeItem = getResource(documentTypeItemId);

  /**
   * some disgusting local logic here
   * if valid is true or null, then it's valid
   * this impacts how the document type changer is enabled/disabled and also
   * whether the items in the form in editable or not
   */
  const isDocumentTypeValid =
    documentTypeItem?.valid || documentTypeItem?.valid === null;
  const thumbnails = document.urls
    ? Object.keys(document.urls).map((item) => ({
        url: document.urls[item],
        documentId: document.id || 'id',
        documentGroup: document.documentType || '',
      }))
    : [];

  const enableThumbnails = useFeatureFlag('enableHILThumbnails');

  const leftColumn = (
    <Stack sx={{ height: 1 }}>
      <DocumentToolbar document={document} fromClaimView={fromClaimView}>
        <HitLToolbar
          document={document}
          documentTypeItemId={documentTypeItemId}
          hasAwaitingReviewStage={hasAwaitingReviewStage || hasSaveStage}
          onSubmit={handleDocumentTypeChange}
          getResource={getResource}
        />
      </DocumentToolbar>

      <CanvasTool
        currentPage={currentPage}
        crop={crop}
        hasThumbnails={enableThumbnails as true}
        thumbnails={thumbnails}
        order={{ canvas: 1, thumbnails: 0 }}
        imageLoadFinishEvent={() => {
          updateDataLayer({
            event: HIL_OPEN_END,
            performance: toFixed(
              (performance.now() - startPerformanceMeasurement) / 1000,
              1
            ),
            documentId: document.id,
          });
        }}
        resetCrop={resetCrop}
        setPage={setPage}
        urls={document.urls}
        stickyControls
        // 25 is the height of the dialog toolbar when opening from claim view
        // 85 is the height of the document type toolbar
        stickyControlsTop={fromClaimView ? 60 + 25 : 60 + 85}
      />
    </Stack>
  );

  const Component = isNewLayout ? HitLFormNew : HitLForm;

  return (
    <ErrorBoundary
      FallbackComponent={Fallback}
      onReset={() => {
        navigateToNextContext();
      }}
      onError={(error, info) => {
        rollbar.info('HIL tool FormHandler failure', error, info);
      }}
    >
      <DocumentDialog
        isLockedByCurrentUser={isLockedByCurrentUser}
        lastLockedBy={lastLockedBy}
        locked={locked}
        reasonCode={reasonCode}
        clientDocumentType={clientDocumentType}
        documentType={documentType}
      />

      <Component
        document={document}
        list={list}
        resources={resources}
        lockDocument={lockDocument}
        onReject={handleReject}
        onSubmit={handleSubmit}
        onSave={handleSave}
        onSkip={handleSkip}
        isLockedByCurrentUser={isLockedByCurrentUser}
        currentPage={currentPage}
        updateList={updateList}
        updateResources={updateResources}
        leftColumn={leftColumn}
        setCrop={setCrop}
        isMultipage={isMultipage}
        isDocumentTypeValid={isDocumentTypeValid}
        ocrHistoryResource={ocrHistoryResource}
        fromClaimView={fromClaimView}
        validationResults={validationResults}
        disableSubmit={disableSubmit}
      />
    </ErrorBoundary>
  );
}

export { FormHandler };
