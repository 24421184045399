import type { Dispatch, ReactNode, SetStateAction } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { AppLogo, AppLogoIcon } from 'components/AppLogo';
import { SvgIconStyle } from 'components/SvgIconStyle';
import { useResponsive } from 'components/customHooks/useResponsive';
import { LinkWithRef } from 'components/Link';
import {
  useTranslationRoot,
  WithTranslationRoot,
} from 'components/with-translation';
import { ARROW_FORWARD_ICON } from 'constants/public-icons';
import { DASHBOARD_PAGE } from 'constants/translation-keys';
import { generalConfig } from 'config';
import dashboardRoute from 'pages/Dashboard/dashboard.route';
import { NAVBAR } from 'theme/layout';

import { NavList } from './NavList';

interface DashboardNavBarProps {
  isMiniNavBarOpen: boolean;
  isOpenSidebar: boolean;
  onCloseSidebar: VoidFunction;
  setLocalStore: Dispatch<SetStateAction<boolean>>;
  toggleMiniNavbar: VoidFunction;
}

interface HomepageLinkProps {
  children: ReactNode;
}

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

function HomepageLink({ children }: HomepageLinkProps) {
  return (
    <Link component={LinkWithRef} to={dashboardRoute.createPath()}>
      {children}
    </Link>
  );
}

function DashboardNavBar({
  isMiniNavBarOpen,
  isOpenSidebar,
  onCloseSidebar,
  setLocalStore,
  toggleMiniNavbar,
}: DashboardNavBarProps) {
  const { t } = useTranslationRoot();
  const isDesktop = useResponsive('up', 'lg');

  const logoMarkup = (isMiniNavbar?: boolean) => (
    <HomepageLink>
      <Stack
        direction="row"
        sx={[
          {
            alignItems: 'center',
            justifyContent: isMiniNavbar ? 'center' : 'space-between',
          },
          isMiniNavbar
            ? { p: 2, flexShrink: 0 }
            : { flexShrink: 0, pt: 3, px: 2.5 },
        ]}
      >
        {isMiniNavbar ? <AppLogoIcon /> : <AppLogo />}
      </Stack>
    </HomepageLink>
  );
  const versionMarkup = (
    <Typography
      component="p"
      variant="caption"
      sx={{
        color: 'grey.600',
        fontSize: ({ typography }) => typography.pxToRem(10),
      }}
    >
      {`v${generalConfig.platformVersion}`}
    </Typography>
  );
  const contentMarkup = (
    <>
      {logoMarkup()}

      <NavList
        removeDocuments={generalConfig.disableNavDocuments}
        removeDocumentsClosedStage={generalConfig.disableNavDocumentClosed}
      />

      <Box sx={{ flexGrow: 1 }} />

      <Box sx={{ pb: 3, px: 2.5 }}>
        <Typography
          component="p"
          variant="caption"
          sx={{
            color: 'grey.600',
          }}
        >
          {`©Sprout.ai 2022. All rights reserved.`}
        </Typography>
        {versionMarkup}
      </Box>
    </>
  );

  const miniNavbarContentMarkup = (
    <Stack
      sx={{
        alignItems: 'center',
        height: 1,
        justifyContent: 'space-between',
      }}
    >
      <Stack>
        {logoMarkup(true)}

        <NavList
          isMiniNavbar
          removeDocuments={generalConfig.disableNavDocuments}
          removeDocumentsClosedStage={generalConfig.disableNavDocumentClosed}
        />
      </Stack>

      <Box
        sx={{
          py: 1,
        }}
      >
        {versionMarkup}
      </Box>
    </Stack>
  );

  return (
    <WithTranslationRoot namespace={DASHBOARD_PAGE}>
      <RootStyle
        sx={{
          displayPrint: 'none',
          position: 'relative',
          width: {
            lg: isMiniNavBarOpen
              ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH
              : NAVBAR.DASHBOARD_WIDTH,
          },
        }}
      >
        {!isDesktop ? (
          <Drawer
            open={isOpenSidebar}
            onClose={onCloseSidebar}
            PaperProps={{
              sx: {
                width: NAVBAR.DASHBOARD_WIDTH,
              },
            }}
          >
            {contentMarkup}
          </Drawer>
        ) : null}
        {isDesktop ? (
          <>
            <IconButton
              aria-label={t('common.miniNavbarLabel') as string}
              size="small"
              sx={{
                backgroundColor: 'background.default',
                border: '1px dashed',
                borderColor: 'grey.50024',
                position: 'fixed',
                left: isMiniNavBarOpen
                  ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH - NAVBAR.TOGGLE / 2
                  : NAVBAR.DASHBOARD_WIDTH - NAVBAR.TOGGLE / 2,
                top: isMiniNavBarOpen ? 22 : 44,
                transform: isMiniNavBarOpen ? '' : 'rotate(180deg)',
                zIndex: ({ zIndex }) => zIndex.drawer,
              }}
              onClick={() => {
                toggleMiniNavbar();
                setLocalStore(!isMiniNavBarOpen);
              }}
            >
              <SvgIconStyle width={16} height={16} src={ARROW_FORWARD_ICON} />
            </IconButton>
            <Drawer
              open
              variant="persistent"
              PaperProps={{
                sx: {
                  bgcolor: 'background.default',
                  borderRightStyle: 'dashed',
                  width: isMiniNavBarOpen
                    ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH
                    : NAVBAR.DASHBOARD_WIDTH,
                  zIndex: ({ zIndex }) => zIndex.drawer - 1,
                },
              }}
            >
              {isMiniNavBarOpen ? miniNavbarContentMarkup : contentMarkup}
            </Drawer>
          </>
        ) : null}
      </RootStyle>
    </WithTranslationRoot>
  );
}

export { DashboardNavBar };
