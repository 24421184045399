import type { ReactNode } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import type {
  Claim,
  ClaimsValidateResult,
  ClaimValidationResult,
} from 'types/Claims';
import {
  CardHeaderWithTranslation,
  TypographyWithTranslation,
  useTranslationRoot,
} from 'components/with-translation';
import { LoadingIcon } from 'components/icons/LoadingIcon';
import { CollapsibleCard } from 'components/CollapsibleCard';
import { AWAITING_REVIEW as DOCUMENT_AWAITING_REVIEW } from 'constants/document-stage';
import { useGetClaimDocuments } from 'state/queries/claims';
import { getIsClaimCompleted } from 'state/selectors/claims';
import { useGetWidgetsClaimSummaryTab } from 'state/queries/widgets';
import { convertArrayToObject } from 'utils/array';
import {
  AWAITING_ENRICHMENT,
  AWAITING_INFO,
  AWAITING_OCR_RESULTS,
  AWAITING_REVIEW,
  CLOSED,
  COMPLETED,
  ERROR,
  PROCESSING,
  REJECTED,
} from 'constants/claims';
import { generalConfig } from 'config';

import { AttachmentV2 } from './AttachmentV2';
import { AuditLogV2 } from './AuditLogV2';
import { AutomationRuleV2 } from './AutomationRuleV2';
import { TrafficLightV2 } from './TrafficLightV2';
import { ValidationResult } from './ValidationResult';
import { ValidationResultV2 } from './ValidationResultV2';
import { Widgets } from './Widgets';

interface SummaryProps {
  claim: Claim;
  hasRefetchButton: boolean;
  isRefetching: boolean;
  refetchClaim: VoidFunction;
}

function SummaryV2({
  claim,
  hasRefetchButton,
  isRefetching,
  refetchClaim,
}: SummaryProps) {
  const { t } = useTranslationRoot();
  const {
    achTasksResults,
    clientRecommendation,
    documents: documentsList,
    features,
    files: filesList,
    id,
    message,
    rejectReason,
    sproutRecommendation,
    validationResults,
  } = claim;

  const {
    data: documents,
    isError,
    isPending,
    isSuccess,
  } = useGetClaimDocuments(id);
  const hasDoneStatus = getIsClaimCompleted(claim);
  const widgetsClaimSummaryQuery = useGetWidgetsClaimSummaryTab();
  let documentsMap = {};

  if (isError) {
    return (
      <TypographyWithTranslation
        i18nKey="common.loadClaimError"
        variant="body1"
      />
    );
  }

  if (isPending) {
    return <LoadingIcon />;
  }

  if (isSuccess) {
    documentsMap = convertArrayToObject(documents, 'id');
  }
  const filesMap = convertArrayToObject(filesList, 'fileId');
  const isCompletedStage = getIsClaimCompleted(claim);

  const Message = ({ children }: { children: ReactNode }) => (
    <Grid
      size={{
        xs: 12,
        md: generalConfig.showClaimTrafficLight && isCompletedStage ? 9 : 12,
      }}
    >
      <Card>
        <CardHeaderWithTranslation titleI18nKey="summaryTable.message" />
        <CardContent>{children}</CardContent>
      </Card>
    </Grid>
  );

  const trafficLight =
    generalConfig.showClaimTrafficLight &&
    isCompletedStage &&
    clientRecommendation ? (
      <Grid size={{ xs: 12, md: 3 }}>
        <TrafficLightV2
          clientRecommendation={clientRecommendation}
          hasRefetchButton={hasRefetchButton}
          isRefetching={isRefetching}
          refetchClaim={refetchClaim}
        />
      </Grid>
    ) : null;

  const attachmentsMarkup = (
    <CollapsibleCard titleI18nKey="summaryTable.attachments">
      <CardContent sx={{ pt: 0 }}>
        <Stack spacing={1}>
          {documentsList.length ? (
            documentsList.map(
              ({ documentId, stage, fileId, clientDocumentType }) => (
                <AttachmentV2
                  key={documentId}
                  claimId={claim.id}
                  documentId={documentId}
                  documentsMap={documentsMap}
                  hasHilStatus={stage === DOCUMENT_AWAITING_REVIEW}
                  hasDoneStatus={hasDoneStatus}
                  stage={stage}
                >
                  <Stack
                    sx={{
                      alignItems: 'start',
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        fontWeight: 'bold',
                        color: ({ palette }) => palette.text.primary,
                        textAlign: 'left',
                        wordBreak: 'break-all',
                      }}
                    >
                      {documentId}
                    </Typography>
                    {fileId && filesMap[fileId] && (
                      <Typography
                        variant="body2"
                        sx={{
                          color: ({ palette }) => palette.text.secondary,
                          textAlign: 'left',
                          wordBreak: 'break-all',
                        }}
                      >
                        {filesMap[fileId].originalFileName}
                      </Typography>
                    )}
                    <Typography
                      variant="body2"
                      sx={{
                        color: ({ palette }) => palette.text.secondary,
                        textAlign: 'left',
                      }}
                    >
                      {clientDocumentType}
                    </Typography>
                  </Stack>
                </AttachmentV2>
              )
            )
          ) : (
            <TypographyWithTranslation
              i18nKey="summaryTable.attachmentsEmpty"
              variant="body2"
            />
          )}
        </Stack>
      </CardContent>
    </CollapsibleCard>
  );

  const auditLogMarkup = (
    <CollapsibleCard titleI18nKey="auditLog.title">
      <CardContent sx={{ pt: 0, overflowX: 'auto' }}>
        <AuditLogV2 claim={claim} filesMap={filesMap} />
      </CardContent>
    </CollapsibleCard>
  );

  function renderValidationResults(
    validationResults: ClaimsValidateResult[] | ClaimValidationResult[]
  ) {
    return validationResults.map((result, index) =>
      Object.prototype.hasOwnProperty.call(result, 'featureKeys') ? (
        <ValidationResultV2
          key={`${result.ruleId}${index}`}
          validationResult={result as ClaimsValidateResult}
        />
      ) : (
        <ValidationResult
          key={`${result.ruleId}${index}`}
          validationResult={result as ClaimValidationResult}
        />
      )
    );
  }

  const automationRules = (
    <Grid size={{ xs: 12, md: 6 }}>
      <CollapsibleCard titleI18nKey="summaryTable.automationRules">
        <CardContent sx={{ pt: 0, overflowX: 'auto' }}>
          <Stack
            sx={{
              gap: 2,
            }}
          >
            {achTasksResults?.length
              ? achTasksResults.map((rule, index) => (
                  <AutomationRuleV2
                    key={`${rule.taskId}${index}`}
                    rule={rule}
                  />
                ))
              : null}
            {validationResults?.length
              ? renderValidationResults(validationResults)
              : null}
            {!achTasksResults?.length && !validationResults?.length ? (
              <TypographyWithTranslation
                i18nKey="summaryTable.rulesEmpty"
                variant="body2"
              />
            ) : null}
          </Stack>
        </CardContent>
      </CollapsibleCard>
    </Grid>
  );

  const NextAction = ({
    children,
    cardHeaderKey,
  }: {
    cardHeaderKey: string;
    children: ReactNode;
  }) => (
    <Grid size={{ xs: 12, md: isCompletedStage ? 9 : 12 }}>
      <Card>
        <CardHeaderWithTranslation titleI18nKey={cardHeaderKey} />
        <CardContent>{children}</CardContent>
      </Card>
    </Grid>
  );

  const markup = ({
    content,
    title,
  }: {
    content: string | null;
    title: string;
  }) => {
    const hasWidgets =
      widgetsClaimSummaryQuery.isSuccess &&
      Boolean(widgetsClaimSummaryQuery.data.length);

    return (
      <Grid container spacing={2}>
        {isCompletedStage ? (
          <Message>
            <Typography variant="body2" sx={{ whiteSpace: 'pre-line' }}>
              {content}
            </Typography>
          </Message>
        ) : hasWidgets ? null : (
          <NextAction cardHeaderKey={title}>
            <Stack spacing={2}>
              <Typography variant="body2">{content}</Typography>
            </Stack>
          </NextAction>
        )}
        {trafficLight}
        {hasWidgets ? (
          <Grid size={{ xs: 12 }}>
            <Box>
              <Widgets
                features={features}
                widgets={widgetsClaimSummaryQuery.data}
              />
            </Box>
          </Grid>
        ) : null}

        <Grid size={{ xs: 12, md: 6 }}>
          <Stack spacing={2}>
            {attachmentsMarkup}
            {generalConfig.disableClaimAuditLog ? null : auditLogMarkup}
          </Stack>
        </Grid>
        {automationRules}
      </Grid>
    );
  };

  const messageMap: {
    [key: string]: {
      title: string;
      content: string | null;
    };
  } = {
    [AWAITING_ENRICHMENT]: {
      title: 'summaryTable.nextAction',
      content: t('summaryTable.awaitingEnrichment'),
    },
    [AWAITING_INFO]: {
      title: 'summaryTable.nextAction',
      content: t('summaryTable.awaitingInfo'),
    },
    [AWAITING_OCR_RESULTS]: {
      title: 'summaryTable.nextAction',
      content: t('summaryTable.awaitingOcrResults'),
    },
    [AWAITING_REVIEW]: {
      title: 'summaryTable.nextAction',
      content: t('summaryTable.awaitingReview'),
    },
    [CLOSED]: {
      title: 'summaryTable.nextAction',
      content: t('summaryTable.closed'),
    },
    [COMPLETED]: {
      title: 'summaryTable.completed',
      content: `${message !== null ? `${message}\n\n` : ''}${
        Array.isArray(sproutRecommendation)
          ? sproutRecommendation.map(({ message }) => message).join('\n')
          : ''
      }`,
    },
    [ERROR]: { title: 'summaryTable.message', content: message },
    [PROCESSING]: {
      title: 'summaryTable.nextAction',
      content: t('summaryTable.processing'),
    },
    [REJECTED]: {
      title: 'summaryTable.message',
      content: t('summaryTable.rejected', { rejectReason }),
    },
  };

  return <Stack>{markup(messageMap[claim.stage])}</Stack>;
}

export { SummaryV2 };
