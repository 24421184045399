import type { ChangeEvent } from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import type {
  ClaimSproutRecommendation,
  ClaimsValidateResult,
} from 'types/Claims';
import { SvgIconStyle } from 'components/SvgIconStyle';
import {
  CIRCLE_CHECK_ICON,
  FULL_SCREEN_EXIT_ICON,
  FULL_SCREEN_ICON,
  NEXT_ICON,
  SQUARE_MINUS_ICON,
} from 'constants/public-icons';
import { useBoolean } from 'components/customHooks/useBoolean';
import {
  ButtonWithTranslation,
  useTranslationRoot,
} from 'components/with-translation';
import { CLAIM_TABLE } from 'constants/table-pagination';
import { STAGE } from 'constants/route-keys';
import { AWAITING_REVIEW } from 'constants/claims';
import claimsRoute from 'pages/Claims/claims.route';
import { useRejectClaim, useUnlockClaim } from 'state/queries/claims';
import { getIsClaimAwaitingReview } from 'state/selectors/claims';
import { useFeatureFlag } from 'components/customHooks/useFeatureFlag';
import { updateWindowPerformanceObject } from 'analytics/utils';
import { REVIEW_SCREEN_SUBMIT_START } from 'analytics/events';

import { ReviewFormClaimType } from './ReviewFormClaimType';
import { ReviewValidationResults } from './ReviewValidationResults';
import { ReviewFormRecommendation } from './ReviewFormRecommendation';
import { useReviewToolContext } from './useReviewToolContext';
import { FULLSCREEN_ZINDEX } from './review-tool-utils';
import { ReviewFormMetadata } from './ReviewFormMetadata';
import { RejectClaimDialog } from './RejectClaimDialog';

const StartIcon = styled(SvgIconStyle)(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    display: 'none',
  },
}));

function ReviewFormHeader() {
  const { t } = useTranslationRoot();
  const showNextButton = useFeatureFlag('enableClaimSkipButton');
  const {
    claim,
    claimType,
    isFullscreen,
    toggleFullscreen,
    isNotLockedByCurrentUser,
    navigateToNextContext,
  } = useReviewToolContext();
  const { openDialog, ...rejectClaimProps } = useRejectClaimDialog(
    claim.id,
    navigateToNextContext
  );
  const unlockClaimAction = useUnlockClaim(claim.id);

  const generateMetadataItems = (items: Record<string, string | string[]>) => {
    return Object.entries(items).map(([label, value]) => ({ label, value }));
  };

  // generate items to be used in ReviewFormMetadata
  const claimMetadataItems = generateMetadataItems(claim.metadata || {});
  const claimPropertiesItems = generateMetadataItems(claim.properties || {});
  const items = [...claimMetadataItems, ...claimPropertiesItems];

  return (
    <Stack
      component="header"
      direction="row"
      sx={{
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: ({ palette }) => `1px solid ${palette.divider}`,
        flex: '0 0 auto',
        flexWrap: { xs: 'wrap', md: 'nowrap' },
        p: 2,
        position: 'relative',
      }}
    >
      <Stack
        direction="row"
        spacing={2}
        sx={{
          alignItems: 'center',
        }}
      >
        <ReviewFormRecommendation
          sproutRecommendation={
            claim.sproutRecommendation as ClaimSproutRecommendation[]
          }
        />
        <Tooltip
          title={
            t(
              isFullscreen
                ? 'reviewTool.fullscreenExitButton'
                : 'reviewTool.fullscreenButton'
            ) as string
          }
          slotProps={{
            popper: {
              sx: { zIndex: FULLSCREEN_ZINDEX + 1 },
            },
          }}
        >
          <span>
            <IconButton onClick={toggleFullscreen}>
              <SvgIconStyle
                src={isFullscreen ? FULL_SCREEN_EXIT_ICON : FULL_SCREEN_ICON}
                height={20}
                width={20}
              />
            </IconButton>
          </span>
        </Tooltip>
        <ReviewFormMetadata items={items} />
        <ReviewFormClaimType claimType={claimType} />
      </Stack>
      <Stack
        direction="row"
        spacing={1}
        sx={{
          alignItems: 'center',
        }}
      >
        <ReviewValidationResults
          data-testid="review-validation-results"
          validationResults={
            (claim.validationResults as ClaimsValidateResult[]) || []
          }
        />

        {getIsClaimAwaitingReview(claim) ? (
          <>
            {showNextButton ? (
              <Tooltip title={t('enrichmentTool.nextClaimButton')}>
                <span>
                  <ButtonWithTranslation
                    i18nKey=""
                    startIcon={
                      <StartIcon height={17} src={NEXT_ICON} width={17} />
                    }
                    variant="contained"
                    color="warning"
                    onClick={() => {
                      navigateToNextContext().then(() => {
                        unlockClaimAction.mutate({ id: claim.id });
                      });
                    }}
                    sx={{
                      height: 36,
                      '& .MuiButton-startIcon': {
                        mr: 0,
                        ml: 0,
                      },
                    }}
                  />
                </span>
              </Tooltip>
            ) : null}
            <ButtonWithTranslation
              data-testid="reject-claim-button"
              disabled={isNotLockedByCurrentUser}
              i18nKey="enrichmentTool.rejectButton"
              color="error"
              startIcon={
                <StartIcon height={17} src={SQUARE_MINUS_ICON} width={17} />
              }
              variant="contained"
              onClick={openDialog}
              sx={{
                whiteSpace: 'nowrap',
              }}
            />
            <RejectClaimDialog
              {...rejectClaimProps}
              data-test-id="reject-claim-dialog"
            />

            <Tooltip
              title={t('reviewTool.shortcutKeySubmit')}
              slotProps={{
                popper: {
                  sx: { zIndex: FULLSCREEN_ZINDEX + 1 },
                },
              }}
            >
              <span>
                <ButtonWithTranslation
                  data-testid="submit-claim-button"
                  disabled={isNotLockedByCurrentUser}
                  i18nKey="enrichmentTool.submitButton"
                  startIcon={
                    <StartIcon height={17} src={CIRCLE_CHECK_ICON} width={17} />
                  }
                  variant="contained"
                  type="submit"
                  sx={{
                    whiteSpace: 'nowrap',
                  }}
                  onClick={() => {
                    updateWindowPerformanceObject(REVIEW_SCREEN_SUBMIT_START);
                  }}
                />
              </span>
            </Tooltip>
          </>
        ) : null}
      </Stack>
    </Stack>
  );
}

function useRejectClaimDialog(
  claimId: string,
  navigateToNextContext: () => void
) {
  const navigate = useNavigate();
  const { t } = useTranslationRoot();
  const rejectClaim = useRejectClaim({
    meta: {
      loadingMessage: t('rejectDialog.submitLoading') as string,
      successMessage: t('rejectDialog.submitSuccess') as string,
      errorMessage: t('rejectDialog.submitFail') as string,
    },
  });
  const [isOpen, openDialog, closeDialog] = useBoolean();
  const [reason, setReason] = useState<string>('');

  useEffect(() => {
    // handle rejectClaim success
    if (rejectClaim.isSuccess) {
      const existingSearch = window.localStorage.getItem(CLAIM_TABLE);

      navigate(
        claimsRoute.createPath({
          search: existingSearch
            ? `?${existingSearch}`
            : `?${STAGE}=${AWAITING_REVIEW}`,
        })
      );
    }
  }, [navigate, rejectClaim.isSuccess]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setReason(event.target.value);
  };

  const handleReject = () => {
    rejectClaim.mutate(
      { id: claimId, reason },
      {
        onSuccess: navigateToNextContext,
      }
    );
  };

  return {
    isOpen,
    isSubmitting: rejectClaim.isPending,
    closeModal: closeDialog,
    openDialog,
    handleChange,
    reason,
    onReject: handleReject,
  };
}

export { ReviewFormHeader };
