// Pages
// Enrichment Tool
export const ENRICHMENT_TOOL_START = 'enrichment_tool_start';
export const ENRICHMENT_TOOL_END = 'enrichment_tool_end';
export const ENRICHMENT_SUBMIT_START = 'enrichment_submit_start';
export const ENRICHMENT_SUBMIT_END = 'enrichment_submit_end';

// Review Screen
export const REVIEW_SCREEN_START = 'review_screen_start';
export const REVIEW_SCREEN_END = 'review_screen_end';
export const REVIEW_SCREEN_SUBMIT_START = 'review_screen_submit_start';
export const REVIEW_SCREEN_SUBMIT_END = 'review_screen_submit_end';

// HIL Tool
export const HIL_VALIDATION_FAIL = 'hil_validation_fail';
export const HIL_OPEN_END = 'hil_open_end';
export const HIL_TOOL_SUBMIT_START = 'hil_tool_submit_start';
export const HIL_TOOL_SUBMIT_END = 'hil_tool_submit_end';

// APIs
// Enrichment Tool
export const ENRICHMENT_VALIDATION_START = 'enrichment_validation_start';
export const ENRICHMENT_VALIDATION_END = 'enrichment_validation_end';
export const ENRICHMENT_PATCH_START = 'enrichment_patch_start';
export const ENRICHMENT_PATCH_END = 'enrichment_patch_end';
export const ENRICHMENT_NEXT_CLAIM_START = 'enrichment_next_claim_start';
export const ENRICHMENT_NEXT_CLAIM_END = 'enrichment_next_claim_end';

// Review Screen
export const REVIEW_SCREEN_VALIDATION_START = 'review_screen_validation_start';
export const REVIEW_SCREEN_VALIDATION_END = 'review_screen_validation_end';
export const REVIEW_SCREEN_NEXT_CLAIM_START = 'review_screen_next_claim_start';
export const REVIEW_SCREEN_NEXT_CLAIM_END = 'review_screen_next_claim_end';
export const REVIEW_SCREEN_PATCH_START = 'review_screen_patch_start';
export const REVIEW_SCREEN_PATCH_END = 'review_screen_patch_end';

// HIL Tool
export const HIL_TOOL_VALIDATION_START = 'hil_tool_validation_start';
export const HIL_TOOL_VALIDATION_END = 'hil_tool_validation_end';
export const HIL_TOOL_PATCH_START = 'hil_tool_patch_start';
export const HIL_TOOL_PATCH_END = 'hil_tool_patch_end';
export const HIL_TOOL_GET_NEXT_DOCUMENT_START =
  'hil_tool_get_next_document_start';
export const HIL_TOOL_GET_NEXT_DOCUMENT_END = 'hil_tool_get_next_document_end';
