import {
  ChangeEvent,
  Dispatch,
  ReactNode,
  SetStateAction,
  useLayoutEffect,
} from 'react';
import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import {
  FormProvider,
  useForm,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { Resizable, ResizeCallback } from 're-resizable';
import merge from 'lodash.merge';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid2';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
import type {
  Claim,
  ClaimObservation,
  ClaimValidationResult,
} from 'types/Claims';
import type { CurrentUser } from 'types/CurrentUser';
import type {
  DocumentContent,
  DocumentLevelContent,
  DocumentValueType,
} from 'types/Documents';
import { BOOL, FLOAT, INT, STR } from 'constants/value-type';
import { LoadingIcon } from 'components/icons/LoadingIcon';
import {
  ButtonWithTranslation,
  TypographyWithTranslation,
  useTranslationRoot,
} from 'components/with-translation';
import { SvgIconStyle } from 'components/SvgIconStyle';
import { HiddenInput } from 'components/form/HiddenInput';
import { CanvasTool } from 'components/CanvasTool';
import { ControllerWithTextFieldV2 } from 'components/form/ControllerWithTextFieldV2';
import { ControllerWithSingleCheckbox } from 'components/form/ControllerWithSingleCheckbox';
import { useBoolean } from 'components/customHooks/useBoolean';
import { useResponsive } from 'components/customHooks/useResponsive';
import { useFeatureFlag } from 'components/customHooks/useFeatureFlag';
import { useNextClaimParams } from 'components/customHooks/useNextClaimParams';
import { toast } from 'components/toast';
import { Label } from 'components/Label.tsx';
import { PAGE_ID, STAGE } from 'constants/route-keys';
import { generalConfig } from 'config';
import {
  ARROW_DOWNWARD_ICON,
  CIRCLE_CHECK_ICON,
  COPY_ICON,
  EDIT_ICON,
  PASTE_ICON,
  PLUS_ICON,
  SQUARE_MINUS_ICON,
  TRASH_ICON,
} from 'constants/public-icons';
import {
  AWAITING_REVIEW,
  ObservationType,
  PROVIDER,
  REIMBURSEMENT,
  STAGE_LABEL_COLOR_MAP,
  statusTranslation,
} from 'constants/claims';
import { CLAIM_TABLE } from 'constants/table-pagination';
import claimsRoute from 'pages/Claims/claims.route';
import claimRoute from 'pages/Claim/claim.route';
import {
  lockClaim,
  useGetClaimObservations,
  useLockClaim,
  usePatchClaimObservations,
  useRejectClaim,
  useUnlockClaim,
  validateClaim,
} from 'state/queries/claims';
import {
  isHighLevelItem,
  isLineLevelItem,
  isNotLineLevelItem,
} from 'state/selectors/documents';
import { getIsClaimAwaitingReview } from 'state/selectors/claims';
import { convertArrayToListAndDict, unique } from 'utils/array';
import { formatDocumentDate } from 'utils/date.ts';
import { generateUuid } from 'utils/generate-uuid';
import { pipe } from 'utils/pipe';
import { useGet } from 'utils/react-query';
import { preWarmImageCache } from 'utils/image-cache.ts';
import { transformKeys } from 'utils/object';
import { toCamelCase } from 'utils/string';
import rollbar from 'rollbar-config.ts';
import {
  updateDataLayer,
  updateWindowPerformanceObject,
  getEventTimestamp,
} from 'analytics/utils';
import {
  ENRICHMENT_TOOL_END,
  ENRICHMENT_TOOL_START,
  ENRICHMENT_VALIDATION_END,
  ENRICHMENT_NEXT_CLAIM_START,
  ENRICHMENT_NEXT_CLAIM_END,
  ENRICHMENT_PATCH_START,
  ENRICHMENT_PATCH_END,
  ENRICHMENT_VALIDATION_START,
} from 'analytics/events';

import { WarningDialog } from './WarningDialog';
import { HardcodedDropdown } from './HardcodedDropdown';
import { HardcodedMultiselect } from './HardcodedMultiselect';
import { EnrichmentValidationResults } from './EnrichmentValidationResults';
import { RejectClaimDialog } from './RejectClaimDialog';

interface EnrichmentToolV2Props {
  claim: Claim;
  currentUser: CurrentUser;
}

interface EnrichmentToolContextProviderProps {
  children: ReactNode;
  claim: Claim;
  currentUser: CurrentUser;
  navigateToNextContext?: () => void;
}

type DocumentContentWithId = DocumentContent & { id: string };
type DocumentLevelContentWithId = DocumentLevelContent & { id: string };
type Lists = Record<string, string[]>;
type Resources = Record<string, DocumentContentWithId>;
type NormalisedData = {
  businessUnit: string;
  dropdownLevelMap: Record<string, string>;
  enrichmentListId: string;
  fieldsListId: string;
  lists: Lists;
  enrichment: Resources;
  fields: Resources;
  observations: Record<string, ClaimObservation>;
  groupedEnrichmentLevels: Record<
    string,
    Record<string, Record<string, DocumentContentWithId>>
  >;
  urls: Record<string, string>;
};
type NormalisedState = {
  state: NormalisedData;
  obs: ClaimObservation[];
};
type FormValues = Record<string, string | boolean | number | null>;
type ValidationObservation = {
  observationId: string;
  type: string;
  fields: DocumentContent[];
};

type ValidationResponse = {
  observations: ValidationObservation[];
  results: {
    ruleId: string;
    ruleTitle: string;
    passed: boolean;
    message: string;
  }[];
  stage: string;
};
type State = {
  businessUnit: string;
  enrichment: {
    [key: string]: DocumentContentWithId;
  };
  fields: {
    [key: string]: DocumentContentWithId;
  };
  observations: {
    [key: string]: ClaimObservation;
  };
  lists: {
    [key: string]: string[];
  };
  fieldsListId: string;
  enrichmentListId: string;
  dropdownLevelMap: Record<string, string>;
  groupedEnrichmentLevels: Record<
    string,
    Record<string, Record<string, DocumentContentWithId>>
  >;
  urls: Record<string, string>;
};
interface ContextState extends State {
  __originalState: State;
}

type OnSubmitStateAndDataType = {
  state: ContextState;
  formData: FormValues;
};

const defaultState = {
  businessUnit: '',
  dropdownLevelMap: {},
  enrichment: {},
  fields: {},
  observations: {},
  lists: {},
  fieldsListId: '',
  enrichmentListId: '',
  groupedEnrichmentLevels: {},
  urls: {},
};

const EnrichmentToolContext = createContext<{
  businessUnit: string;
  claim: Claim;
  claimType: string;
  currentUser: CurrentUser;
  state: ContextState;
  setState: Dispatch<SetStateAction<ContextState>>;
  navigateToNextContext: () => void;
}>({
  businessUnit: '',
  claim: {} as Claim,
  claimType: '',
  currentUser: {} as CurrentUser,
  state: {
    __originalState: defaultState,
    ...defaultState,
  },
  setState: () => {},
  navigateToNextContext: () => {},
});

export function EnrichmentToolContextProvider({
  children,
  claim,
  currentUser,
  navigateToNextContext,
}: EnrichmentToolContextProviderProps) {
  const [state, setState] = useState({
    __originalState: defaultState,
    ...defaultState,
  });

  const businessUnit = (claim.properties?.businessUnit || '') as string;
  const claimType = claim.properties?.claimType || '';

  return (
    <EnrichmentToolContext.Provider
      value={{
        businessUnit,
        claim,
        claimType,
        currentUser,
        state,
        setState,
        navigateToNextContext: navigateToNextContext || (() => {}),
      }}
    >
      {children}
    </EnrichmentToolContext.Provider>
  );
}

function useEnrichmentToolContext() {
  return useContext(EnrichmentToolContext);
}

function EnrichmentToolV2({ claim, currentUser }: EnrichmentToolV2Props) {
  const { id: claimId } = claim;
  const navigate = useNavigate();
  const { t } = useTranslationRoot();
  const lockClaimAction = useLockClaim(claimId);
  const unlockClaimAction = useUnlockClaim(claimId);
  const patchObservations = usePatchClaimObservations({
    successMessage: t('enrichmentTool.submitChangesSuccess') as string,
    loadingMessage: t('enrichmentTool.submitChangesLoading') as string,
    errorMessage: (error) =>
      t('enrichmentTool.submitChangesFail', {
        errorMsg:
          (error as unknown as { reason_message: string }).reason_message ||
          (error as unknown as { reason_code: string }).reason_code,
      }),
    gtmEventStart: ENRICHMENT_PATCH_START,
    gtmEventEnd: ENRICHMENT_PATCH_END,
    claimId: claim.id,
  });
  const { data, isPending, isError } = useGetClaimObservations(claimId);
  const enableGetNextClaim = useFeatureFlag('enableGetNextClaim');
  const enableToolDebug = useFeatureFlag('enableDebugMode');
  const getNextClaim = useNextClaimParams();
  const [isLockedByCurrentUser, setIsLockedByCurrentUser] = useState(false);

  // update data layer when we navigate to the next claim
  useEffect(() => {
    if (getEventTimestamp(ENRICHMENT_NEXT_CLAIM_START) > 0) {
      updateDataLayer({
        event: ENRICHMENT_NEXT_CLAIM_END,
        performance:
          (Date.now() - getEventTimestamp(ENRICHMENT_NEXT_CLAIM_START)) / 1000,
        claimId,
      });
    }
  }, [claimId]);

  useEffect(function lockClaimOnMount() {
    const { locked } = claim;

    if (!locked && getIsClaimAwaitingReview(claim)) {
      if (enableToolDebug) {
        rollbar.info('Lock claim on mount', { claimId });
      }
      lockClaimAction.mutate(claimId);
      setIsLockedByCurrentUser(true);
    }
  }, []); // eslint-disable-line

  // unlock claim if user navigates away from claim review screen
  useEffect(() => {
    return () => {
      if (window.location.search.indexOf(`${PAGE_ID}=review`) === -1) {
        if (
          isLockedByCurrentUser &&
          !patchObservations.isPending &&
          getIsClaimAwaitingReview(claim)
        ) {
          unlockClaimAction.mutate({ id: claimId });
        }
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [claim, isLockedByCurrentUser, patchObservations.isPending]);

  if (isError) {
    return (
      <Box sx={{ p: 3 }}>
        <TypographyWithTranslation i18nKey="enrichmentTool.errorLoading" />
      </Box>
    );
  }

  if (isPending) {
    return (
      <Box sx={{ p: 3 }}>
        <LoadingIcon />
      </Box>
    );
  }

  if (!data || !data.length) {
    return (
      <Box sx={{ p: 3 }}>
        <TypographyWithTranslation i18nKey="enrichmentTool.noDocuments" />
      </Box>
    );
  }

  function navigateToClaimsTable() {
    const existingSearch = window.localStorage.getItem(CLAIM_TABLE);
    navigate(
      claimsRoute.createPath({
        search: existingSearch
          ? `?${existingSearch}`
          : `?${STAGE}=${AWAITING_REVIEW}`,
      })
    );
  }

  async function navigateToNextContext() {
    if (!enableGetNextClaim) {
      navigateToClaimsTable();
      return;
    }

    try {
      updateWindowPerformanceObject(ENRICHMENT_NEXT_CLAIM_START);
      const nextClaim = await getNextClaim(true);

      if (nextClaim) {
        const isAwaitingReview = getIsClaimAwaitingReview(nextClaim);
        const to = isAwaitingReview
          ? `${claimRoute.createPath(nextClaim.id)}?${PAGE_ID}=review`
          : claimRoute.createPath(nextClaim.id);
        navigate(to);
      } else {
        navigateToClaimsTable();
      }
    } catch (error) {
      navigateToClaimsTable();
    }
  }

  const onSubmit = (observations: ClaimObservation[]) => {
    const { id } = claim;
    patchObservations.mutate(
      { id, observations },
      {
        onSuccess: async () => {
          await navigateToNextContext();
        },
      }
    );
  };

  return (
    <EnrichmentToolContextProvider
      claim={claim}
      currentUser={currentUser}
      navigateToNextContext={navigateToNextContext}
    >
      <Card sx={{ overflow: 'hidden', height: { md: 'calc(100vh - 176px)' } }}>
        <EnrichmentForm observations={data} onSubmit={onSubmit} />
      </Card>
      <WarningDialog
        email={currentUser.email}
        lastLockedBy={claim.lastLockedBy}
        locked={claim.locked}
      />
    </EnrichmentToolContextProvider>
  );
}

const StartIcon = styled(SvgIconStyle)(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    display: 'none',
  },
}));

interface EnrichmentFormProps {
  observations: ClaimObservation[];
  onSubmit: (observations: ClaimObservation[]) => void;
}

const DISABLED_DOCUMENT_ITEMS = [
  'control_number',
  'client_name',
  'provider_name',
  'policy_number',
];
const DROPDOWN_ITEMS = [
  'client_tax_id',
  'policy_client_tax_id',
  'provider_tax_id',
  'reason_id',
];
const HARDCODED_DROPDOWN_ITEMS = ['referrer_provider', 'self_funding_tax_id'];
const HARDCODED_MULTISELECT_ITEMS = ['doubts'];
const FORCEFULLY_HIDDEN_LINE_ITEMS = [
  'item_specialty',
  'item_state_contribution',
  'item_description_ocr',
];

function isDisabledDocumentItems({ sproutaiKey }: DocumentContent) {
  return DISABLED_DOCUMENT_ITEMS.includes(sproutaiKey);
}

function isDropdownItems({ sproutaiKey }: DocumentContent) {
  return DROPDOWN_ITEMS.includes(sproutaiKey);
}

function isHardcodedDropdownItems({ sproutaiKey }: DocumentContent) {
  return HARDCODED_DROPDOWN_ITEMS.includes(sproutaiKey);
}

function isHardcodedMultiselectItems({ sproutaiKey }: DocumentContent) {
  return HARDCODED_MULTISELECT_ITEMS.includes(sproutaiKey);
}

function generateTranslatedLabel(item: DocumentContentWithId) {
  return `enrichmentFields.${item.sproutaiKey}`;
}

function isForcefullyHiddenLineItem(item: DocumentContentWithId) {
  return FORCEFULLY_HIDDEN_LINE_ITEMS.includes(item.sproutaiKey);
}

// TODO: remove this function once we have a better way to handle this
function isClientMemIdItem({ sproutaiKey }: DocumentContent) {
  return sproutaiKey === 'client_memid';
}

export function EnrichmentForm({
  observations,
  onSubmit,
}: EnrichmentFormProps) {
  const { t } = useTranslationRoot();
  const enableToolDebug = useFeatureFlag('enableDebugMode');
  const enableThumbnails = useFeatureFlag('enableReviewToolThumbnails');
  const { claim, claimType, currentUser, setState, state } =
    useEnrichmentToolContext();

  const accordionRef = useRef<HTMLDivElement>(null);
  const bodyRef = useRef<HTMLDivElement>(null);

  const isAwaitingReview = getIsClaimAwaitingReview(claim);

  const normalisedData = useMemo(
    () => normaliseData(observations, claim.clientClaimId),
    [claim.clientClaimId, observations]
  );
  const defaultValues = useMemo(
    () => getDefaultValues(normalisedData),
    [normalisedData]
  );
  const methods = useForm<FormValues>({
    defaultValues,
  });
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  useEffect(function initialiseState() {
    setState({
      __originalState: normalisedData,
      ...normalisedData,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    updateWindowPerformanceObject(ENRICHMENT_TOOL_START);
    return () => {
      updateDataLayer({
        event: ENRICHMENT_TOOL_END,
        performance:
          (Date.now() - getEventTimestamp(ENRICHMENT_TOOL_START)) / 1000,
        claimId: claim.id,
      });
    };
  }, []); // eslint-disable-line

  useEffect(() => {
    let interval: string | number | NodeJS.Timer | undefined;

    if (isAwaitingReview) {
      interval = setInterval(() => {
        if (enableToolDebug) {
          rollbar.info('Locking claim after 9 minutes', { claimId: claim.id });
        }
        // calling this direction so that the state is not mutated
        void lockClaim(claim.id);
      }, 60000 * 9);

      return () => {
        clearInterval(interval);
      };
    } else {
      if (interval) {
        clearInterval(interval);
      }

      return () => {
        if (interval) {
          clearInterval(interval);
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [claim.id, isAwaitingReview]);

  useLayoutEffect(() => {
    const accordion = accordionRef.current;

    if (accordion && bodyRef.current) {
      const rect = accordion.getBoundingClientRect();
      const offset = 286 + rect.height + 20;
      bodyRef.current.style.height = `calc(100vh - ${offset}px)`;
    }
  }, [state.fieldsListId]);

  if (state.fieldsListId === '') {
    return null;
  }

  const list = state.lists[state.fieldsListId];
  const fields = list.map((id) => state.fields[id]);

  const columns = [
    {
      field: 'label',
      headerName: t('enrichmentTool.clientKey'),
      width: '160px',
    },
    {
      field: 'value',
      headerName: t('enrichmentTool.value'),
      startAdornment: <SvgIconStyle src={EDIT_ICON} height={17} width={17} />,
      width: 'auto',
    },
  ];

  const hasLineItems = fields.some(isLineLevelItem);
  const hiddenItems = fields.filter(
    (field) =>
      isHighLevelItem(field) ||
      isForcefullyHiddenItem(claimType)(field) ||
      isHiddenByClaimType(claimType)(field)
  );
  const notHiddenItems = fields.filter(
    (field) =>
      !isHighLevelItem(field) &&
      !isForcefullyHiddenItem(claimType)(field) &&
      !isHiddenByClaimType(claimType)(field)
  );

  const addLineItems = () => {
    const lineItems = notHiddenItems.filter(isLineLevelItem);

    // group line items by line index
    const groupedLineItems = getGroupedLineItems(lineItems);
    const exampleRow = [
      ...Object.values(groupedLineItems),
    ].pop() as DocumentLevelContentWithId[];

    const { ids, resources } = exampleRow.reduce(
      (acc, item) => {
        const id = generateUuid();
        const lineIdx = item.lineIdx ? item.lineIdx + 1 : 1;
        return {
          ...acc,
          ids: [...acc.ids, id],
          resources: {
            ...acc.resources,
            [id]: {
              ...item,
              id,
              lineIdx,
              source: 'HIL',
              valid:
                item.sproutaiKey === 'item_specialty' ||
                item.sproutaiKey === 'item_state_contribution_flag' ||
                item.sproutaiKey === 'item_state_contribution',
              value: '',
            },
          },
        } as {
          ids: string[];
          resources: Record<string, DocumentLevelContentWithId>;
        };
      },
      {
        ids: [],
        resources: {},
      } as {
        ids: string[];
        resources: Record<string, DocumentLevelContentWithId>;
      }
    );

    // having to do this to stop the user from losing their previously typed
    // data before adding/removing line items
    const existingFormValues = methods.getValues();
    const existingFields = Object.keys(existingFormValues).reduce(
      (acc, key) => {
        acc[key] = {
          ...state.fields[key],
          value: existingFormValues[key] as string,
        };

        return acc;
      },
      { ...state.fields }
    );

    setState((prevState) => ({
      ...prevState,
      lists: {
        ...prevState.lists,
        [prevState.fieldsListId]: [
          ...prevState.lists[prevState.fieldsListId],
          ...ids,
        ],
      },
      fields: {
        ...prevState.fields,
        ...existingFields,
        ...resources,
      },
    }));
  };

  const renderHiddenItems = (items: DocumentContentWithId[]) => {
    if (items.length) {
      return items.map(({ id }) => <HiddenInput key={id} name={id} />);
    }

    return null;
  };

  const renderDocumentItems = (items: DocumentContentWithId[]) => {
    const documentItems = items.filter(isNotLineLevelItem);
    const rows = documentItems.map((item) => ({
      ...item,
      label: generateTranslatedLabel(item),
      renderCell: (props: Row) => {
        const { id, label, valid, valueType } = props;
        const disabled = isDisabledDocumentItems(props);

        if (isHardcodedDropdownItems(props)) {
          return <HardcodedDropdown {...props} />;
        }

        if (isDropdownItems(props)) {
          return <EnrichedDropdown {...props} />;
        }

        if (isHardcodedMultiselectItems(props)) {
          return <HardcodedMultiselect {...props} />;
        }

        if (valueType === BOOL) {
          return (
            <ControllerWithSingleCheckbox
              name={props.id}
              checkboxProps={{
                'aria-label': t(label) as string,
                size: 'small',
              }}
              sx={{
                ml: 0,
              }}
            />
          );
        }

        if (valueType === STR) {
          if (isClientMemIdItem(props)) {
            //clear the policynumber field when client memid is manually entered ON KEYUP
            const policyNumberId =
              list.find(
                (id) => state.fields[id].sproutaiKey === 'policy_number'
              ) ?? '';

            const onChangeHandler = (
              _event: ChangeEvent<HTMLInputElement>,
              policyNumberId: string
            ) => {
              if (policyNumberId) {
                methods.setValue(policyNumberId, '');
              }
            };

            return (
              <ControllerWithTextFieldV2
                name={id}
                onChangeHandler={(e: ChangeEvent<HTMLInputElement>) =>
                  onChangeHandler(e, policyNumberId)
                }
                textfieldProps={{
                  'aria-label': t(label) as string,
                  disabled,
                  error: typeof valid === 'boolean' && !valid,
                  variant: 'standard',
                  InputProps: { sx: { fontSize: 12 } },
                }}
              />
            );
          } else {
            return (
              <ControllerWithTextFieldV2
                name={id}
                textfieldProps={{
                  'aria-label': t(label) as string,
                  disabled,
                  error: typeof valid === 'boolean' && !valid,
                  variant: 'standard',
                  InputProps: { sx: { fontSize: 12 } },
                }}
              />
            );
          }
        }

        return null;
      },
    }));

    return <FieldsTable columns={columns} rows={rows} />;
  };

  const WithLineItems = ({
    fields,
    lineItems,
  }: {
    fields: ReactNode;
    lineItems: ReactNode;
  }) => (
    <Stack
      spacing={2}
      sx={{
        p: 1,
      }}
    >
      <Stack spacing={1}>
        <TypographyWithTranslation
          variant="subtitle1"
          fontSize={12}
          fontWeight="bold"
          i18nKey="enrichmentTool.documentItems"
        />
        {fields}
      </Stack>
      <Stack
        sx={{
          gap: 1,

          '& .invalid': {
            border: '1px solid',
            borderColor: ({ palette }) => `${palette.error.main} !important`,
          },
        }}
      >
        <Stack
          direction="row"
          sx={{
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <TypographyWithTranslation
            variant="subtitle1"
            fontSize={12}
            fontWeight="bold"
            i18nKey="enrichmentTool.lineItems"
          />
          <Box>
            <Button
              startIcon={
                <SvgIconStyle src={PLUS_ICON} height={14} width={14} />
              }
              size="small"
              sx={{
                fontSize: 12,
              }}
              onClick={addLineItems}
            >
              {t('enrichmentTool.addLineButton')}
            </Button>
          </Box>
        </Stack>
        {lineItems}
        <Box>
          <Button
            startIcon={<SvgIconStyle src={PLUS_ICON} height={14} width={14} />}
            size="small"
            sx={{
              fontSize: 12,
            }}
            onClick={addLineItems}
          >
            {t('enrichmentTool.addLineButton')}
          </Button>
        </Box>
      </Stack>
    </Stack>
  );

  const renderFields = (items: DocumentContentWithId[]) => {
    const node = renderDocumentItems(items);

    return hasLineItems ? (
      <WithLineItems fields={node} lineItems={<LineItems items={items} />} />
    ) : (
      <Box
        sx={{
          width: 1,
        }}
      >
        {node}
      </Box>
    );
  };

  const onFormSubmit = async (data: FormValues) => {
    const getOriginalList = (state: ContextState) => {
      const { __originalState, fieldsListId } = state;
      return __originalState.lists[fieldsListId];
    };

    const getCurrentList = (state: ContextState) => {
      const { lists, fieldsListId } = state;
      return lists[fieldsListId];
    };

    const getFieldId =
      (state: ContextState) => (sproutaiKey: string) => (id: string) => {
        const item = state.fields[id];
        return item.sproutaiKey === sproutaiKey;
      };

    // const markDeletedLineItems = ({
    //   state,
    //   formData
    // }: OnSubmitStateAndDataType) => {
    //   const originalList = getOriginalList(state);
    //   const currentList = getCurrentList(state);
    //   const filteredIds = originalList.filter(
    //     (id) => !currentList.includes(id)
    //   );
    //   const newState = { ...state };
    //
    //   filteredIds.forEach((id) => {
    //     newState.fields[id].deleted = true;
    //   });
    //
    //   return {
    //     state: newState,
    //     formData
    //   };
    // };

    // if treat_manually is false, we need to set doubts to empty string
    const handleTreatManuallyAndDoubts = ({
      state,
      formData,
    }: OnSubmitStateAndDataType) => {
      const newFormData = { ...formData };
      const list = getCurrentList(state);
      const findFieldId = getFieldId(state);
      const treatManuallyId = list.find(findFieldId('treat_manually'));
      const doubtsId = list.find(findFieldId('doubts'));

      if (treatManuallyId && doubtsId) {
        const treatManuallyValue = formData[treatManuallyId] as boolean;
        if (!treatManuallyValue) {
          newFormData[doubtsId] = '';
        }
      }

      return {
        state,
        formData: newFormData,
      };
    };

    const handlePreReimbursedAndSelfFundingTaxId = ({
      state,
      formData,
    }: OnSubmitStateAndDataType) => {
      const newFormData = { ...formData };
      const list = getCurrentList(state);
      const findFieldId = getFieldId(state);
      const preReimbursedId = list.find(findFieldId('pre_reimbursed'));
      const selfFundingTaxIdId = list.find(findFieldId('self_funding_tax_id'));

      if (preReimbursedId && selfFundingTaxIdId) {
        const preReimbursementValue = formData[preReimbursedId] as boolean;
        if (!preReimbursementValue) {
          newFormData[selfFundingTaxIdId] = '';
        }
      }

      return {
        state,
        formData: newFormData,
      };
    };

    const updateFormValuesIntoState = ({
      state,
      formData,
    }: OnSubmitStateAndDataType) => {
      const newState = { ...state };

      Object.entries(formData).forEach(([key, value]) => {
        const item = newState.fields[key];
        const transformer = transformValueFromFormToState(item.valueType);
        newState.fields[key].value = transformer(value) as string;
      });

      return {
        state: newState,
        formData,
      };
    };

    const fixLineIdx = ({ state, formData }: OnSubmitStateAndDataType) => {
      const data = { ...state.fields };
      const items = Object.values(data);
      const lineGroups = items.reduce(
        (acc, item) => {
          if (item.lineIdx !== null && item.lineIdx !== undefined) {
            if (!acc[item.lineIdx]) {
              acc[item.lineIdx] = [item];
            } else {
              acc[item.lineIdx].push(item);
            }
          }

          return acc;
        },
        {} as Record<string, DocumentContentWithId[]>
      );

      // Assign new sequential lineIdx
      let newLineIdx = 0;
      const newLineIdxes = Object.keys(lineGroups)
        .sort((a, b) => parseInt(a) - parseInt(b))
        .reduce((acc, oldLineIdx) => {
          acc[oldLineIdx].forEach((item) => {
            item.lineIdx = newLineIdx;
          });
          newLineIdx++;

          return acc;
        }, lineGroups);

      const dict = Object.values(newLineIdxes).reduce(
        (acc, items) => {
          const obj = { ...acc };

          items.forEach((item) => {
            // @ts-ignore
            obj[item.id] = item.lineIdx;
          });

          return obj;
        },
        {} as Record<string, number>
      );

      const newFields = Object.entries(data).reduce((acc, [key, item]) => {
        const lineIdx = dict[item.id];

        if (lineIdx !== undefined) {
          // @ts-ignore
          acc[key] = {
            ...item,
            lineIdx,
          };
        }

        return acc;
      }, data);

      return {
        state: {
          ...state,
          fields: newFields,
        },
        formData,
      };
    };

    const updateObservations = ({
      state,
      formData,
    }: OnSubmitStateAndDataType) => {
      const newState = { ...state };
      const originalList = getOriginalList(state);
      const currentList = getCurrentList(newState);
      const mergedList = [...originalList, ...currentList];
      const uniqueList = [...new Set(mergedList)];
      const newFields = uniqueList.map((id) => newState.fields[id]);
      const listId = unmakeFieldsList(newState.fieldsListId);
      newState.observations[listId].content.fields = newFields;

      return {
        state: newState,
        formData,
      };
    };

    setIsFormSubmitting(true);

    const result = pipe(
      handleTreatManuallyAndDoubts,
      handlePreReimbursedAndSelfFundingTaxId,
      updateFormValuesIntoState,
      fixLineIdx,
      updateObservations
    )({ state, formData: data });
    const getObservations = (state: ContextState) => {
      const listIds = state.lists.observations;
      return listIds.map((id) => state.observations[id]);
    };

    const getValidationResult = (response: ValidationResponse) => {
      const { results } = response;
      const rule = results.find((result) => result.ruleId === 'AllFieldsValid');

      if (rule) {
        return rule.passed;
      }

      // if no validation result just assumed it's passed and send the results to IDP
      return true;
    };

    const updateFieldsFromValidation = (response: ValidationResponse) => {
      const getObservations = (response: ValidationResponse) =>
        response.observations;
      const getObservation = (observations: ValidationObservation[]) => {
        const listId = unmakeFieldsList(state.fieldsListId);
        return observations.find(
          (observation) => observation.observationId === listId
        );
      };
      const getObservationFields = (observation: ValidationObservation) =>
        observation.fields;
      const getItemLevel = (field: DocumentContent) => field.level;
      const matchDocumentItem = (
        field: DocumentContent,
        item: DocumentContent
      ) => field.sproutaiKey === item.sproutaiKey;
      const matchLineItem = (field: DocumentContent, item: DocumentContent) =>
        field.sproutaiKey === item.sproutaiKey &&
        field.lineIdx === item.lineIdx;
      const selectMatcher = (level: string) =>
        level === 'item' ? matchLineItem : matchDocumentItem;

      const observations = getObservations(response);
      const observation = getObservation(observations);

      if (observation) {
        const fields = getObservationFields(observation);
        const newState = { ...state };
        const list = getCurrentList(newState);
        const newResources = list.reduce((acc, key) => {
          const item = state.fields[key];
          const level = getItemLevel(item);
          const field = fields.find((field) =>
            selectMatcher(level)(field, item)
          );

          if (field) {
            const valid = field.valid;

            acc[key] = {
              ...item,
              valid,
            };
          }

          return acc;
        }, state.fields);

        setState((prevState) => ({
          ...prevState,
          fields: {
            ...prevState.fields,
            ...newResources,
          },
        }));
      }
    };

    const lockClaimAgain = async () => {
      if (
        claim.locked &&
        claim.lastLockedBy === currentUser.email &&
        getIsClaimAwaitingReview(claim)
      ) {
        await lockClaim(claim.id);
      } else {
        throw new Error('Claim is not locked by the current user');
      }
    };

    const observations = getObservations(result.state);

    try {
      await lockClaimAgain();
    } catch (error) {
      if (enableToolDebug) {
        rollbar.info('Failed to lock claim onSubmit', {
          stage: claim.stage,
          claimId: claim.id,
          locked: claim.locked,
        });
      }
      toast.error(t('enrichmentTool.lockClaimError') as string);
      setIsFormSubmitting(false);
      return;
    }

    try {
      updateWindowPerformanceObject(ENRICHMENT_VALIDATION_START);
      const validationResult = await validateClaim(observations);
      updateDataLayer({
        event: ENRICHMENT_VALIDATION_END,
        performance:
          (Date.now() - getEventTimestamp(ENRICHMENT_VALIDATION_START)) / 1000,
        claimId: claim.id,
      });
      const transformedResult = transformKeys(validationResult, toCamelCase);
      const isValid = getValidationResult(transformedResult);

      if (isValid) {
        onSubmit(
          // remove the id from the fields
          observations.map((observation) => {
            if (observation.content?.fields?.length) {
              // @ts-ignore
              if (observation.content.fields.some((field) => field?.id)) {
                return {
                  ...observation,
                  content: {
                    ...observation.content,
                    fields: observation.content.fields.map(
                      // @ts-ignore
                      ({ id, ...rest }) => rest
                    ),
                  },
                };
              }
            }
            return observation;
          })
        );
        return;
      } else {
        toast.error(t('enrichmentTool.validationFail'));
        updateFieldsFromValidation(transformedResult);
      }
    } catch (error) {
      toast.error(
        t('enrichmentTool.validationCatchFail', {
          errorMsg: (error as unknown as { reason_message: string })
            .reason_message,
        })
      );
    }
    setIsFormSubmitting(false);
  };

  const handleKeyDown = (event: any) => {
    // Enter key
    if (event.keyCode === 13) {
      event.preventDefault();
    }

    // CTRL key and Enter key press together then submit
    if (event.ctrlKey && event.keyCode === 13) {
      event.preventDefault();
      methods.handleSubmit(onFormSubmit)();
    }
  };

  // preload images
  preWarmImageCache(normalisedData.urls);
  const reasonMessages = claim.metadata?.reasonDescriptions as string[];

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(onFormSubmit)}
        onKeyDown={handleKeyDown}
      >
        <Stack>
          <FormHeader isFormSubmitting={isFormSubmitting} />

          <Stack direction={{ md: 'row' }}>
            <ResizableBox>
              <Stack>
                <div ref={accordionRef}>
                  <Accordion
                    defaultExpanded
                    disableGutters
                    elevation={0}
                    square
                    sx={{
                      borderRadius: '0 !important',
                      boxShadow: 'none !important',
                      borderBottom: ({ palette }) =>
                        `1px solid ${palette.divider}`,
                    }}
                    onChange={(_, isOpen) => {
                      if (bodyRef.current && accordionRef.current) {
                        if (isOpen) {
                          const rect =
                            accordionRef.current.getBoundingClientRect();
                          const offset = 320 + rect.height;
                          bodyRef.current.style.height = `calc(100vh - ${offset}px)`;
                        } else {
                          bodyRef.current.style.height = 'calc(100vh - 327px)';
                        }
                      }
                    }}
                  >
                    <AccordionSummary
                      expandIcon={
                        <SvgIconStyle
                          src={ARROW_DOWNWARD_ICON}
                          width={18}
                          height={18}
                        />
                      }
                      sx={{
                        backgroundColor: 'background.neutral',
                        minHeight: 40,
                        pl: 1,
                        position: 'relative',
                        '&:before': {
                          // little accent to show a little box to represent resizing
                          content: '""',
                          backgroundColor: 'grey.500',
                          display: 'block',
                          height: 20,
                          width: 3,

                          position: 'absolute',
                          right: -2,
                          top: '50%',
                          transform: 'translateY(-50%)',
                          zIndex: 1,
                        },
                        '& .MuiAccordionSummary-content': {
                          my: 1,
                        },
                      }}
                    >
                      <TypographyWithTranslation
                        i18nKey="enrichmentTool.reasonMessage"
                        variant="subtitle1"
                        sx={{ fontSize: 12 }}
                      />
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        pb: 1,
                      }}
                    >
                      <Stack spacing={2}>
                        {reasonMessages?.length ? (
                          <Stack>
                            <List dense disablePadding>
                              {reasonMessages.map((reasonMessage, index) => (
                                <ListItem key={index} disablePadding>
                                  <ListItemText
                                    primary={reasonMessage}
                                    primaryTypographyProps={{
                                      sx: { fontSize: 12 },
                                    }}
                                  />
                                </ListItem>
                              ))}
                            </List>
                          </Stack>
                        ) : null}
                      </Stack>
                    </AccordionDetails>
                  </Accordion>
                </div>
                <Box
                  component="header"
                  sx={{
                    backgroundColor: 'background.neutral',
                    height: 40,
                    px: 1,
                  }}
                />
                {renderHiddenItems(hiddenItems)}
                <Box
                  ref={bodyRef}
                  sx={{
                    overflowY: 'auto',
                    height: 'calc(100vh - 327px)',
                  }}
                >
                  {renderFields(notHiddenItems)}
                </Box>
              </Stack>
            </ResizableBox>
            <Box
              sx={{
                order: { md: 2, lg: 3 },
                position: 'relative',
                width: 1,
                minWidth: '20%',
              }}
            >
              {state.urls ? (
                <CanvasTool
                  urls={state.urls}
                  useCtrlZoom
                  hasThumbnails={
                    (enableThumbnails &&
                      Object.keys(state.urls).length > 1) as true
                  }
                  thumbnails={Object.entries(state.urls).map(([key, url]) => ({
                    documentId: key,
                    documentGroup: 'enrichment',
                    url,
                  }))}
                />
              ) : (
                <Box
                  sx={{
                    p: 2,
                  }}
                >
                  <TypographyWithTranslation i18nKey="enrichmentTool.noUrls" />
                </Box>
              )}
            </Box>
          </Stack>
        </Stack>
      </form>
    </FormProvider>
  );
}

function ResizableBox({ children }: { children: ReactNode }) {
  const theme = useTheme();
  const isDesktop = useResponsive('up', 'md');

  const [size, setSize] = useState({
    width: isDesktop
      ? localStorage.getItem(generalConfig.claimEnrichmentFormWidthKey) || 400
      : '100%',
    height: '100%',
  });

  const onResizeStop: ResizeCallback = (_event, _direction, ref, d) => {
    setSize((prevState) => {
      localStorage.setItem(
        generalConfig.claimEnrichmentFormWidthKey,
        typeof prevState.width === 'number'
          ? String(prevState.width + d.width)
          : String(ref.clientWidth)
      );

      return {
        ...prevState,
        width: (prevState.width as number) + d.width,
      };
    });
  };

  return (
    <Box
      component={Resizable}
      size={isDesktop ? size : { width: '100%', height: '100%' }}
      onResizeStop={onResizeStop}
      style={{
        borderRight: `1px solid ${theme.palette.divider}`,
      }}
      enable={{
        right: true,
      }}
      sx={{
        order: { xs: 2, md: 1 },
        maxWidth: isDesktop ? '80%' : '100%',
        minWidth: '20%',
        minHeight: '100%',
      }}
    >
      {children}
    </Box>
  );
}

function FormHeader({ isFormSubmitting }: { isFormSubmitting: boolean }) {
  const { t } = useTranslationRoot();
  const { businessUnit, claim, currentUser, navigateToNextContext } =
    useEnrichmentToolContext();
  const {
    closeDialog,
    handleChange,
    handleReject,
    isOpen,
    isSubmitting,
    openDialog,
    reason,
  } = useRejectDialog(claim.id, navigateToNextContext);

  const isNotLockedByCurrentUser =
    claim.locked && claim.lastLockedBy !== currentUser.email;
  const isAwaitingReview = getIsClaimAwaitingReview(claim);
  const { stage } = claim;
  const reviewStationNumber = (claim.metadata?.ReviewStationNumber ||
    claim.metadata?.reviewStationNumber) as string;
  const claimType = claim.properties?.claimType?.toLowerCase();

  return (
    <Stack
      component="header"
      direction="row"
      sx={{
        justifyContent: 'space-between',
        p: 2,
        borderBottom: ({ palette }) => `1px solid ${palette.divider}`,
      }}
    >
      <Stack
        direction="row"
        spacing={2}
        sx={{
          alignItems: 'center',
        }}
      >
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
          {claim.clientClaimId}
        </Typography>
        <Divider orientation="vertical" flexItem />
        <Stack>
          <TypographyWithTranslation
            i18nKey="enrichmentTool.claimType"
            variant="body2"
            sx={{ fontSize: 12 }}
          />
          <Typography variant="subtitle1" sx={{ fontSize: 12 }}>
            {claimType === REIMBURSEMENT
              ? t('enrichmentTool.reimbursement')
              : t('enrichmentTool.provider')}
          </Typography>
        </Stack>
        <Divider orientation="vertical" flexItem />
        <Stack>
          <TypographyWithTranslation
            i18nKey="enrichmentTool.businessUnit"
            variant="body2"
            sx={{ fontSize: 12 }}
          />
          <Typography variant="subtitle1" sx={{ fontSize: 12 }}>
            {businessUnit}
          </Typography>
        </Stack>
        {!!reviewStationNumber && (
          <>
            <Divider orientation="vertical" flexItem />
            <Stack>
              <TypographyWithTranslation
                i18nKey="enrichmentTool.reviewStationNumber"
                variant="body2"
                sx={{ fontSize: 12 }}
              />
              <Typography variant="subtitle1" sx={{ fontSize: 12 }}>
                {reviewStationNumber}
              </Typography>
            </Stack>
          </>
        )}
        <Divider orientation="vertical" flexItem />
        <Box>
          <Label
            i18nKey={statusTranslation[stage]}
            variant="ghost"
            color={STAGE_LABEL_COLOR_MAP[stage]}
          />
        </Box>
        <Divider orientation="vertical" flexItem />
        <Stack>
          <TypographyWithTranslation
            i18nKey="header.lastUpdateTime"
            variant="body2"
            sx={{ fontSize: 12 }}
          />
          <Typography variant="subtitle1" sx={{ fontSize: 12 }}>
            {formatDocumentDate(claim.lastUpdatedOn)}
          </Typography>
        </Stack>
      </Stack>
      <Stack
        direction="row"
        spacing={2}
        sx={{
          alignItems: 'center',
        }}
      >
        <EnrichmentValidationResults
          validationResults={claim.validationResults as ClaimValidationResult[]}
        />

        <ButtonWithTranslation
          disabled={isNotLockedByCurrentUser || !isAwaitingReview}
          i18nKey="enrichmentTool.rejectButton"
          color="error"
          size="small"
          startIcon={
            <StartIcon height={17} src={SQUARE_MINUS_ICON} width={17} />
          }
          variant="contained"
          onClick={openDialog}
          sx={{
            whiteSpace: 'nowrap',
          }}
        />

        <RejectClaimDialog
          isOpen={isOpen}
          closeModal={closeDialog}
          handleChange={handleChange}
          reason={reason}
          onReject={handleReject}
          isSubmitting={isSubmitting}
        />

        <Tooltip title={t('enrichmentTool.shortcutKeySubmit')}>
          <span>
            <ButtonWithTranslation
              disabled={
                isNotLockedByCurrentUser ||
                isFormSubmitting ||
                !isAwaitingReview
              }
              i18nKey="enrichmentTool.submitButton"
              size="small"
              startIcon={
                <StartIcon height={17} src={CIRCLE_CHECK_ICON} width={17} />
              }
              variant="contained"
              type="submit"
              sx={{
                whiteSpace: 'nowrap',
              }}
            />
          </span>
        </Tooltip>
      </Stack>
    </Stack>
  );
}

function useRejectDialog(claimId: string, navigateToNextContext: () => void) {
  const { t } = useTranslationRoot();
  const [isOpen, openDialog, closeDialog] = useBoolean();
  const [reason, setReason] = useState<string>('');
  const rejectClaim = useRejectClaim({
    meta: {
      loadingMessage: t('rejectDialog.submitLoading') as string,
      successMessage: t('rejectDialog.submitSuccess') as string,
      errorMessage: t('rejectDialog.submitFail') as string,
    },
  });

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setReason(event.target.value);
  };

  const handleReject = () => {
    rejectClaim.mutate(
      { id: claimId, reason },
      {
        onSuccess: () => {
          navigateToNextContext();
        },
      }
    );
  };

  return {
    closeDialog,
    handleChange,
    handleReject,
    isOpen,
    isSubmitting: rejectClaim.isPending,
    openDialog,
    reason,
  };
}

function makeFieldsList(id: string) {
  return `${id}-fields`;
}

function unmakeFieldsList(listId: string) {
  return listId.replace('-fields', '');
}

function makeEnrichmentList(id: string) {
  return `${id}-enrichment`;
}

function normaliseData(
  observations: ClaimObservation[],
  clientClaimId: string | null
) {
  const data: NormalisedData = {
    businessUnit: '',
    dropdownLevelMap: {},
    enrichmentListId: '',
    fieldsListId: '',
    lists: {
      observations: [],
    },
    enrichment: {},
    fields: {},
    observations: {},
    groupedEnrichmentLevels: {},
    urls: {},
  };

  const addIdToItem = (item: DocumentContent) => ({
    ...item,
    id: generateUuid(),
  });

  const getContentFieldsWithIds = (fields: DocumentContent[]) => {
    const fieldsWithIds = fields.map(addIdToItem);
    return convertArrayToListAndDict<DocumentContentWithId>(
      fieldsWithIds,
      'id'
    ) as [string[], Resources];
  };

  const getEnrichmentType = (observation: ClaimObservation) =>
    observation.type === ObservationType.ENRICHMENT;

  const getDocumentType = (observation: ClaimObservation) =>
    observation.type === ObservationType.DOCUMENT;

  const getPropertiesType = (observation: ClaimObservation) =>
    observation.type === ObservationType.PROPERTIES;

  const getDocumentGroupItem = (item: DocumentContent) =>
    item.sproutaiKey === 'document_group';

  const getBusinessUnitItem = (item: DocumentContent) =>
    item.sproutaiKey === 'business_unit';

  const getFeature = (item: DocumentContent) => item.value === 'feature';

  const normaliseObservations = ({ state, obs }: NormalisedState) => ({
    state: obs.reduce((acc, observation) => {
      acc.lists['observations'] = [...acc.lists.observations, observation.id];
      acc.observations[observation.id] = observation;

      return acc;
    }, state),
    obs,
  });

  const normaliseFields = ({ state, obs }: NormalisedState) => ({
    state: obs.reduce((acc, observation) => {
      if (getDocumentType(observation)) {
        const documentGroup =
          observation.content.fields.find(getDocumentGroupItem);

        if (documentGroup && getFeature(documentGroup)) {
          const listId = makeFieldsList(observation.id);

          if (!acc.fieldsListId) {
            acc.fieldsListId = listId;
          }

          const [list, resources] = getContentFieldsWithIds(
            observation.content.fields
          );
          acc.lists[listId] = list;
          acc.fields = {
            ...acc.fields,
            ...resources,
          };
          acc.urls = observation.content.urls;
        }
      }

      return acc;
    }, state),
    obs,
  });

  const normaliseEnrichment = ({ state, obs }: NormalisedState) => ({
    state: obs.reduce((acc, observation) => {
      if (getEnrichmentType(observation)) {
        const listId = makeEnrichmentList(observation.id);

        if (!acc.enrichmentListId) {
          acc.enrichmentListId = listId;
        }

        const [list, resources] = getContentFieldsWithIds(
          observation.content.fields
        );
        acc.lists[listId] = list;
        acc.enrichment = {
          ...acc.enrichment,
          ...resources,
        };
      }

      return acc;
    }, state),
    obs,
  });

  const groupEnrichment = ({ state, obs }: NormalisedState) => {
    const { enrichmentListId, enrichment, fields, fieldsListId, lists } = state;
    const enrichmentItems = Object.keys(enrichment).length
      ? lists[enrichmentListId].map((id) => enrichment[id])
      : [];

    const groupedEnrichmentLevels = enrichmentItems.reduce(
      (groups, item) => {
        const lineIndex = String(item.lineIdx);

        if (!groups[item.level]) {
          groups[item.level] = {};
        }

        if (!groups[item.level][lineIndex]) {
          groups[item.level][lineIndex] = {};
        }

        const fieldId = lists[fieldsListId].find((id) => {
          // TODO: remove this once we have a better way to handle this
          // adding client_policy_number to the groupedEnrichmentLevels
          if (item.sproutaiKey === 'client_policy_number') {
            return fields[id].sproutaiKey === 'policy_number';
          }

          return fields[id].sproutaiKey === item.sproutaiKey;
        });

        if (fieldId) {
          groups[item.level][lineIndex] = {
            ...groups[item.level][lineIndex],
            [fieldId]: item,
          };
        }

        return groups;
      },
      {} as Record<
        string,
        Record<string, Record<string, DocumentContentWithId>>
      >
    );

    return { state: { ...state, groupedEnrichmentLevels }, obs };
  };

  const populateFieldsWithEnrichedData = ({ state, obs }: NormalisedState) => {
    const {
      enrichment,
      enrichmentListId,
      fields,
      fieldsListId,
      groupedEnrichmentLevels,
      lists,
    } = state;

    if (Object.keys(enrichment).length !== 0) {
      const fieldsList = lists[fieldsListId];

      fieldsList.forEach((id) => {
        const field = fields[id];

        if (isDropdownItems(field)) {
          const { sproutaiKey, value } = field;
          const enrichmentId = lists[enrichmentListId].find(
            (enrichmentId) =>
              enrichment[enrichmentId].sproutaiKey === sproutaiKey &&
              enrichment[enrichmentId].value === value
          );

          if (enrichmentId && enrichment[enrichmentId]) {
            const { level, lineIdx } = enrichment[enrichmentId];
            const group = groupedEnrichmentLevels[level]?.[String(lineIdx)];
            // check if the group has related enrichment data
            const groupHasRelatedEnrichmentData = Object.keys(group).some(
              (key) => group[key].value === field.value
            );

            if (group) {
              if (!state.dropdownLevelMap?.[id]) {
                state.dropdownLevelMap = {
                  ...state.dropdownLevelMap,
                  [id]: level,
                };
              }

              Object.keys(group).forEach((key) => {
                const enrichedData = group[key];

                if (state.fields[key]) {
                  state.fields[key].value = groupHasRelatedEnrichmentData
                    ? enrichedData.value
                    : state.fields[key].value;
                }
              });
            }
          } else {
            const enrichmentId = lists[enrichmentListId].find(
              (enrichmentId) =>
                enrichment[enrichmentId].sproutaiKey === sproutaiKey
            );

            if (enrichmentId && enrichment[enrichmentId]) {
              const { level, lineIdx } = enrichment[enrichmentId];
              const group = groupedEnrichmentLevels[level]?.[String(lineIdx)];

              if (group) {
                if (!state.dropdownLevelMap?.[id]) {
                  state.dropdownLevelMap = {
                    ...state.dropdownLevelMap,
                    [id]: level,
                  };
                }
              }
            }
          }
        }
      });
    }

    return { state, obs };
  };

  const populateControlNumberFieldWithClientClaimId = ({
    state,
    obs,
  }: NormalisedState) => {
    const newState = { ...state };
    const { fields, fieldsListId, lists } = state;
    const fieldsList = lists[fieldsListId];
    const controlNumberFieldId = fieldsList.find(
      (id) => fields[id].sproutaiKey === 'control_number'
    );

    if (controlNumberFieldId && clientClaimId) {
      newState.fields[controlNumberFieldId].value = clientClaimId;
    }

    return { state: newState, obs };
  };

  //TODO: comment out this function for now
  /*
  const populatePolicyNumberValueFromEnrichment = ({
    state,
    obs
  }: NormalisedState) => {
    const newState = { ...state };
    const { enrichment, enrichmentListId, fields, fieldsListId, lists } =
      newState;
    const enrichmentPolicyNumberId = lists[enrichmentListId].find(
      (id) => enrichment[id].sproutaiKey === 'policy_number'
    );
    // TODO: find a better way to handle this
    // geting client_policy_number from enrichment
    const enrichmentClientPolicyNumberId = lists[enrichmentListId].find(
      (id) => enrichment[id].sproutaiKey === 'client_policy_number'
    );

    const fieldPolicyNumberId = lists[fieldsListId].find(
      (id) => fields[id].sproutaiKey === 'policy_number'
    );

    if (
      enrichmentPolicyNumberId &&
      fieldPolicyNumberId &&
      enrichmentClientPolicyNumberId
    ) {
      newState.fields[fieldPolicyNumberId].value =
        enrichment[enrichmentClientPolicyNumberId].value;
    }

    return {
      state: newState,
      obs
    };
  };
  */

  // const populateProviderNameValueFromEnrichment = ({
  //   state,
  //   obs
  // }: NormalisedState) => {
  //   const newState = { ...state };
  //   const { enrichment, enrichmentListId, fields, fieldsListId, lists } =
  //     newState;
  //   const enrichmentProviderNameId = lists[enrichmentListId].find(
  //     (id) => enrichment[id].sproutaiKey === 'provider_name'
  //   );
  //   const fieldProviderNameId = lists[fieldsListId].find(
  //     (id) => fields[id].sproutaiKey === 'provider_name'
  //   );
  //
  //   if (enrichmentProviderNameId && fieldProviderNameId) {
  //     newState.fields[fieldProviderNameId].value =
  //       enrichment[enrichmentProviderNameId].value;
  //   }
  //
  //   return {
  //     state: newState,
  //     obs
  //   };
  // };

  const getBusinessUnitFromPropertiesObservation = ({
    state,
    obs,
  }: NormalisedState) => ({
    state: obs.reduce((acc, observation) => {
      if (getPropertiesType(observation)) {
        const businessUnitItem =
          observation.content.fields.find(getBusinessUnitItem);

        if (businessUnitItem) {
          acc.businessUnit = businessUnitItem.value;
        }
      }

      return acc;
    }, state),
    obs,
  });

  const result = pipe<NormalisedState>(
    normaliseObservations,
    normaliseFields,
    normaliseEnrichment,
    groupEnrichment,
    populateFieldsWithEnrichedData,
    populateControlNumberFieldWithClientClaimId,
    getBusinessUnitFromPropertiesObservation
  )({
    state: data,
    obs: observations,
  });

  return result.state;
}

function transformValueFromStateToForm(valueType: DocumentValueType) {
  return (value: string) => {
    switch (valueType) {
      case BOOL:
        return value === 'True';
      case INT:
      case FLOAT:
        return parseInt(value, 10);
      default:
        return value ?? '';
    }
  };
}

function transformValueFromFormToState(valueType: DocumentValueType) {
  return (value: string | boolean | number | null) => {
    switch (valueType) {
      case BOOL:
        return value ? 'True' : 'False';
      case INT:
      case FLOAT:
        return String(value);
      default:
        return value;
    }
  };
}

function getDefaultValues({ fieldsListId, lists, fields }: NormalisedData) {
  const fieldsList = lists[fieldsListId];
  return fieldsList.reduce(
    (acc, id) => {
      const field = fields[id];

      if (field) {
        const transformer = transformValueFromStateToForm(field.valueType);
        acc[field.id] = transformer(field.value);
      }

      return acc;
    },
    {} as Record<string, string | boolean | number | null>
  );
}

// Don't add line items in here as it'll break the updating of
// related line items
const FORCEFULLY_HIDDEN_FIELDS = [
  'document_group',
  'document_provider',
  'document_type',
  'has_medical_prescription',
  'total_state_contribution',
];
const PROVIDER_FORCEFULLY_HIDDEN_FIELDS = [
  ...FORCEFULLY_HIDDEN_FIELDS,
  'referrer_provider',
];
const REIMBURSEMENT_FIELDS = [
  'credit_note_number',
  'credit_note_date',
  'credit_note_total_value',
];
const PROVIDER_FIELDS = [
  'client_tax_id',
  'client_memid',
  'client_multichannel',
  'policy_number',
  'provider_name',
  'treat_manually',
  'self_funding_tax_id',
  'doubts',
];
const HIDDEN_BY_BOOLEAN: Record<string, string> = {
  doubts: 'treat_manually',
  self_funding_tax_id: 'pre_reimbursed',
};

const isProviderClaimType = (claimType: string) =>
  claimType.toLowerCase() === PROVIDER;
const isReimbursementClaimType = (claimType: string) =>
  claimType.toLowerCase() === REIMBURSEMENT;

function isForcefullyHiddenItem(claimType: string) {
  return (item: DocumentContent): boolean => {
    const hiddenFields = isProviderClaimType(claimType)
      ? PROVIDER_FORCEFULLY_HIDDEN_FIELDS
      : FORCEFULLY_HIDDEN_FIELDS;

    return hiddenFields.includes(item.sproutaiKey);
  };
}

function isHiddenByClaimType(claimType: string) {
  return (item: DocumentContent): boolean => {
    if (isReimbursementClaimType(claimType)) {
      return REIMBURSEMENT_FIELDS.includes(item.sproutaiKey);
    }

    if (isProviderClaimType(claimType)) {
      return PROVIDER_FIELDS.includes(item.sproutaiKey);
    }

    return false;
  };
}

const baseTableStyles = {
  fontSize: 12,
  height: 36,
  overflow: 'hidden',
  px: 1,
  py: 0,
  whiteSpace: 'nowrap',
};

type Column = {
  field: string;
  headerName: string;
  startAdornment?: ReactNode;
  width?: number | string;
};

type Row = {
  label: string;
  renderCell?: (props: Row) => ReactNode;
  [key: string]: any;
} & DocumentContentWithId;

interface FieldsTableProps {
  columns: Column[];
  rows: Row[];
}

function FieldsTable({ columns, rows }: FieldsTableProps) {
  return (
    <TableContainer>
      <Table size="small" sx={{ position: 'relative', tableLayout: 'fixed' }}>
        <TableHead>
          <TableRow>
            {columns.map(({ headerName, startAdornment, width }, index) => (
              <TableCell
                key={index}
                sx={{
                  ...baseTableStyles,
                  backgroundColor: 'transparent',
                  color: 'text.primary',
                  fontWeight: 'bold',
                  width: width ?? 'auto',
                  '&:first-of-type': {
                    boxShadow: 'inherit',
                    pl: 1,
                    borderRadius: 0,
                  },
                  '&:last-of-type': {
                    boxShadow: 'inherit',
                    pr: 1,
                    borderRadius: 0,
                  },
                }}
              >
                <Stack
                  direction="row"
                  sx={{
                    alignItems: 'center',
                    gap: 1,
                  }}
                >
                  {startAdornment}
                  {headerName}
                </Stack>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <FieldsTableRow key={row.id} columns={columns} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

/**
 * more hardcoded shit just to hide fields if a checkbox deem it to
 */
function useShowHideFields(row: Row) {
  const { state } = useEnrichmentToolContext();
  const { control, getValues } = useFormContext();
  const { fields, fieldsListId, lists } = state;
  const flag = HIDDEN_BY_BOOLEAN[row.sproutaiKey];
  const itemId = flag
    ? lists[fieldsListId].find((id) => fields[id].sproutaiKey === flag)
    : undefined;
  const currentValue = useWatch({
    control,
    name: itemId as string,
    exact: true,
    disabled: !itemId,
  });
  const fieldValue = itemId ? getValues(itemId) : true;
  const [isVisible, setIsVisible] = useState<boolean>(flag ? fieldValue : true);

  useEffect(() => {
    if (flag && itemId) {
      setIsVisible(currentValue);
    }
  }, [flag, itemId, currentValue]);

  return {
    isVisible,
  };
}

function FieldsTableRow({ columns, row }: { columns: Column[]; row: Row }) {
  const { t } = useTranslationRoot();
  const { isVisible } = useShowHideFields(row);
  const { id } = row;

  const isInput = (field: string) => field === 'value';

  return (
    <TableRow
      key={id}
      sx={{
        display: isVisible
          ? 'table-row'
          : row.sproutaiKey === 'reimbursed' ||
              row.sproutaiKey === 'reimbursed_value'
            ? 'none'
            : 'none',
      }}
    >
      {columns.map(({ field }, index) => (
        <TableCell
          key={index}
          sx={{
            ...baseTableStyles,
            '&:first-of-type': { pl: 1 },
            '&:last-of-type': { pr: 1 },
          }}
        >
          {isInput(field)
            ? row.renderCell
              ? row.renderCell(row)
              : row[field]
            : t(row[field])}
        </TableCell>
      ))}
    </TableRow>
  );
}

function EnrichedDropdown(props: Row) {
  const { t } = useTranslationRoot();
  const { setState, state } = useEnrichmentToolContext();
  const { register, setValue } = useFormContext();
  const { id, sproutaiKey, value } = props;

  const level = state.dropdownLevelMap[id];
  const enrichmentLevels = state.groupedEnrichmentLevels[level];
  const initialOptions = [{ id, sproutaiKey, value }];
  const enrichmentOptions = enrichmentLevels
    ? (Object.values(enrichmentLevels)
        .map((items) => {
          const result = Object.values(items).find(
            (item) => item.sproutaiKey === sproutaiKey
          );

          if (result && result.value) {
            return result;
          }

          return null;
        })
        .filter(Boolean) as DocumentContentWithId[])
    : [];
  const options = [...initialOptions, ...enrichmentOptions].filter(Boolean);

  const handleClear = () => {
    //find the group that contains the deleted field
    const group = Object.values(enrichmentLevels).find((group) =>
      Object.values(group).find((item) => item.sproutaiKey === sproutaiKey)
    );

    // set the form value of each item in the group
    if (group) {
      Object.entries(group).forEach(([key, item]) => {
        if (item.valueType !== sproutaiKey) {
          setValue(key, '');
        }
      });
    }
  };

  const updateValue = (newValue: any) => {
    setState((prevState) => ({
      ...prevState,
      fields: {
        ...prevState.fields,
        [id]: {
          ...prevState.fields[id],
          value: newValue,
        },
      },
    }));
  };

  const handleChange = (newValue: string) => {
    // find the group that contains the selected value
    const group = Object.values(enrichmentLevels).find((group) =>
      Object.values(group).find(
        (item) =>
          item.sproutaiKey === props.sproutaiKey && item.value === newValue
      )
    );

    // set the form value of each item in the group
    if (group) {
      Object.entries(group).forEach(([key, item]) => {
        if (item.valueType !== sproutaiKey) {
          setValue(key, item.value);
        }
      });
    } else if (!group) {
      handleClear();
    } else if (newValue === '') {
      handleClear();
    }
  };

  return (
    <Autocomplete
      defaultValue={value}
      freeSolo
      onBlur={(event) => {
        const newValue = (event.target as HTMLInputElement).value;

        setState((prevState) => ({
          ...prevState,
          fields: {
            ...prevState.fields,
            [id]: {
              ...prevState.fields[id],
              value: newValue as any,
            },
          },
        }));
      }}
      onChange={(_event, newValue, reason) => {
        if (reason === 'clear') {
          handleClear();
        } else if (reason === 'selectOption') {
          handleChange(newValue ?? '');
          updateValue(newValue);
        }
      }}
      onInputChange={(_event, newValue, reason) => {
        if (reason === 'input') {
          handleChange(newValue ?? '');
        }
      }}
      componentsProps={{
        popper: {
          sx: {
            boxShadow: (theme) => theme.shadows[1],
            width: 'auto !important',
          },
        },
      }}
      ListboxProps={{
        sx: { fontSize: 12 },
      }}
      options={unique(options.map(({ value }) => value))}
      renderInput={(params) => (
        <TextField
          {...merge(params, register(id))}
          aria-label={t(props.label) as string}
          error={!props.valid}
          variant="standard"
          inputProps={{ ...params.inputProps, sx: { fontSize: 12 } }}
        />
      )}
    />
  );
}

interface ResizeableLineItemBoxProps {
  children: ReactNode;
  type: string;
  field: string;
  hidden: boolean;
}

function ResizableLineItemBox({
  children,
  hidden,
  type,
  field,
}: ResizeableLineItemBoxProps) {
  const defaultWidth = type === 'singleSelect' ? 234 : 117;
  const storedWidth = localStorage.getItem('claimReviewLineItemsWidth');
  const parsedWidth = storedWidth ? JSON.parse(storedWidth) : {};
  const defaultState = {
    ...parsedWidth,
    [field]: parsedWidth[field] ?? defaultWidth,
  };
  const [widths, setWidths] = useState<Record<string, number>>(defaultState);

  const onResizeStop: ResizeCallback = (_event, _direction, _ref, d) => {
    setWidths((prevState) => {
      const newState = {
        ...prevState,
        [field]: prevState[field] + d.width,
      };

      localStorage.setItem(
        'claimReviewLineItemsWidth',
        JSON.stringify(newState)
      );
      window.dispatchEvent(new Event('UPDATE_LINE_ITEM_WIDTH'));

      return newState;
    });
  };

  useEffect(() => {
    const updateWidths = () => {
      const storedWidth = localStorage.getItem('claimReviewLineItemsWidth');
      const parsedWidth = storedWidth ? JSON.parse(storedWidth) : {};
      setWidths((prevState) => ({
        ...prevState,
        ...parsedWidth,
      }));
    };
    window.addEventListener('UPDATE_LINE_ITEM_WIDTH', updateWidths);

    return () => {
      window.removeEventListener('UPDATE_LINE_ITEM_WIDTH', updateWidths);
    };
  }, []);

  return (
    <Box
      component={Resizable}
      size={{
        width: widths[field],
        height: 'auto',
      }}
      enable={{
        right: true,
      }}
      handleClasses={{ right: 'item-resize' }}
      onResizeStop={onResizeStop}
      sx={{
        order: { xs: 2, md: 1 },
        maxWidth: '100%',
        minWidth: '40px',
        backgroundColor: 'inherit',
        color: (theme) => theme.palette.text.primary,
        display: hidden ? 'none' : 'block',
        fontSize: 12,
        overflow: 'hidden',
        p: 0.5,
        position: 'relative',

        '.item-resize:before': {
          // little accent to show a little box to represent resizing
          content: '""',
          backgroundColor: 'grey.50016',
          display: 'block',
          height: 20,
          width: '1px',

          position: 'absolute',
          right: 8,
          top: '50%',
          transform: 'translateY(-50%)',
          zIndex: 1,
        },
      }}
    >
      {children}
    </Box>
  );
}

function LineItems({ items }: { items: DocumentContentWithId[] }) {
  const { t } = useTranslationRoot();
  const { state, setState } = useEnrichmentToolContext();
  const { unregister, getValues, setValue } = useFormContext();
  const { columns, rows } = generateLineItems(items);
  const clipboardRef = useRef<LineItemRow | null>(null);

  const writeToClipboardRef = (row: LineItemRow) => {
    toast.custom(t('enrichmentTool.tooltipCopySuccess'), { duration: 2000 });
    clipboardRef.current = row;
  };

  const pasteToLineItems = (row: LineItemRow) => {
    const { keyToId, lineIdx, ...rest } = row;

    if (clipboardRef.current && clipboardRef.current?.lineIdx !== lineIdx) {
      Object.keys(rest).forEach((key) => {
        const id = keyToId[key];

        if (clipboardRef.current?.[key]) {
          const valueType = state.fields[id].valueType;

          if (valueType === BOOL) {
            setValue(id, clipboardRef.current?.[key] === 'True');
          } else {
            setValue(id, clipboardRef.current?.[key] ?? '');
          }
        }
      });
    }
  };

  const deleteLineItems = (row: LineItemRow) => () => {
    const ids = Object.values(row.keyToId) as string[];
    unregister(ids);

    const list = state.lists[state.fieldsListId];
    const filteredList = list.filter((id) => !ids.includes(id));

    // also might as well updates fields object at the same time
    const newFields = ids.reduce((acc, id) => {
      acc[id] = {
        ...state.fields[id],
        deleted: true,
      };
      return acc;
    }, {} as Resources);

    // having to do this to stop the user from losing their previously typed
    // data before adding/removing line items
    const existingFormValues = getValues();
    const existingFields = Object.keys(existingFormValues).reduce(
      (acc, key) => {
        acc[key] = {
          ...state.fields[key],
          value: existingFormValues[key],
        };

        return acc;
      },
      { ...state.fields }
    );

    setState((prevState) => ({
      ...prevState,
      lists: {
        ...prevState.lists,
        [prevState.fieldsListId]: filteredList,
      },
      fields: {
        ...prevState.fields,
        ...existingFields,
        ...newFields,
      },
    }));

    Object.keys(existingFormValues).forEach((key) => {
      setValue(key, existingFormValues[key]);
    });
  };

  return (
    <Paper>
      <Stack spacing={2}>
        {rows.map((row, index) => (
          <Grid
            container
            key={index}
            spacing={1}
            sx={{
              alignItems: 'center',
              borderTop: '1px solid',
              borderTopColor: 'divider',
              borderTopWidth: index === 0 ? 0 : 1,
              paddingTop: index === 0 ? 0 : 1,
              px: 0.5,
            }}
          >
            <Grid size={{ xs: 12 }}>
              <Stack
                direction="row"
                spacing={1}
                sx={{
                  alignItems: 'center',
                }}
              >
                <TypographyWithTranslation
                  fontSize={12}
                  fontWeight="bold"
                  i18nKey="enrichmentTool.lineNumber"
                  options={{ number: index + 1 }}
                />
                {rows.length > 1 ? (
                  <>
                    <Tooltip title={t('enrichmentTool.tooltipDelete')}>
                      <IconButton
                        size="small"
                        color="error"
                        onClick={deleteLineItems(row)}
                        data-testid="delete-line-item"
                      >
                        <SvgIconStyle src={TRASH_ICON} height={18} width={18} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={t('enrichmentTool.tooltipCopy')}>
                      <IconButton
                        size="small"
                        onClick={() => writeToClipboardRef(row)}
                      >
                        <SvgIconStyle src={COPY_ICON} height={18} width={18} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={t('enrichmentTool.tooltipPaste')}>
                      <IconButton
                        size="small"
                        onClick={() => pasteToLineItems(row)}
                      >
                        <SvgIconStyle src={PASTE_ICON} height={18} width={18} />
                      </IconButton>
                    </Tooltip>
                  </>
                ) : null}
              </Stack>
            </Grid>

            <Grid size={{ xs: 12 }}>
              <Stack
                direction="row"
                sx={{
                  flexWrap: 'wrap',
                }}
              >
                {columns.map(({ field, type, headerName, hidden }, idx) => {
                  const name = row.keyToId[field];
                  const item = state.fields[name];

                  const Wrapper = ({ children }: { children: ReactNode }) => (
                    <Box
                      component={ResizableLineItemBox}
                      sx={{
                        backgroundColor: 'inherit',
                        color: ({ palette }) => palette.text.primary,
                        display: hidden ? 'none' : 'block',
                        flex: type === 'singleSelect' ? '1 0 auto' : '0 1 auto',
                        fontSize: 12,
                        minWidth: type === 'singleSelect' ? 234 : 117,
                        maxWidth: type === 'singleSelect' ? 234 : 117,
                        width: 1,
                      }}
                      hidden={hidden}
                      field={field}
                      type={type}
                    >
                      {children}
                    </Box>
                  );

                  if (type === 'boolean') {
                    return (
                      <Wrapper key={idx}>
                        <ControllerWithSingleCheckbox
                          label={t(headerName) as string}
                          name={name}
                          checkboxProps={{
                            'aria-label': t(headerName) as string,
                            size: 'small',
                          }}
                          sx={{
                            alignItems: 'flex-start',
                            mx: 0,
                            '& .MuiFormControlLabel-label': {
                              color: 'grey.500',
                              fontSize: 12,
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                            },
                          }}
                        />
                      </Wrapper>
                    );
                  }

                  if (type === 'singleSelect') {
                    return (
                      <Wrapper key={idx}>
                        <LineItemDropdown
                          name={name}
                          label={t(headerName) as string}
                          error={!item.valid}
                          row={row}
                          value={row[field]}
                        />
                      </Wrapper>
                    );
                  }

                  return (
                    <Wrapper key={idx}>
                      <ControllerWithTextFieldV2
                        name={name}
                        textfieldProps={{
                          error: !item.valid,
                          label: t(headerName),
                          InputLabelProps: {
                            shrink: true,
                          },
                          inputProps: {
                            sx: {
                              fontSize: 12,
                              height: 26,
                            },
                          },
                          variant: 'standard',
                        }}
                      />
                    </Wrapper>
                  );
                })}
              </Stack>
            </Grid>
          </Grid>
        ))}
      </Stack>
    </Paper>
  );
}

function useGetLookup({
  businessUnit,
  searchText,
  searchKey,
  code,
}: {
  businessUnit: string;
  searchText: string;
  searchKey: string;
  code?: string;
}) {
  const params = {
    business_unit: businessUnit,
    search_text: searchText,
    search_key: searchKey,
    code: code ?? '',
  };

  function generateUrl() {
    return code
      ? `lookup/code\\?search_text=:search_text;code=:code&search_key=:search_key&business_unit=:business_unit`
      : `lookup/code\\?search_text=:search_text&search_key=:search_key&business_unit=:business_unit`;
  }

  return useGet<
    { lookup_codes: Record<string, string>[] },
    Record<string, string>[]
  >({
    url: generateUrl(),
    params: { ...params },

    select: ({ lookup_codes }) => lookup_codes,
    enabled: !!searchText,
    gcTime: Infinity,
    staleTime: Infinity,
  });
}

const LOOKUP_MAP: Record<string, string> = {
  code: 'item_code',
  description: 'item_description',
  specialty: 'item_specialty',
};

function generateUniqueOptionName(code: string, description: string) {
  // replace description spaces with hyphen
  return `${code}-${description?.replace(/\s/g, '-')}`;
}

type LineItemRow = {
  [key: string]: any;
  lineIdx: number;
};

interface LineItemDropdownProps {
  error: boolean;
  label: string;
  name: string;
  row: LineItemRow;
  value: string;
}

function LineItemDropdown({
  error,
  label,
  name,
  row,
  value = '',
}: LineItemDropdownProps) {
  const { control, register, setValue, getValues } = useFormContext();
  const { businessUnit, state } = useEnrichmentToolContext();
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<any[]>([]);
  const [inputValue, setInputValue] = useState(value ?? '');
  const prevInputValue = useRef(inputValue);
  const [searchText, setSearchText] = useState('');
  // const loading = open && options.length === 0;
  const { sproutaiKey } = state.fields[name];
  const searchKey = sproutaiKey === 'item_code' ? 'code' : 'description';
  const currentValue = useWatch({ control, name });
  const { data, isFetching } = useGetLookup({
    businessUnit: businessUnit || state.businessUnit,
    searchKey,
    searchText,
    code: searchKey !== 'code' ? getValues(row.keyToId.item_code) : '',
  });

  const dataWithIds =
    data?.map((params) => ({
      id: generateUniqueOptionName(params.code, params.description),
      ...params,
    })) || [];

  const updateRelatedFields = (newValue: Record<string, string>) => {
    if (newValue) {
      Object.keys(newValue).forEach((key) => {
        if (key !== 'id') {
          const lookupName = LOOKUP_MAP[key];
          const id = row.keyToId[lookupName];
          const value = newValue[key];

          setValue(id, value);
        }
      });
    }
  };

  useEffect(() => {
    if (data) {
      setOptions(dataWithIds);
    }
  }, [data]); // eslint-disable-line

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  useEffect(() => {
    const isDiff = prevInputValue.current !== inputValue;
    const handler = isDiff
      ? setTimeout(() => {
          setSearchText(inputValue);
        }, 400)
      : null;

    if (isDiff) {
      prevInputValue.current = inputValue;
    }

    return () => {
      if (handler) {
        clearTimeout(handler);
      }
    };
  }, [inputValue]);

  useEffect(() => {
    // this one updates related field inputValue
    // so when a user selects code, the description field will be updated
    if (inputValue !== currentValue) {
      setInputValue(currentValue || '');
    }
  }, [currentValue]); // eslint-disable-line

  return (
    <Autocomplete
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      loading={isFetching}
      freeSolo
      onChange={(_event, newValue) => {
        updateRelatedFields(newValue);
      }}
      defaultValue={value ?? ''}
      getOptionLabel={(option) => option[searchKey] || inputValue}
      inputValue={inputValue}
      onInputChange={(_event, newValue) => {
        setInputValue(newValue ?? '');
      }}
      componentsProps={{
        popper: {
          sx: {
            boxShadow: (theme) => theme.shadows[1],
            width: 'auto !important',
          },
        },
      }}
      filterOptions={(x) => x}
      ListboxProps={{
        sx: { fontSize: 12 },
      }}
      options={options}
      renderOption={(props, { id, ...option }) => (
        <Box component="li" {...props} key={id}>
          {Object.values(option).join(', ')}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          {...register(name)}
          error={error}
          label={label}
          variant="standard"
          InputLabelProps={{
            ...params.InputLabelProps,
            shrink: true,
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isFetching ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          inputProps={{
            ...params.inputProps,
            sx: { fontSize: 12, height: 26 },
          }}
        />
      )}
    />
  );
}

const lineItemDropdownFields = ['item_code', 'item_description'];

function isLineItemDropdownType(item: DocumentContent) {
  return lineItemDropdownFields.includes(item.sproutaiKey);
}

function getGroupedLineItems(items: DocumentContentWithId[]) {
  return items.reduce(
    (groups, item) => {
      const lineIndex = String(item.lineIdx);

      if (!groups[lineIndex]) {
        groups[lineIndex] = [];
      }

      groups[lineIndex].push(item);

      return groups;
    },
    {} as Record<string, DocumentContentWithId[]>
  );
}

function removeDeletedGroupLineItems(
  items: Record<string, DocumentContentWithId[]>
) {
  return Object.entries(items).reduce(
    (acc, [key, value]) => {
      if (value.length && value.some((item) => item.deleted)) {
        return acc;
      }

      return {
        ...acc,
        [key]: value,
      };
    },
    {} as Record<string, DocumentContentWithId[]>
  );
}

function generateLineItems(items: DocumentContentWithId[]) {
  const lineItems = items.filter(isLineLevelItem);

  // group line items by line index
  const groupedLineItems = getGroupedLineItems(lineItems);
  const filteredGroupLineItems = removeDeletedGroupLineItems(groupedLineItems);
  const length = Object.keys(filteredGroupLineItems).length;
  const [first] = Object.values(filteredGroupLineItems);

  const columns = length
    ? first.map((item) => {
        const base = {
          field: item.sproutaiKey,
          headerName: generateTranslatedLabel(item),
          type: 'string',
          hidden: isForcefullyHiddenLineItem(item),
        };

        if (isLineItemDropdownType(item)) {
          return {
            ...base,
            type: 'singleSelect',
          };
        }

        if (item.valueType === BOOL) {
          return {
            ...base,
            type: 'boolean',
          };
        }

        return base;
      })
    : [];

  const rows = Object.entries(filteredGroupLineItems).map(
    ([lineIndex, items]) => {
      const row = items.reduce(
        (acc, item) => {
          acc[item.sproutaiKey] = item.value;
          acc['keyToId'][item.sproutaiKey] = item.id;
          return acc;
        },
        { keyToId: {} } as Record<string, any>
      );

      return {
        ...row,
        lineIdx: parseInt(lineIndex),
      } as { [key: string]: any; lineIdx: number };
    }
  );

  return { columns, rows };
}

export { EnrichmentToolV2 };
