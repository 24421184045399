import { Suspense } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import type { Claim } from 'types/Claims';
import { usePages } from 'components/customHooks/usePages';
import { TabWithTranslation } from 'components/with-translation';
import { LoadingIcon } from 'components/icons/LoadingIcon';
import { Summary } from 'pages/Claim/Summary';
import { ReceivedInfo } from 'pages/Claim/ReceivedInfo';
import { Feedback } from 'pages/Claim/Feedback';
import { useIsSuperAdminRole } from 'state/queries/current-user';

import type { DocumentsMap, FilesMap } from './types';

enum PAGES {
  firstTab = 'summary.firstTab',
  secondTab = 'summary.secondTab',
  fourthTab = 'summary.fourthTab',
}

interface PagesProps {
  claim: Claim;
  documentsMap: DocumentsMap;
  filesMap: FilesMap;
}

function Pages({ claim }: PagesProps) {
  const isSuperAdminQuery = useIsSuperAdminRole();
  const hasSuperAdminPermission =
    (isSuperAdminQuery.isSuccess && isSuperAdminQuery.data) || false;
  const { handleChangePage, isPending, page } = usePages(PAGES.firstTab);

  return (
    <Card>
      <Box sx={{ width: '100%' }}>
        <TabContext value={page}>
          <Box
            sx={{
              backgroundColor: 'grey.50024',
              borderBottom: 1,
              borderColor: 'divider',
            }}
          >
            <TabList onChange={handleChangePage} sx={{ px: 2 }}>
              {(Object.keys(PAGES) as Array<keyof typeof PAGES>)
                .map((key) => {
                  if (key === 'secondTab' && !hasSuperAdminPermission) {
                    return null;
                  }

                  return (
                    <TabWithTranslation
                      key={key}
                      labelI18nKey={PAGES[key]}
                      value={PAGES[key]}
                    />
                  );
                })
                .filter(Boolean)}
            </TabList>
          </Box>
          <Suspense fallback={<LoadingIcon />}>
            <div style={{ opacity: isPending ? 0.8 : 1 }}>
              <TabPanel value={PAGES.firstTab} sx={{ p: 2 }}>
                <Summary claim={claim} />
              </TabPanel>
              {hasSuperAdminPermission ? (
                <TabPanel value={PAGES.secondTab}>
                  <ReceivedInfo properties={claim.properties} />
                </TabPanel>
              ) : null}
              <TabPanel value={PAGES.fourthTab}>
                <Feedback claimId={claim.id} />
              </TabPanel>
            </div>
          </Suspense>
        </TabContext>
      </Box>
    </Card>
  );
}

export { Pages };
