const publicIconDir = `/icons/`;

export function getIconPath(name: string) {
  return `${publicIconDir}${name}`;
}

export const ACCOUNT_BOX_ICON = getIconPath('account-box.svg');
export const API_ICON = getIconPath('api.svg');
export const ARROW_DOWNWARD_ICON = getIconPath('arrow-downward.svg');
export const ARROW_FORWARD_ICON = getIconPath('arrow-forward.svg');
export const ARROW_AUTOFIT_WIDTH_ICON = getIconPath('arrow-autofit-width.svg');
export const ARROW_RIGHT_LINE_ICON = getIconPath('arrow-right-line.svg');
export const AVATAR_ICON = getIconPath('avatar.svg');
export const AWS_APPCONFIG_ICON = getIconPath('aws-appconfig.svg');
export const BADGE_ICON = getIconPath('badge.svg');
export const BASELINE_RECEIPT_ICON = getIconPath('baseline-receipt.svg');
export const BRIGHTNESS_HIGH_ICON = getIconPath('brightness-high.svg');
export const BRIGHTNESS_LOW_ICON = getIconPath('brightness-low.svg');
export const CALENDAR_ICON = getIconPath('calendar.svg');
export const CHECK_FILL_ICON = getIconPath('check-fill.svg');
export const CHECK_LIST_ICON = getIconPath('checklist.svg');
export const CHECK_MARK_ICON = getIconPath('check-mark.svg');
export const CIRCLE_CHECK_ICON = getIconPath('circle-check.svg');
export const CLASSIFICATION_ICON = getIconPath('classification.svg');
export const CLOSE_ICON = getIconPath('close.svg');
export const CLOSE_CIRCLE_FILL_ICON = getIconPath('close-circle-fill.svg');
export const CODE_BROWSER = getIconPath('code-browser.svg');
export const CONFIGURATION_ICON = getIconPath('configuration.svg');
export const COPY_ICON = getIconPath('copy-line.svg');
export const CROP_ICON = getIconPath('crop.svg');
export const DATA_ENRICHMENT_ADD_ICON = getIconPath('data-enrichment-add.svg');
export const DELETE_SWEEP_ICON = getIconPath('delete-sweep.svg');
export const DIAGNOSIS_ICON = getIconPath('diagnosis.svg');
export const EDIT_ICON = getIconPath('edit.svg');
export const EMERGENCY_ICON = getIconPath('emergency.svg');
export const EYE_FILL_ICON = getIconPath('eye-fill.svg');
export const EYE_OFF_FILL_ICON = getIconPath('eye-off-fill.svg');
export const EXCLAMATION_ICON = getIconPath('exclamation-mark.svg');
export const EXPAND_ICON = getIconPath('expand-outlined.svg');
export const FEATURE_FLAGS_ICON = getIconPath('flags.svg');
export const FEEDBACK_ICON = getIconPath('feedback.svg');
export const FILE_PLUS_ICON = getIconPath('file-plus.svg');
export const FILES_ICON = getIconPath('files.svg');
export const FILE_UPLOAD_ICON = getIconPath('file-upload.svg');
export const FILTER_LIST_ICON = getIconPath('filter-list.svg');
export const FULL_SCREEN_ICON = getIconPath('full-screen.svg');
export const FULL_SCREEN_EXIT_ICON = getIconPath('full-screen-exit.svg');
export const HOSPITAL_ICON = getIconPath('hospital.svg');
export const ILLUSTRATION_404_ICON = getIconPath('illustration-404.svg');
export const ILLUSTRATION_500_ICON = getIconPath('illustration-500.svg');
export const INFO_ICON = getIconPath('info.svg');
export const LIST_ICON = getIconPath('list.svg');
export const MEDICATION_ICON = getIconPath('medication.svg');
export const MENU_ICON = getIconPath('menu.svg');
export const MINUS_ICON = getIconPath('minus.svg');
export const MOON_ICON = getIconPath('moon.svg');
export const NEXT_ICON = getIconPath('next.svg');
export const OCR_ICON = getIconPath('ocr.svg');
export const PASTE_ICON = getIconPath('paste.svg');
export const POLICY_ICON = getIconPath('policy.svg');
export const PLUS_ICON = getIconPath('plus.svg');
export const RECEIPT_ICON = getIconPath('receipt.svg');
export const RECOMMEND_ICON = getIconPath('recommend.svg');
export const REDACT_ICON = getIconPath('redact.svg');
export const REFRESH_ICON = getIconPath('refresh.svg');
export const RETRY_ICON = getIconPath('retry.svg');
export const SAVE_ICON = getIconPath('save-outline.svg');
export const SEARCH_ICON = getIconPath('search.svg');
export const SELECTION_ICON = getIconPath('selection.svg');
export const SKEW_X_ICON = getIconPath('skew-x.svg');
export const SKEW_Y_ICON = getIconPath('skew-y.svg');
export const SQUARE_MINUS_ICON = getIconPath('square-minus.svg');
export const STETHOSCOPE_ICON = getIconPath('stethoscope.svg');
export const SUN_ICON = getIconPath('sun.svg');
export const SURGICAL_ICON = getIconPath('surgical.svg');
export const THREE_DOTS_ICON = getIconPath('three-dots.svg');
export const THEME_ICON = getIconPath('theme.svg');
export const TICK_ICON = getIconPath('tick.svg');
export const TRASH_ICON = getIconPath('trash.svg');
export const TRIANGLE_ICON = getIconPath('triangle.svg');
export const TRENDING_DOWN_ICON = getIconPath('trending-down.svg');
export const TRENDING_UP_ICON = getIconPath('trending-up.svg');
export const UNLOCK_ICON = getIconPath('unlock.svg');
export const UPLOAD_ICON = getIconPath('upload.svg');
export const UPLOAD_FILE_ICON = getIconPath('upload-file.svg');
export const TWOTONE_SETTINGS_ICON = getIconPath('twotone-settings.svg');
export const USER_ICON = getIconPath('user.svg');
export const USER_MULTIPLE_ICON = getIconPath('user-multiple.svg');
export const WARNING_ICON = getIconPath('warning.svg');
export const ZIP_FILE_ICON = getIconPath('zip-file.svg');
export const ZOOM_RESET_ICON = getIconPath('zoom-reset.svg');

export const ADMIN_ICON = getIconPath('admin.svg');
export const HUMAN_IN_THE_LOOP_ICON = getIconPath('human-in-the-loop.svg');
export const PERFORMANCE_ICON = getIconPath('performance.svg');
export const FILE_EARMARK_ICON = getIconPath('file-earmark.svg');
