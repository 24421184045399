import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { TypographyWithTranslation } from 'components/with-translation';
import type { ClaimAch } from 'types/Claims';

interface AutomationRuleProps {
  rule: ClaimAch;
}

function AutomationRule({ rule }: AutomationRuleProps) {
  const fields = [
    // { key: 'summaryTable.taskId', value: rule.taskId },
    { key: 'summaryTable.taskTitle', value: rule.taskTitle },
    { key: 'summaryTable.taskType', value: rule.taskType },
    {
      key: 'summaryTable.passed',
      value: String(rule.passed),
      sx: {
        textTransform: 'capitalize',
      },
    },
    { key: 'summaryTable.message', value: rule.message },
  ];

  return (
    <Stack direction="row">
      <Box sx={{ px: 2 }}>
        <Box
          sx={{
            backgroundColor: rule.passed ? 'success.main' : 'error.main',
            height: 1,
            width: '2px',
          }}
        />
      </Box>
      <Stack>
        {fields.map(({ key, value, sx }) => (
          <Box key={key}>
            <TypographyWithTranslation
              component="span"
              variant="body1"
              fontWeight="bold"
              i18nKey={key}
            />
            <Typography component="span" variant="body1">{`: `}</Typography>
            <Typography component="span" variant="body1" sx={sx}>
              {value}
            </Typography>
          </Box>
        ))}
      </Stack>
    </Stack>
  );
}

export { AutomationRule };
