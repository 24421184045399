import type { ReactNode } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import Card from '@mui/material/Card';
import { HilTool } from 'components/HilTool/HiLTool';
import { Page } from 'components/Page';
import {
  TypographyWithTranslation,
  WithTranslationRoot,
} from 'components/with-translation';
import { RESOURCE_ID } from 'constants/route-keys';
import { HITL_TOOL_PAGE } from 'constants/translation-keys';
import rollbar from 'rollbar-config';
import { Fallback } from 'components/HilTool/Fallback';

function HitLTool() {
  const params = useParams();
  const documentId = params[RESOURCE_ID];

  const Root = ({ children }: { children: ReactNode }) => (
    <ErrorBoundary
      FallbackComponent={Fallback}
      onError={(error, info) => {
        rollbar.info('Error in HitLTool', error, info);
      }}
    >
      <WithTranslationRoot namespace={HITL_TOOL_PAGE}>
        <Page i18nKeyTitle="meta.title">
          <Outlet />
          <Card sx={{ overflow: 'initial', contain: 'paint' }}>{children}</Card>
        </Page>
      </WithTranslationRoot>
    </ErrorBoundary>
  );

  if (!documentId) {
    return (
      <Root>
        <TypographyWithTranslation
          variant="body1"
          i18nKey="common.loadDocumentError"
        />
      </Root>
    );
  }

  return (
    <Root>
      <HilTool documentId={documentId} />
    </Root>
  );
}

export default HitLTool;
