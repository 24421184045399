import type { ReactNode } from 'react';
import type { Theme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Drawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid2';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import type { Document } from 'types/Documents';
import { LazyImage } from 'components/Image';
import { LinkWithRef } from 'components/Link';
import { SvgIconStyle } from 'components/SvgIconStyle';
import { useBoolean } from 'components/customHooks/useBoolean';
import { Label } from 'components/Label';
import {
  ButtonWithTranslation,
  CardHeaderWithTranslation,
  TableCellWithTranslation,
  TypographyWithTranslation,
  useTranslationRoot,
} from 'components/with-translation';
import { CLOSE_ICON, FILE_EARMARK_ICON } from 'constants/public-icons';
import hitlToolRoute from 'pages/HitLTool/hitlTool.route';
import { SubHeaderItem } from 'pages/Claim/SubHeaderItem';
import { formatDocumentDate } from 'utils/date';
import { ARROW_FORWARD_ICON } from 'constants/public-icons';
import {
  STAGE_LABEL_COLOR_MAP,
  STAGE_TRANSLATION_MAP,
} from 'constants/document-stage';

interface AttachmentProps {
  children: ReactNode;
  claimId: string;
  documentId?: string;
  documentsMap?: { [key: string]: Document };
  hasHilStatus?: boolean;
  hasDoneStatus?: boolean;
  stage?: string;
}

function borderStyle({ palette }: Theme) {
  return `1px solid ${palette.grey[300]}`;
}

function Attachment({
  children,
  claimId,
  documentId,
  documentsMap,
  hasHilStatus,
  hasDoneStatus,
  stage,
}: AttachmentProps) {
  const { t } = useTranslationRoot();
  const [isOpen, openDrawer, closeDrawer] = useBoolean();
  const markup = (
    <Stack
      direction="row"
      sx={{
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: 1,
        color: ({ palette }) => palette.text.primary,
        opacity: 0.5,
        width: 1,
      }}
    >
      <SvgIconStyle src={FILE_EARMARK_ICON} />
      <Typography variant="body1">{children}</Typography>
    </Stack>
  );

  if (hasHilStatus && documentId) {
    const document = documentsMap ? documentsMap[documentId] : ({} as Document);
    const s = document?.stage || stage;

    return (
      <Button
        component={LinkWithRef}
        endIcon={
          s && (
            <Label
              color="warning"
              sx={{ fontSize: '12px !important' }}
              i18nKey={s ? STAGE_TRANSLATION_MAP[s] : ''}
            />
          )
        }
        to={hitlToolRoute.createPath(documentId) as string}
      >
        {markup}
      </Button>
    );
  }

  if (documentsMap && documentId) {
    const document = documentsMap[documentId];

    return (
      <>
        <Button
          onClick={openDrawer}
          endIcon={
            stage ? (
              <Label
                color={STAGE_LABEL_COLOR_MAP[stage]}
                sx={{ fontSize: '12px !important' }}
                i18nKey={STAGE_TRANSLATION_MAP[stage]}
              />
            ) : null
          }
        >
          {markup}
        </Button>
        <Drawer
          anchor="right"
          open={isOpen}
          onClose={closeDrawer}
          PaperProps={{ sx: { maxWidth: 'lg', width: '100%' } }}
        >
          <Box role="presentation">
            <Stack
              component="header"
              direction="row"
              sx={{
                justifyContent: 'space-between',
                position: 'relative',
              }}
            >
              <Stack
                direction="row"
                spacing={3}
                sx={{
                  padding: 4,
                }}
              >
                <SubHeaderItem titleI18Key="drawer.claimId">
                  <Typography
                    component="span"
                    sx={{
                      fontWeight: 'bold',
                    }}
                  >
                    {claimId}
                  </Typography>
                </SubHeaderItem>

                {document?.lastUpdatedBy ? (
                  <SubHeaderItem
                    titleI18Key="drawer.lastUpdatedBy"
                    sx={{
                      px: 3,
                    }}
                  >
                    <Typography variant="body2">
                      {document?.lastUpdatedBy}
                    </Typography>
                  </SubHeaderItem>
                ) : null}

                <SubHeaderItem
                  titleI18Key="drawer.lastUpdatedOn"
                  sx={{
                    borderLeft: borderStyle,
                    px: 3,
                  }}
                >
                  <Typography variant="body2">
                    {formatDocumentDate(document?.lastUpdatedOn)}
                  </Typography>
                </SubHeaderItem>
              </Stack>

              {hasDoneStatus ? (
                <Box
                  sx={{
                    mr: 9,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <ButtonWithTranslation
                    component={LinkWithRef}
                    variant="outlined"
                    to={hitlToolRoute.createPath(documentId) as string}
                    sx={{
                      whiteSpace: 'nowrap',
                    }}
                    i18nKey="drawer.toDocumentHistory"
                    endIcon={<SvgIconStyle src={ARROW_FORWARD_ICON} />}
                  />
                </Box>
              ) : null}

              <IconButton
                aria-label={t('drawer.closeButtonText') as string}
                onClick={closeDrawer}
                sx={{
                  position: 'absolute',
                  right: ({ spacing }) => spacing(3),
                  top: '50%',
                  transform: 'translateY(-50%)',
                }}
              >
                <SvgIconStyle src={CLOSE_ICON} />
              </IconButton>
            </Stack>
            <Grid container spacing={1}>
              <Grid size={7}>
                {document?.urls ? (
                  <>
                    {Object.values(document?.urls).map((url) => (
                      <LazyImage key={url} alt="" src={url} width={697} />
                    ))}
                  </>
                ) : (
                  <Box sx={{ px: 4 }}>
                    <TypographyWithTranslation i18nKey="drawer.noImage" />
                  </Box>
                )}
              </Grid>
              <Grid size={5}>
                <Card
                  sx={{ position: 'sticky', top: ({ spacing }) => spacing(1) }}
                >
                  <CardHeaderWithTranslation titleI18nKey="drawer.extractedValues" />
                  <Table sx={{ mt: 3 }}>
                    <TableHead>
                      <TableRow>
                        <TableCellWithTranslation i18nKey="drawer.formalKey" />
                        <TableCellWithTranslation i18nKey="drawer.text" />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {document?.content ? (
                        document.content.map(
                          ({ sproutaiKey, value }, index) => (
                            <TableRow key={`${sproutaiKey}_${index}`}>
                              <TableCell>{sproutaiKey}</TableCell>
                              <TableCell>{value}</TableCell>
                            </TableRow>
                          )
                        )
                      ) : (
                        <TableRow>
                          <TableCellWithTranslation
                            i18nKey="drawer.noContent"
                            rowSpan={2}
                          />
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </Drawer>
      </>
    );
  }

  return <Box sx={{ p: 1 }}>{markup}</Box>;
}

export { Attachment };
