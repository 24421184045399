import rollbar from 'rollbar-config';
import { fetchAuthSession } from 'aws-amplify/auth';

import { FilesMultipart } from './files-multipart.ts';

const API_URL = window.__env__.API_URL || import.meta.env.VITE_API_URL;
const RETRY_FAIL_COUNT = 3;

let featureFlagRequestFailCount = 0;

function removeTrailingSlash(path: string) {
  return path.replace(/\/$/, '');
}

function makeApiLink(path: string, version = 0) {
  return `${API_URL}/v${version}/${removeTrailingSlash(path)}`;
}

type Body = NonNullable<unknown> | [];

type RequestOptions = {
  headers?: HeadersInit;
};

type XMLCallbacks = {
  onLoad: (e: ProgressEvent, request: any) => void;
  onError?: (e: ProgressEvent) => void;
  onProgress?: (e: ProgressEvent) => void;
};

type Api = {
  authenticate: (accessToken: string) => void;
  unauthenticate: () => void;
  get: <T>(url: string) => Promise<T>;
  patch: (url: string, body?: Body) => Promise<any>;
  post: (url: string, body?: Body) => Promise<any>;
  put: (url: string, body?: Body, opts?: RequestOptions) => Promise<any>;
  delete: (url: string, body?: Body) => Promise<any>;
  xmlRequest: (
    url: string,
    method: string,
    body?: Body,
    callback?: XMLCallbacks,
    headers?: Headers
  ) => XMLHttpRequest;
  multipartUpload: (filename: string, file: File) => FilesMultipart;
};

const AUTHORIZATION_HEADER = 'Authorization';
const CONTENT_TYPE_HEADER = 'Content-Type';

const headers = new Headers({
  [CONTENT_TYPE_HEADER]: 'application/json',
});
const defaultOptions = {
  credentials: 'include',
  headers,
};

function getAuthorizationHeader() {
  return headers.get(AUTHORIZATION_HEADER);
}

function increaseFeatureFlagRequestFailCount() {
  featureFlagRequestFailCount++;
}

function resetFeatureFlagRequestFailCount() {
  featureFlagRequestFailCount = 0;
}

function isFailureLimitReached() {
  return featureFlagRequestFailCount === RETRY_FAIL_COUNT;
}

function isFeatureFlagRequest(url: string) {
  return url.includes('feature-flags');
}

function disableRollbar() {
  rollbar.configure({
    enabled: false,
  });
}

function logError(
  errorMessage: string,
  error: Error,
  custom: {
    url: string;
    method: string;
    errorType?: string;
    [key: string]: string | Error | undefined;
  }
) {
  const ignoreUrls = [
    '/history/0', // metlife doesn't have history feature yet
  ];
  const { url, method, errorType, ...rest } = custom;

  if (ignoreUrls.some((ignoreUrl) => url.includes(ignoreUrl))) {
    return;
  }

  rollbar.error(errorMessage, error, {
    url,
    method,
    errorType: custom.errorType ?? 'Fetch error',
    ...rest,
  });
}

async function fetchRequest(
  url: string,
  method: string,
  body?: Body,
  overwriteOptions?: RequestInit
) {
  const options = {
    ...defaultOptions,
    method,
    body: body && JSON.stringify(body),
  } as RequestInit;

  const { accessToken } = (await fetchAuthSession()).tokens || {};

  if (!!accessToken && accessToken.toString) {
    headers.set(AUTHORIZATION_HEADER, `Bearer ${accessToken.toString()}`);
  }

  Object.entries(overwriteOptions?.headers || {}).forEach(([key, value]) => {
    headers.set(key, value);
    if (
      key.toLowerCase() === CONTENT_TYPE_HEADER.toLowerCase() &&
      value === 'application/x-yaml'
    ) {
      options.body = body as string;
    }
  });

  return fetch(url, options)
    .then(async (response) => {
      if (response.ok) {
        if (isFeatureFlagRequest(url)) {
          resetFeatureFlagRequestFailCount();
        }

        if (response.status === 204) {
          return null;
        }

        if (response.statusText === 'No Content') {
          return await response.text();
        }

        return await response.json();
      } else {
        return Promise.reject(response);
      }
    })
    .catch(async (response) => {
      const message = response.statusText;
      const text = await response.text();
      const error = new Error(text);

      if (isFeatureFlagRequest(url)) {
        increaseFeatureFlagRequestFailCount();
      }

      isFailureLimitReached()
        ? disableRollbar()
        : logError(message, error, {
            url,
            method,
            errorType: 'Fetch catch error',
            body: text,
          });
      return Promise.reject(message);
    });
}

const api: Api = {
  authenticate(accessToken) {
    headers.set(AUTHORIZATION_HEADER, `Bearer ${accessToken}`);
  },
  unauthenticate() {
    headers.delete(AUTHORIZATION_HEADER);
  },
  get(url) {
    return fetchRequest(url, 'GET');
  },
  patch(url, body) {
    return fetchRequest(url, 'PATCH', body);
  },
  post(url, body) {
    return fetchRequest(url, 'POST', body);
  },
  put(url, body, opts = undefined) {
    return fetchRequest(url, 'PUT', body, opts);
  },
  delete(url, body) {
    return fetchRequest(url, 'DELETE', body);
  },
  xmlRequest(url, method, body, callbacks, optHeaders) {
    const request = new XMLHttpRequest();
    request.open(method, url, true);

    if (typeof callbacks !== 'undefined') {
      if (callbacks.onLoad) {
        request.addEventListener('load', (e) => {
          if (callbacks && callbacks.onLoad) {
            callbacks.onLoad(e, request);
          }
        });
      }

      if (callbacks.onError) {
        request.addEventListener('error', callbacks.onError);
      }

      if (callbacks.onProgress) {
        request.upload.addEventListener('progress', callbacks.onProgress);
      }
    }

    // set new auth header
    request.setRequestHeader(
      AUTHORIZATION_HEADER,
      headers.get(AUTHORIZATION_HEADER) || ''
    );

    for (const key in optHeaders) {
      request.setRequestHeader(key, optHeaders[key]);
    }

    // @ts-ignore
    request.send(body);

    return request;
  },
  multipartUpload(fileName, file) {
    return new FilesMultipart({ fileName, file });
  },
};

export default api;
export { API_URL, getAuthorizationHeader, headers, makeApiLink };
export type { Api, XMLCallbacks };
