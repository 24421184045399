import type { ReactNode } from 'react';
import type { Theme } from '@mui/material/styles';
import { ErrorBoundary } from 'react-error-boundary';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import type { ClaimRecommendation } from 'types/Claims';
import { LoadingIcon } from 'components/icons/LoadingIcon';
import { LinkWithRef } from 'components/Link';
import { Page } from 'components/Page';
import { GenericErrorFallback } from 'components/GenericErrorFallback';
import {
  LinkWithTranslation,
  LoadingButtonWithTranslation,
  TypographyWithTranslation,
  useTranslationRoot,
  WithTranslationRoot,
} from 'components/with-translation';
import { useFeatureFlag } from 'components/customHooks/useFeatureFlag.ts';
import { RESOURCE_ID } from 'constants/route-keys';
import { CLAIM_PAGE } from 'constants/translation-keys';
import claimsRoute from 'pages/Claims/claims.route';
import { useGetClaim } from 'state/queries/claims';
import {
  getIsClaimAwaitingReview,
  getIsClaimAwaitingInfo,
  getIsClaimProcessing,
} from 'state/selectors/claims';
import { useGetCurrentUser } from 'state/queries/current-user';
import { convertArrayToObject } from 'utils/array';
import { formatDocumentDate } from 'utils/date';
import { generalConfig } from 'config';
import { EnrichmentToolV3 } from 'components/EnrichmentTool';

import { AuditLog } from './AuditLog';
import { Pages } from './Pages';
import { SubHeaderItem } from './SubHeaderItem';
import { TrafficLight } from './TrafficLight';
import { ReviewTool } from './ReviewTool';

function getStatusTranslation(
  clientRecommendation: ClaimRecommendation | ClaimRecommendation[]
) {
  const status = Array.isArray(clientRecommendation)
    ? Number(clientRecommendation[0].status)
    : Number(clientRecommendation.status);

  if (status >= 0 && status < 1) {
    return 'activityStatus.accept';
  } else if (status >= 1 && status < 4) {
    return 'activityStatus.requiresReview';
  }

  return 'activityStatus.error';
}

function borderStyle({ palette }: Theme) {
  return `1px solid ${palette.grey[300]}`;
}

function Wrapper({ children }: { children: ReactNode }) {
  return (
    <ErrorBoundary FallbackComponent={GenericErrorFallback}>
      <WithTranslationRoot namespace={CLAIM_PAGE}>
        <Page i18nKeyTitle="meta.title">{children}</Page>
      </WithTranslationRoot>
    </ErrorBoundary>
  );
}

function ClaimView() {
  const { t } = useTranslationRoot(CLAIM_PAGE);
  const enableEnrichmentTool = useFeatureFlag('enableAdvReview');

  const params = useParams();
  const claimId = params[RESOURCE_ID];

  const {
    data: claim,
    isError,
    isPending,
    isRefetching,
    refetch,
  } = useGetClaim(claimId);
  const currentUser = useGetCurrentUser();

  if (isPending || currentUser.isPending) {
    return (
      <Wrapper>
        <LoadingIcon />
      </Wrapper>
    );
  }

  if (isError || currentUser.isError) {
    return (
      <Wrapper>
        <Stack spacing={2} sx={{ p: 3 }}>
          <TypographyWithTranslation
            variant="body1"
            i18nKey="common.loadClaimError"
          />
          <Box>
            <LinkWithTranslation
              i18nKey="common.goBackButton"
              component={LinkWithRef}
              to={claimsRoute.createPath()}
            />
          </Box>
        </Stack>
      </Wrapper>
    );
  }

  const { clientRecommendation, documents, files } = claim;
  const documentsMap = convertArrayToObject(documents, 'documentId');
  const filesMap = convertArrayToObject(files, 'fileId');
  const hasRefetchButton =
    getIsClaimProcessing(claim) || getIsClaimAwaitingInfo(claim);

  let markup;

  if (getIsClaimAwaitingReview(claim)) {
    if (enableEnrichmentTool && !claim.features) {
      markup = (
        <Box sx={{ p: 3 }}>
          <EnrichmentToolV3 claim={claim} currentUser={currentUser.data} />
        </Box>
      );
    } else {
      markup = (
        <Box sx={{ p: 3 }}>
          <ReviewTool claim={claim} currentUser={currentUser.data} />
        </Box>
      );
    }
  } else {
    const recommendations = Array.isArray(clientRecommendation)
      ? clientRecommendation
      : clientRecommendation
        ? ([clientRecommendation] as ClaimRecommendation[])
        : [];

    markup = (
      <>
        <Stack
          direction="row"
          sx={{
            borderBottom: borderStyle,
            borderTop: borderStyle,
            py: 3,
          }}
        >
          <SubHeaderItem
            titleI18Key="header.claimId"
            sx={{
              px: 3,
            }}
          >
            {claim.id}
          </SubHeaderItem>
          <SubHeaderItem
            titleI18Key="header.lastUpdateTime"
            sx={{
              borderLeft: borderStyle,
              px: 3,
            }}
          >
            {formatDocumentDate(claim.lastUpdatedOn)}
          </SubHeaderItem>
          {recommendations?.length ? (
            <SubHeaderItem
              startComponent={
                <TrafficLight clientRecommendation={recommendations} />
              }
              titleI18Key="header.status"
              sx={{
                borderLeft: borderStyle,
                px: 3,
              }}
            >
              {t(getStatusTranslation(recommendations))}
            </SubHeaderItem>
          ) : null}
          {hasRefetchButton ? (
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                borderLeft: borderStyle,
                px: 3,
              }}
            >
              <LoadingButtonWithTranslation
                disabled={isRefetching}
                loading={isRefetching}
                variant="outlined"
                i18nKey="common.refetchButton"
                onClick={() => refetch()}
              />
            </Box>
          ) : null}
        </Stack>

        <Stack
          sx={{
            gap: 4,
            px: 4,
            py: 3,
          }}
        >
          <Pages
            claim={claim}
            documentsMap={documentsMap}
            filesMap={filesMap}
          />
          {generalConfig.disableClaimAuditLog ? null : (
            <AuditLog claim={claim} filesMap={filesMap} />
          )}
        </Stack>
      </>
    );
  }

  return <Wrapper>{markup}</Wrapper>;
}

export default ClaimView;
