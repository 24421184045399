import type { ReactNode } from 'react';
import { createContext, useState } from 'react';
import type {
  Claim,
  ClaimFeature,
  ClaimFeatures,
  Fields,
  LinesFeature,
} from 'types/Claims';
import type { CurrentUser } from 'types/CurrentUser';
import type { Config } from 'types/Config';
import {
  getClaimFeatures,
  getClaimFields,
  getClaimLines,
  getClaimType,
} from 'state/selectors/claims';
import {
  LINES_KEYS,
  MAIN_LIST,
  makeItemFieldId,
  makeItemIdList,
  makeLinesListKey,
} from 'constants/claims';
import { pipe } from 'utils/pipe';

type Lists = Record<string, string[]>;
type Resources = Record<string, ClaimFeature>;

type Context = {
  config: Config;
  claim: Claim;
  claimType?: string;
  currentUser: CurrentUser;
  images: ClaimImage[];
  isFullscreen: boolean;
  isNotLockedByCurrentUser: boolean;
  navigateToNextContext: () => Promise<void>;
  toggleFullscreen: () => void;
  state: {
    lists: Lists;
    resources: Resources;
  };
};

type NormalisedState = {
  features: ClaimFeatures;
  lists: Lists;
  resources: Resources;
};

export type ClaimImage = {
  url: string;
  documentId: string;
  documentGroup: string;
};

interface ReviewToolContextProviderProps {
  config?: Config;
  children: ReactNode;
  claim: Claim;
  currentUser: CurrentUser;
  isFullscreen: boolean;
  toggleFullscreen: () => void;
  images: ClaimImage[];
  navigateToNextContext: () => Promise<void>;
}

const ReviewToolContext = createContext<Context>({} as Context);

function normaliseClaimFields({ features }: NormalisedState) {
  const fields = getClaimFields(features) as Fields;

  return Object.entries(fields).reduce(
    (acc, [key, value]) => {
      return {
        ...acc,
        lists: {
          ...acc.lists,
          [MAIN_LIST]: [...acc.lists[MAIN_LIST], key],
        },
        resources: {
          ...acc.resources,
          [key]: value,
        },
      };
    },
    {
      features,
      lists: { [MAIN_LIST]: [] },
      resources: {},
    } as NormalisedState
  );
}

function normaliseLinesList({ features, lists, resources }: NormalisedState) {
  const lines = getClaimLines(features) as LinesFeature;

  return Object.entries(lines).reduce(
    (acc, [key, value]) => {
      const linesListKey = makeLinesListKey(key);

      if (value.length) {
        return value.reduce(
          (line, _arrays, index) => ({
            ...acc,
            lists: {
              ...acc.lists,
              [linesListKey]: [
                ...(line.lists[linesListKey] || []),
                makeItemIdList(key, index),
              ],
            },
          }),
          { features, lists, resources } as NormalisedState
        );
      }

      return {
        features,
        lists: {
          ...acc.lists,
          [linesListKey]: [],
        },
        resources,
      };
    },
    { features, lists, resources } as NormalisedState
  );
}

function normaliseClaimLines({ features, lists, resources }: NormalisedState) {
  const lines = getClaimLines(features) as LinesFeature;

  if (lines && Object.keys(lines).length > 0) {
    return Object.entries(lines).reduce(
      (root, [key, values]) => {
        const lineFields = values.reduce(
          (acc, fields, index) => {
            return {
              lists: {
                ...acc.lists,
                [makeItemIdList(key, index)]: Object.keys(fields).map((id) =>
                  makeItemFieldId(key, index, id)
                ),
              },
              resources: {
                ...acc.resources,
                ...Object.entries(fields).reduce(
                  (acc, [id, value]) => ({
                    ...acc,
                    [makeItemFieldId(key, index, id)]: value,
                  }),
                  {}
                ),
              },
            };
          },
          { lists: {}, resources: {} }
        );

        return {
          ...root,
          lists: {
            ...root.lists,
            ...lineFields.lists,
            [LINES_KEYS]: [...(root.lists[LINES_KEYS] || []), key],
          },
          resources: {
            ...root.resources,
            ...lineFields.resources,
          },
        };
      },
      { features, lists, resources } as NormalisedState
    );
  } else {
    return {
      features,
      lists: {
        ...lists,
        [LINES_KEYS]: [],
      },
      resources,
    };
  }
}

function ReviewToolContextProvider({
  children,
  config,
  ...props
}: ReviewToolContextProviderProps) {
  const { claim, currentUser } = props;
  const [state] = useState(() => {
    const features = getClaimFeatures(claim);

    if (features) {
      const results = pipe(
        normaliseClaimFields,
        normaliseLinesList,
        normaliseClaimLines
      )({
        features,
        lists: {},
        resources: {},
      });

      return {
        lists: results.lists,
        resources: results.resources,
      };
    }
    return {
      lists: {},
      resources: {},
    };
  });

  const isNotLockedByCurrentUser =
    claim.locked && claim.lastLockedBy !== currentUser.email;
  const claimType = getClaimType(claim);

  return (
    <ReviewToolContext.Provider
      value={{
        ...props,
        config: config || ({} as Config),
        state,
        isNotLockedByCurrentUser,
        claimType,
      }}
    >
      {children}
    </ReviewToolContext.Provider>
  );
}

export type { ReviewToolContextProviderProps };
export { ReviewToolContext, ReviewToolContextProvider };
