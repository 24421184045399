import Grid from '@mui/material/Grid2';
import CardContent from '@mui/material/CardContent';
import type { ClaimFeature, ClaimFeatures } from 'types/Claims';
import type { ClaimSummaryWidget } from 'types/Widgets';
import { CollapsibleCard } from 'components/CollapsibleCard';

import { Markdown } from './Markdown.tsx';
import { TimelineWidget } from './TimelineWidget.tsx';

interface WidgetsProps {
  features: ClaimFeatures | null;
  widgets: ClaimSummaryWidget[];
}

function Widgets({ features, widgets }: WidgetsProps) {
  return (
    <Grid container spacing={2}>
      {widgets
        .map((widget, index) => {
          if (features) {
            // TODO: this only works for items and not line items
            const item = features.fields[widget.feature] as ClaimFeature;
            if (item && item.value) {
              return (
                <Grid key={index} size={{ xs: widget.colWidth || 6 }}>
                  <CollapsibleCard
                    titleI18nKey={widget.title}
                    initialExpanded={!widget.collapsed}
                  >
                    <CardContent
                      sx={{
                        pt: 0,
                        pre: {
                          whiteSpace: 'pre-wrap',
                        },
                      }}
                    >
                      {widget.widgetType === 'timeline' ? (
                        <TimelineWidget value={item.value} />
                      ) : (
                        <Markdown value={item.value} />
                      )}
                    </CardContent>
                  </CollapsibleCard>
                </Grid>
              );
            }
          }

          return null;
        })
        .filter(Boolean)}
    </Grid>
  );
}

export { Widgets };
