import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import type { Claim, ClaimFeatures } from 'types/Claims';
import type { CurrentUser } from 'types/CurrentUser';
import { CLAIM_TABLE } from 'constants/table-pagination';
import { PAGE_ID, STAGE } from 'constants/route-keys';
import { AWAITING_REVIEW } from 'constants/claims';
import claimRoute from 'pages/Claim/claim.route';
import claimsRoute from 'pages/Claims/claims.route';
import { useBoolean } from 'components/customHooks/useBoolean';
import { useFeatureFlag } from 'components/customHooks/useFeatureFlag';
import { useLocalStorage } from 'components/customHooks/useLocalStorage';
import { useNextClaimParams } from 'components/customHooks/useNextClaimParams';
import { LoadingIcon } from 'components/icons/LoadingIcon';
import { generalConfig } from 'config';
import { TypographyWithTranslation } from 'components/with-translation';
import { useGetConfig } from 'state/queries/config';
import {
  useGetNewClaimPageUrls,
  useLockClaim,
  usePatchClaimReview,
  useUnlockClaim,
} from 'state/queries/claims';
import { getIsClaimAwaitingReview } from 'state/selectors/claims';
// import { preWarmImageCache } from 'utils/image-cache.ts';
import {
  updateDataLayer,
  updateWindowPerformanceObject,
  getEventTimestamp,
} from 'analytics/utils';
import {
  REVIEW_SCREEN_END,
  REVIEW_SCREEN_NEXT_CLAIM_START,
  REVIEW_SCREEN_NEXT_CLAIM_END,
  REVIEW_SCREEN_START,
  REVIEW_SCREEN_SUBMIT_END,
  REVIEW_SCREEN_SUBMIT_START,
} from 'analytics/events';
import { toFixed } from 'utils/numbers';

import { WarningDialog } from './WarningDialog';
import { ReviewToolContextProvider } from './review-tool-context';
import { ReviewForm } from './ReviewForm';
import { FULLSCREEN_ZINDEX } from './review-tool-utils';
import { ReviewReadOnlyForm } from './ReviewReadOnlyForm';

interface ReviewToolProps {
  claim: Claim;
  currentUser: CurrentUser;
}

const CardStyled = styled(Card, {
  shouldForwardProp: (prop) => prop !== 'isFullscreen',
})<{ isFullscreen: boolean }>(({ isFullscreen }) => ({
  overflow: 'initial',
  ...(isFullscreen && {
    backgroundColor: 'background.default',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: FULLSCREEN_ZINDEX,
  }),
}));

function ReviewTool({ claim, currentUser }: ReviewToolProps) {
  const navigate = useNavigate();
  const { id: claimId } = claim;
  const lockClaimAction = useLockClaim(claimId);
  const unlockClaimAction = useUnlockClaim(claimId);
  const [localStore, setLocalStore] = useLocalStorage(
    generalConfig.fullScreenReviewTool,
    false
  );
  const [isFullscreen, , , toggleIsFullscreen] = useBoolean(localStore);
  const enableGetNextClaim = useFeatureFlag('enableGetNextClaim');

  const getNextClaim = useNextClaimParams();

  const toggleFullscreen = () => {
    toggleIsFullscreen();
    setLocalStore(!isFullscreen);
  };

  const configQuery = useGetConfig('claim_review');
  const claimPageUrlsQuery = useGetNewClaimPageUrls(claimId);
  const patchClaimReviewQuery = usePatchClaimReview(claimId);

  useEffect(() => {
    updateWindowPerformanceObject(REVIEW_SCREEN_START);

    return () => {
      updateDataLayer({
        event: REVIEW_SCREEN_END,
        performance:
          (Date.now() - getEventTimestamp(REVIEW_SCREEN_START)) / 1000,
        claimId: claim.id,
      });
    };
  }, []); // eslint-disable-line

  useEffect(function lockClaimOnMount() {
    const { locked } = claim;

    if (!locked) {
      lockClaimAction.mutate(claimId);
    }
  }, []); // eslint-disable-line

  useEffect(
    function unlockClaim() {
      const { locked } = claim;
      const isLockedByCurrentUser = claim.lastLockedBy === currentUser.email;

      return () => {
        const searchParams = new URLSearchParams(window.location.search);

        if (!searchParams.get(PAGE_ID)) {
          if (
            locked &&
            isLockedByCurrentUser &&
            getIsClaimAwaitingReview(claim)
          ) {
            unlockClaimAction.mutate({ id: claimId });
          }
        }
      };
    },
    // eslint-disable-next-line
    [claim]
  );

  useEffect(function beforeUnloadListener() {
    function unlockClaim() {
      unlockClaimAction.mutate({ id: claimId });
    }

    window.addEventListener('beforeunload', unlockClaim);

    return () => {
      window.removeEventListener('beforeunload', unlockClaim);
    };
  }, []); // eslint-disable-line

  function navigateToClaimsTable() {
    const existingSearch = window.localStorage.getItem(CLAIM_TABLE);

    navigate(
      claimsRoute.createPath({
        search: existingSearch
          ? `?${existingSearch}`
          : `?${STAGE}=${AWAITING_REVIEW}`,
      })
    );
  }

  async function navigateToNextContext() {
    updateDataLayer({
      event: REVIEW_SCREEN_SUBMIT_END,
      performance:
        (Date.now() - getEventTimestamp(REVIEW_SCREEN_SUBMIT_START)) / 1000,
    });

    if (!enableGetNextClaim) {
      navigateToClaimsTable();
      return Promise.resolve();
    }

    updateWindowPerformanceObject(REVIEW_SCREEN_NEXT_CLAIM_START);
    const nextClaim = await getNextClaim(true);
    updateDataLayer({
      event: REVIEW_SCREEN_NEXT_CLAIM_END,
      performance: toFixed(
        (Date.now() - getEventTimestamp(REVIEW_SCREEN_NEXT_CLAIM_START)) / 1000
      ),
      claimId: claim.id,
    });

    if (nextClaim) {
      const isAwaitingReview = getIsClaimAwaitingReview(nextClaim);
      const to = isAwaitingReview
        ? `${claimRoute.createPath(nextClaim.id)}?${PAGE_ID}=review`
        : claimRoute.createPath(nextClaim.id);
      navigate(to);
    } else {
      navigateToClaimsTable();
    }

    return Promise.resolve();
  }

  function submitClaimReview(features: ClaimFeatures) {
    return patchClaimReviewQuery.mutate(
      {
        id: claim.id,
        features,
      },
      {
        onSuccess: navigateToNextContext,
      }
    );
  }

  if (claimPageUrlsQuery.isPending || configQuery.isPending) {
    return <LoadingIcon />;
  }

  if (claimPageUrlsQuery.isError) {
    return (
      <Box
        sx={{
          p: 2,
        }}
      >
        <TypographyWithTranslation i18nKey="reviewTool.noUrls" />
      </Box>
    );
  }

  if (configQuery.isError) {
    console.warn('Failed to load config');
  }

  // preWarmImageCache(claimPageUrlsQuery.data);

  return (
    <ReviewToolContextProvider
      config={configQuery.data}
      claim={claim}
      currentUser={currentUser}
      isFullscreen={isFullscreen}
      toggleFullscreen={toggleFullscreen}
      images={claimPageUrlsQuery.data}
      navigateToNextContext={navigateToNextContext}
    >
      <CardStyled
        sx={{ overflowY: 'hidden', height: { md: 1 } }}
        isFullscreen={isFullscreen}
        data-testid="claim-review-screen"
      >
        {getIsClaimAwaitingReview(claim) ? (
          <ReviewForm onSubmit={submitClaimReview} />
        ) : (
          <ReviewReadOnlyForm />
        )}
      </CardStyled>
      <WarningDialog
        email={currentUser.email}
        lastLockedBy={claim.lastLockedBy}
        locked={claim.locked}
      />
    </ReviewToolContextProvider>
  );
}

export { ReviewTool };
