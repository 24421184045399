import debounce from '@mui/utils/debounce';
import {
  keepPreviousData,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import type { PaginationApiResponse } from 'api/transformers/types/pagination.ts';
import type {
  ClaimApiResponse,
  ClaimDocumentsResponse,
  ClaimsApiResponse,
  ClaimObservationsApiResponse,
  ClaimPagesUrlsApiResponse,
  ClaimValidationApiResponse,
} from 'api/transformers/types/claims.ts';
import type { Order } from 'constants/sort';
import type {
  Claim,
  ClaimFeatures,
  ClaimObservation,
  ClaimsPage,
  ClaimPagesUrls,
} from 'types/Claims';
import type { CurrentUser } from 'types/CurrentUser';
import type { Document } from 'types/Documents';
import type { Meta } from 'query-client.ts';
import api, { makeApiLink } from 'api/api';
import {
  transformClaimDocumentsResponse,
  transformClaimObservationsResponse,
  transformClaimsValidateResponse,
  transformClaimResponse,
} from 'api/transformers/claims';
import {
  CLAIM,
  CLAIM_ARCHIVE,
  CLAIM_DOCUMENTS,
  CLAIM_FEEDBACK,
  CLAIM_LOCK,
  CLAIM_OBSERVATIONS,
  CLAIM_UNLOCK,
  CLAIM_PAGEURLS,
  CLAIMS,
  CURRENT_USER,
  makeClaimsList,
  CLAIM_REJECT,
  CLAIM_REVIEW_VALIDATION,
  CLAIM_VALIDATION,
  CLAIM_REVIEW,
  CLAIM_NEXT,
} from 'constants/api-endpoints';
import { ASC } from 'constants/sort';
import { generalConfig } from 'config';
import { useGet, useOptimisticMutation } from 'utils/react-query';
import { toSnakeCase } from 'utils/string';
import { pathToUrl } from 'utils/url';
import { transformKeys } from 'utils/object';
import { useTranslationRoot } from 'components/with-translation.tsx';
import {
  selectClaimPagesUrlsResponse,
  selectClaimResponse,
  selectClaimsResponse,
} from 'state/selectors/claims.ts';
import {
  updateDataLayer,
  updateWindowPerformanceObject,
  getEventTimestamp,
} from 'analytics/utils';
import {
  REVIEW_SCREEN_PATCH_END,
  REVIEW_SCREEN_PATCH_START,
} from 'analytics/events';

export function useGetClaims({
  filters,
  order = ASC,
  searchValue = '',
  searchField,
  start = generalConfig.defaultPaginationStart,
  stage = null,
  size = generalConfig.defaultPaginationSize,
}: {
  filters?: string | null;
  order?: Order;
  searchValue?: string | null;
  searchField?: string | null;
  start: number;
  stage?: string | null;
  size: number;
}) {
  const { list, params } = makeClaimsList({
    filters,
    order,
    searchValue,
    searchField,
    stage,
  });

  return useGet<PaginationApiResponse<ClaimsApiResponse>, ClaimsPage>({
    url: list,
    params: { start: start * size, size, ...params },
    prefix: CLAIMS,

    select: selectClaimsResponse,
    placeholderData: keepPreviousData,
    refetchOnMount: 'always',
    refetchOnWindowFocus: true,
  });
}

export function useGetClaim(id: string | undefined) {
  return useGet<ClaimApiResponse, Claim>({
    url: id ? pathToUrl(CLAIM, { id }) : '',
    prefix: CLAIMS,

    select: selectClaimResponse,
    gcTime: 10 * (60 * 1000), // 10 minute
    staleTime: 60 * 1000,
    refetchOnMount: 'always',
  });
}

export function useGetClaimPageUrls(id: string | undefined) {
  return useGet<{ urls: string[] }, string[]>({
    url: id ? pathToUrl(CLAIM_PAGEURLS, { id }) : '',
    prefix: CLAIMS,

    select: (data) => (data.urls ? data.urls : []),
    gcTime: 0,
    refetchOnMount: 'always',
  });
}

export function useGetNewClaimPageUrls(id: string | undefined) {
  return useGet<ClaimPagesUrlsApiResponse[], ClaimPagesUrls>({
    url: id ? pathToUrl(CLAIM_PAGEURLS, { id }) : '',
    prefix: CLAIMS,
    select: selectClaimPagesUrlsResponse,
    apiVersion: 1,
  });
}

export function useGetClaimDocuments(id: string | null) {
  return useGet<ClaimDocumentsResponse, Document[]>({
    url: id ? pathToUrl(CLAIM_DOCUMENTS, { id }) : '',
    prefix: CLAIMS,

    select: transformClaimDocumentsResponse,
  });
}

export function postClaimFeedback(
  claimId: string,
  reason: string,
  description: string
) {
  return api.post(
    makeApiLink(
      `${pathToUrl(CLAIM_FEEDBACK, { id: claimId })}?claim_id=${claimId}`
    ),
    {
      reason,
      description,
    }
  );
}

export function useGetClaimObservations(id: string | null) {
  return useGet<ClaimObservationsApiResponse, ClaimObservation[]>({
    url: id ? pathToUrl(CLAIM_OBSERVATIONS, { id }) : '',
    prefix: CLAIMS,

    select: transformClaimObservationsResponse,
    gcTime: 0,
    refetchOnMount: 'always',
  });
}

function patchClaimObservations({
  id,
  observations,
}: {
  id: string;
  observations: ClaimObservation[];
}) {
  const body = {
    observations: observations.map(({ id, ...observation }) => ({
      // convert id back to observation_id
      observation_id: id,
      ...transformKeys(observation, toSnakeCase),
    })),
  };
  return api.patch(makeApiLink(pathToUrl(CLAIM_OBSERVATIONS, { id })), body);
}

export function usePatchClaimObservations(meta: Meta) {
  return useMutation({
    mutationFn: patchClaimObservations,
    meta: meta as unknown as {
      errorMessage: string;
      loadingMessage: string;
      successMessage: string;
    },
  });
}

function patchClaimReview({
  id,
  features,
}: {
  id: string;
  features: ClaimFeatures;
}) {
  updateWindowPerformanceObject(REVIEW_SCREEN_PATCH_START);
  const body = { features };

  return api.patch(makeApiLink(pathToUrl(CLAIM_REVIEW, { id })), body);
}

export function usePatchClaimReview(id: string) {
  const queryClient = useQueryClient();
  const { t } = useTranslationRoot();

  return useOptimisticMutation({
    fn: patchClaimReview,
    url: pathToUrl(CLAIM_REVIEW, { id }),
    updater: (_oldData, newData) => {
      return newData.features;
    },
    onSettled: () => {
      updateDataLayer({
        event: REVIEW_SCREEN_PATCH_END,
        performance:
          (Date.now() - getEventTimestamp(REVIEW_SCREEN_PATCH_START)) / 1000,
        claimId: id,
      });
      queryClient.invalidateQueries({ queryKey: [CLAIMS] });
    },
    prefix: CLAIMS,
    meta: {
      errorMessage: t('reviewTool.submitChangesFailed'),
      loadingMessage: t('reviewTool.submitChangesLoading'),
      successMessage: t('reviewTool.submitChangesSuccess'),
    },
  });
}

/**
 * Lock Claim mechanism
 */
const lockingQueue = new Map();

export function lockClaim(id: string) {
  return api.put(makeApiLink(pathToUrl(CLAIM_LOCK, { id })));
}

function unlockClaim({ id, force = false }: { id: string; force?: boolean }) {
  clearQueue();
  return api.put(
    makeApiLink(pathToUrl(CLAIM_UNLOCK, { id, force: String(force) }))
  );
}

function clearQueue() {
  lockingQueue.clear();
}

function flushQueue(id: string) {
  if (lockingQueue.size) {
    lockClaim(id);
    clearQueue();
  }
}

const rateLimitedFlushQueue = debounce(
  flushQueue,
  generalConfig.lockClaimInterval
);

function addToQueue(id: string) {
  lockingQueue.set(Date.now(), true);
  rateLimitedFlushQueue(id);
}

function lockClaimWithQueue(id: string) {
  addToQueue(id);
  return new Promise((resolve) => resolve({ id }));
}

export function useLockClaimWithQueue() {
  return {
    lockClaimQueueAction: useMutation({ mutationFn: lockClaimWithQueue }),
    cancelLock: clearQueue,
  };
}

export function useLockClaim(id: string) {
  const queryClient = useQueryClient();

  return useOptimisticMutation<Claim, string>({
    fn: lockClaim,
    url: pathToUrl(CLAIM, { id }),
    updater: (oldData) => {
      const currentUser = queryClient.getQueryData<CurrentUser>([CURRENT_USER]);

      if (oldData && currentUser) {
        return {
          ...oldData,
          lastLockedBy: currentUser.email,
          lastLockedOn: new Date().toISOString(),
          locked: true,
        };
      }

      return oldData;
    },
    prefix: CLAIMS,
  });
}

export function useUnlockClaim(id: string) {
  const queryClient = useQueryClient();

  return useOptimisticMutation<
    Claim,
    {
      id: string;
      force?: boolean;
    }
  >({
    fn: unlockClaim,
    url: pathToUrl(CLAIM, { id }),
    updater: (oldData) => {
      if (oldData) {
        return {
          ...oldData,
          lastLockedBy: null,
          lastLockedOn: null,
          locked: false,
        };
      }

      return oldData;
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [CLAIMS] });
    },
    prefix: CLAIMS,
  });
}

function archiveClaim({ id }: { id: string }) {
  return api.put(makeApiLink(pathToUrl(CLAIM_ARCHIVE, { id })));
}

export function useArchiveClaim({ id, meta }: { id: string; meta: Meta }) {
  const queryClient = useQueryClient();

  return useOptimisticMutation<Claim, { id: string }>({
    fn: archiveClaim,
    url: pathToUrl(CLAIM, { id }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [CLAIMS] });
    },
    prefix: CLAIMS,
    meta,
  });
}

export function useInvalidateClaimsQueries() {
  const queryClient = useQueryClient();

  return () => {
    queryClient.invalidateQueries({ queryKey: [CLAIMS] });
  };
}

function rejectClaim({ id, reason }: { id: string; reason: string }) {
  const body = {
    reason,
  };
  return api.put(makeApiLink(pathToUrl(CLAIM_REJECT, { id })), body);
}

export function useRejectClaim({ meta }: { meta: Meta }) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: rejectClaim,
    onSuccess: async () => {
      await queryClient.cancelQueries({ queryKey: [CLAIMS] });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [CLAIMS] });
    },
    meta: meta as unknown as {
      errorMessage: string;
      loadingMessage: string;
      successMessage: string;
    },
  });
}

export function validateClaim(observations: ClaimObservation[]) {
  const body = {
    observations: observations.map(({ id, ...observation }) => ({
      // convert id back to observation_id
      observation_id: id,
      ...transformKeys(observation, toSnakeCase),
    })),
  };

  return api.post(makeApiLink(CLAIM_VALIDATION), body);
}

export function useValidateClaim(id: string) {
  const queryClient = useQueryClient();
  const queryKey = [CLAIMS, pathToUrl(CLAIM, { id }), null];

  return useMutation<
    ClaimValidationApiResponse,
    Error,
    ClaimObservation[],
    Claim
  >({
    mutationFn: validateClaim,
    onSuccess: async ({ results }) => {
      queryClient.setQueryData<Claim>(queryKey, (oldData) => {
        if (oldData) {
          return {
            ...oldData,
            validation_results: results,
          };
        }

        return undefined;
      });
    },
  });
}

export function postClaimValidate(id: string, features: ClaimFeatures) {
  const body = { features };

  return api.post(
    makeApiLink(`${pathToUrl(CLAIM_REVIEW_VALIDATION, { id })}`),
    body
  );
}

export async function validateClaimReview({
  id,
  features,
}: {
  id: string;
  features: ClaimFeatures;
}) {
  const response = await postClaimValidate(id, features);

  return transformClaimsValidateResponse(response);
}

export function createClaim(claim: {
  client_claim_id: string;
  properties: string;
  metadata: string;
  expected_files: number;
}) {
  const body = {
    ...claim,
    properties: claim.properties
      ? JSON.parse(claim.properties)
      : JSON.parse('{}'),
    metadata: claim.metadata ? JSON.parse(claim.metadata) : JSON.parse('{}'),
  };

  return api.post(makeApiLink(CLAIMS), body);
}

type GetNextClaimParams = {
  order?: string | null;
  searchField?: string | null;
  searchValue?: string | null;
  lock?: string;
  sort?: string | null;
  filters?: string | null;
};

function getNextClaim(params: GetNextClaimParams): Promise<ClaimApiResponse> {
  const url = Object.keys(params).reduce((acc, key) => {
    if (params[key]) {
      if (acc === CLAIM_NEXT) {
        return `${acc}\\?${toSnakeCase(key)}=:${key}`;
      }
      return `${acc}&${toSnakeCase(key)}=:${key}`;
    }

    return acc;
  }, CLAIM_NEXT);

  return api.get(makeApiLink(pathToUrl(url, params)));
}

export function useGetNextClaim(options: GetNextClaimParams) {
  const queryClient = useQueryClient();

  return async (lock = false) => {
    try {
      const data = await getNextClaim({ ...options, lock: String(lock) });
      const queryKey = [CLAIMS, pathToUrl(CLAIM, { id: data.claim_id }), null];
      queryClient.setQueryData(queryKey, data);

      return Promise.resolve(transformClaimResponse(data));
    } catch (error) {
      console.error(error);
    }
  };
}
