import { Controller, useFormContext } from 'react-hook-form';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

const referrer_provider = [
  '996 - Contactologista',
  '997 - Ortoptista',
  '998 - Optometria ou optometrista',
  '999 - Nenhuma das anteriores',
];
const self_funding_tax_id = [
  "501864164 - ACP Serviços de Assistência, Lda",
  "600008878 - ADMG - Ass. Doença Militares da Guarda",
  "503122165 - Administração Regional do Centro",
  "503148776 - Admn Reg Saúde Lx Vale Tejo- Sub Reg Lx",
  "600000303 - ADSE",
  "504763202 - Águas e Parque Biológico de Gaia",
  "980051070 - AIG Europe Limited",
  "500069514 - Allianz",
  "506125394 - Assoc. Mutualista da Auto-Sueco",
  "511010400 - Associação Socorros Mútuos",
  "502998202 - ATAL-Associação Trab Autárquicos Loulé",
  "511202008 - Banif - Banco Internacional do Funchal,",
  "980398541 - C.C.M.O. Mutuelle",
  "500746478 - Caixa de Previdência do Pessoal da C.M.L",
  "501634851 - Caixa Previdência Gondomar Serviços Mun.",
  "505456010 - Câmara Municipal da Amadora",
  "501131140 - Câmara Municipal da Guarda",
  "505387131 - Câmara Municipal da Maia",
  "500051054 - Câmara Municipal de Almada",
  "506901173 - Câmara Municipal de Braga",
  "505187531 - Câmara Municipal de Cascais",
  "502177080 - Câmara Municipal de Mafra",
  "500745943 - Câmara Municipal de Oeiras",
  "512012814 - Câmara Municipal de Ponta Delgada",
  "506755150 - Câmara Municipal de Salvaterra de Magos",
  "500051062 - Câmara Municipal de Sintra",
  "680011609 - Câmara Municípal de Torres Novas",
  "501138960 - Câmara Municipal de Valongo",
  "512065837 - Câmara Municipal do Corvo",
  "511217315 - Câmara Municipal do Funchal",
  "506538575 - Câmara Municipal S. João da Madeira",
  "512016852 - Centro de Saúde Angra do Heroismo",
  "512031282 - Centro de Saúde da Calheta",
  "512026432 - Centro de Saúde da Povoação",
  "512027595 - Centro de Saúde da Praia da Vitória",
  "512034958 - Centro de Saúde da Ribeira Grande",
  "512084726 - Centro de Saúde das Lajes",
  "503135593 - Centro de Saúde de Braga",
  "512034125 - Centro de Saúde de Ponta Delgada",
  "512084726 - Centro de Saúde de S. Roque do Pico",
  "512026289 - Centro de Saúde de Santa Cruz das Flores",
  "512027170 - Centro de Saúde de Sta. Cruz da Graciosa",
  "512030910 - Centro de Saúde de Velas",
  "672001527 - Centro de Saúde de Vila do Porto",
  "672001489 - Centro de Saúde de Vila Franco do Campo",
  "512026270 - Centro de Saúde do Nordeste",
  "672000598 - Centro de Saúde doa Horta",
  "94 - Comparticipação de outra Entidade",
  "500745439 - CPAS Caixa de Previdência dos Advogados",
  "511235283 - Dir. Reg. de Gestão e Des. dos Recursos",
  "505938022 - EDP, S. A.",
  "502865695 - Empordef",
  "505486580 - EPS - Gestão de Sistemas de Saúde",
  "600015270 - Escola Secundária de Penafiel",
  "501735925 - Escola Superior de Gestão de Santarém",
  "600073718 - Estação Vitivinicola Nacional",
  "503034975 - Europ Assistance",
  "600027651 - Fac. de Psic. e Cienc. da Ed. da Univ.",
  "502784083 - Faculdade de Arquitectura - Univ Téc Lx",
  "502618418 - Faculdade de Ciências da Universidade de",
  "502659807 - Faculdade de Farmácia de Lisboa",
  "500918880 - Fidelidade Mundial",
  "506917037 - Freguesia de A-dos-Cunhados",
  "500745684 - Fundação Calouste Gulbenkian",
  "501208583 - Fundação Salvador Caetano",
  "506518434 - Future Healthcare, Serviços de Saúde",
  "500166650 - GESTNAVE",
  "502661313 - Groupama Seguros SA",
  "502661321 - Groupama Seguros SA",
  "501639365 - Hospital de Joaquim Urbano",
  "506361578 - Hospital de São Teotónio, S. A.",
  "506361454 - Hospital Geral de Santo António, S. A.",
  "501597620 - Hospital S. João",
  "500746427 - IASFA - Instituto Acção Social Força Arm",
  "508791332 - Idealcare",
  "504598686 - IEP - Instituto das Estradas de Portugal",
  "504739506 - IMOPPI Inst Merc Obras Pub e Part e Imob",
  "500792887 - Imprensa Nacional - Casa da Moeda, S. A.",
  "500792828 - Indústria Aeronáutica de Portugal SA",
  "501391606 - INETI",
  "505305500 - Ins. de Solidadiedade e Segurança Social",
  "600020584 - Ins. Sup. Contabilidade e Admin. Lisboa",
  "501631720 - Inst. de Ciências B. de Abel Salazar",
  "504152980 - Inst. Politécnico Castelo Branco",
  "680010149 - Inst. Politécnico de Viana do Castelo",
  "506362299 - Inst. Port. Oncologia Francisco Gentil",
  "501510184 - Inst. Sup. Ciências do Trab. e Empresa",
  "511284349 - Inst.de Adm. da Saude e Assuntos Sociais",
  "501171592 - Instituto da Conservação da Natureza",
  "501460888 - Instituto da Habitação e da Reabilitação",
  "501176080 - Instituto dos Vinhos do Douro e do Porto",
  "680033548 - Instituto Politécnico de Viseu",
  "503494933 - Instituto Politécnico do Cávado e do Ave",
  "501639373 - Instituto Sup. de Contab. e Adm. Coimbra",
  "505869721 - Instituto Superior de Agronomia",
  "500743282 - Instituto Superior de Gestão",
  "501507930 - Instituto Superior Técnico",
  "500077568 - IOS ( Instituto de Obras Sociais)",
  "600016234 - ISEL - Inst. Sup. de Eng. de Lisboa",
  "501540709 - ISEP - Inst. Sup. de Engenharia do Porto",
  "680047514 - Junta de Freguesia de Algés",
  "506858324 - Junta de Freguesia de Arrentela",
  "501384660 - Lab. Nac. de Engenharia Civil",
  "500068658 - Liberty Seguros",
  "501689168 - Lusitania, Companhia Seguros, S.A.",
  "97 - Médis",
  "600012662 - Ministério da Defesa Nacional",
  "508752000 - Ministério da Saúde U.L.S. Guarda, E.P.E",
  "96 - Multicare",
  "502150319 - Município de Alcácer do Sal",
  "600018032 - Museu Nac. de Arqueologia - I.P. Museus",
  "99 - Outra Seguradora",
  "503509027 - Portugal Telecom",
  "512097780 - Praia Ambiente Empresa Municipal",
  "502101903 - Presidência do Conselho de Ministros",
  "500225680 - Rádio e TV de Portugal SGPS S. A.",
  "500095019 - Radiodifusão Portuguesa SA",
  "503434809 - Rar - Serviços de Assistência Clínica",
  "95 - REDE ADV",
  "671001310 - Região Autónoma da Madeira",
  "501413197 - Reitoria da Universidade do Porto",
  "500955743 - SAMS (Norte)",
  "501403736 - SAMS (Quadros)",
  "500825556 - SAMS (Sul e Ilhas)",
  "900270993 - Serv. Soc. Trabalhadores Mun. Arraiolos",
  "511228848 - Serviço Regional de Saúde, E. P. E.",
  "680017640 - Serviços Municipalizados de Castelo Bran",
  "501135987 - Serviços Soc. Caixa Geral de Depósitos",
  "506812782 - Serviços Sociais da C. M. Lisboa",
  "503680850 - Serviços Sociais Ministério Justiça",
  "501129979 - Sind. Nac. Pessoal Voo da Aviação Civil",
  "500842639 - Sindicato dos Bancários do Centro",
  "504837320 - Sindicato Independente da Banca",
  "98 - SNS",
  "510766714 - SS Trab. Mun. Montemor-o-Novo",
  "506623602 - TAP - Transportes Aéreos Portugueses",
  "600000362 - Turismo de Portugal",
  "502083514 - UBI - Universidade da Beira Interior",
  "510183085 - Unidade de Saúde da Ilha do Faial",
  "510161502 - Unidade Saúde Ilha Santa Maria",
  "600027716 - Univ do Porto Faculdade de Engenharia",
  "11 - Univerdade Nova de Lisboa",
  "502110660 - Universidade Aberta",
  "501082522 - Universidade Católica Portuguesa",
  "501461108 - Universidade de Aveiro",
  "12 - Universidade do Minho",
  "502821060 - Victoria - Seguros SA",
  "600006662 - SAD - PSP - Polícia de Segurança Pública",
];
const optionsMap: { [key: string]: string[] } = {
  referrer_provider,
  self_funding_tax_id,
};

function HardcodedDropdown(props: any) {
  const { clientKey, id, sproutaiKey, value } = props;
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      defaultValue={value}
      render={({ field: { onChange, value } }) => (
        <Autocomplete
          id={id}
          options={optionsMap[sproutaiKey]}
          onChange={(_event, newValue) => {
            onChange(newValue);
          }}
          value={value}
          componentsProps={{
            popper: {
              sx: {
                boxShadow: (theme) => theme.shadows[1],
                width: 'auto !important',
              },
            },
          }}
          ListboxProps={{
            sx: { fontSize: 12 },
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              aria-label={clientKey}
              error={!props.valid}
              variant="standard"
              inputProps={{
                ...params.inputProps,
                sx: {
                  fontSize: 12,
                },
              }}
            />
          )}
        />
      )}
      name={id}
    />
  );
}

export { HardcodedDropdown };
