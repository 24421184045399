import type { ClaimRecommendation } from 'types/Claims';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useTranslationRoot } from 'components/with-translation';
import { InfoIcon } from 'theme/overrides/CustomIcons';

interface TrafficLightProps {
  clientRecommendation: ClaimRecommendation[] | null;
}

export function getBackgroundColor(status: number) {
  if (status >= 0 && status < 1) {
    return 'success.main';
  } else if (status >= 1 && status < 2) {
    return 'error.main';
  } else if (status >= 2 && status < 3) {
    return 'warning.main';
  } else if (status >= 3 && status < 4) {
    return 'info.main';
  }

  return 'grey.300';
}

function TrafficLight({ clientRecommendation }: TrafficLightProps) {
  const { t } = useTranslationRoot();

  if (clientRecommendation) {
    const recommendations = Array.isArray(clientRecommendation)
      ? clientRecommendation
      : [clientRecommendation];

    return (
      <Box
        sx={{
          alignItems: 'center',
          backgroundColor: getBackgroundColor(
            Number(recommendations[0].status)
          ),
          borderRadius: '50%',
          display: 'flex',
          height: 24,
          justifyContent: 'center',
          width: 24,
        }}
      >
        <Tooltip
          arrow
          title={
            <>
              {recommendations.map(({ status, statusDetail }, index) => (
                <Stack key={index}>
                  <Typography variant="subtitle1">
                    {t('common.status')}: {status}
                  </Typography>
                  <Typography variant="body2">{statusDetail}</Typography>
                </Stack>
              ))}
            </>
          }
          disableInteractive
        >
          <span>
            <Button
              variant="text"
              color="inherit"
              disabled
              sx={{ color: 'inherit !important' }}
            >
              <InfoIcon sx={{ height: 14, width: 14 }} />
            </Button>
          </span>
        </Tooltip>
      </Box>
    );
  }

  return null;
}

// function TrafficLight({ status, statusDetail }: ClaimRecommendation) {
//   return (
//     <Box
//       sx={{
//         alignItems: 'center',
//         backgroundColor: getBackgroundColor(Number(status)),
//         borderRadius: '50%',
//         display: 'flex',
//         height: 24,
//         justifyContent: 'center',
//         width: 24
//       }}
//     >
//       <DialogTooltip
//         getDialog={{
//           index() {
//             return [header(`Status: ${status}`)];
//           }
//         }}
//       >
//         <InfoIcon sx={{ height: 14, width: 14 }} />
//       </DialogTooltip>
//     </Box>
//   );
// }

export { TrafficLight };
