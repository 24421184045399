import { ErrorBoundary } from 'react-error-boundary';
import { Provider as RollbarProvider } from '@rollbar/react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { QueryClientProvider } from '@tanstack/react-query';
import { SettingsProvider } from 'components/settings-context';
import Page500 from 'pages/Site/Page500';
import 'locales/i18n';
import 'amplify-config';

import App from './App';
import queryClient from './query-client';
import rollbar from './rollbar-config';

const isDevMode = import.meta.env.DEV;
// doing this weird thing in order to get e2e test working
// and without bundling MSW into production build
const isDevelopment = import.meta.env.MODE === 'development';
// bump

async function prepareMSW() {
  if (
    (isDevMode || isDevelopment) &&
    String(import.meta.env.VITE_MSW) === 'true'
  ) {
    const { worker } = await import('./mocks/browser');

    return worker.start({ onUnhandledRequest: 'bypass' });
  } else {
    return Promise.resolve();
  }
}

async function init() {
  await prepareMSW();

  const rootElement = document.getElementById('root') as HTMLElement;

  createRoot(rootElement).render(
    <RollbarProvider instance={rollbar}>
      <SettingsProvider>
        <BrowserRouter>
          <QueryClientProvider client={queryClient}>
            <ErrorBoundary FallbackComponent={Page500}>
              <App />
            </ErrorBoundary>
          </QueryClientProvider>
        </BrowserRouter>
      </SettingsProvider>
    </RollbarProvider>
  );
}

void init();
