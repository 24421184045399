import type { StepIconProps } from '@mui/material/StepIcon';
import Box from '@mui/material/Box';
import {
  CHECK_LIST_ICON,
  CHECK_MARK_ICON,
  DIAGNOSIS_ICON,
  EMERGENCY_ICON,
  MEDICATION_ICON,
  STETHOSCOPE_ICON,
  SURGICAL_ICON,
} from 'constants/public-icons';
import { SvgIconStyle } from 'components/SvgIconStyle.tsx';

const iconsLibrary: { [type: string]: string } = {
  asl_assessment: CHECK_LIST_ICON,
  hospitalization: EMERGENCY_ICON,
  diagnosis: DIAGNOSIS_ICON,
  surgery: SURGICAL_ICON,
  medication: MEDICATION_ICON,
  doctor_visit: STETHOSCOPE_ICON,
};

function TimelineIcon({ icon }: StepIconProps) {
  return (
    <Box
      sx={{
        width: 48,
        height: 48,
        border: '3px solid',
        borderRadius: '50%',
        borderColor: 'primary.main',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <SvgIconStyle
        src={iconsLibrary[String(icon)] ?? CHECK_MARK_ICON}
        sx={{
          fill: 'white',
        }}
      />
    </Box>
  );
}

export { TimelineIcon };
