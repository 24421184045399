import { Controller, useFormContext } from 'react-hook-form';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

const doubts = [
  "Analises com restricao",
  "Apolices 40 medis SBS",
  "Consultas de especialidades diferentes juntas",
  "Data de entrada menor que da fatura",
  "Data para cada acto medico",
  "Declaracao pre comparticipacao incompleta",
  "Descricao de acidente",
  "Descricao do AM",
  "Descricao e valor de cada AM",
  "Despesa no estrangeiro moeda diferente de euro",
  "Despesas juntas",
  "Documento incompleto Faltam páginas",
  "Documento nao e fatura",
  "Falta declaracao comparticipacao",
  "Falta PM",
  "Fatura cortada",
  "Fatura ilegivel",
  "Fatura rasurada",
  "Fatura sem NIF",
  "Lentes Regressivas",
  "NIF da PS diferente da fatura",
  "Nota de Alta",
  "Nota de credito",
  "Nota de debito",
  "PS da Prescricao e Fatura Diferentes",
  "PS nao consta indexado na PS da PM",
  "PS nao consta indexado no subscritor",
  "PS nao consta validar na tabela de portal",
  "REEMBOLSO WEB indisponibilidade do sistema",
  "Validar ato medico",
  "Validar carta de devolucao",
  "Validar comparticipacao",
  "Validar data de ocorrencia",
  "Validar desconto",
  "Validar especialidade",
  "Validar exposicao da PS",
  "Validar PM",
  "Validar PS",
  "Validar RM Info Clinica",
  "Validar sub sistemas",
  "Validar unidades",
  "Validar valores",
  "Valor para cada AM",
];

function HardcodedMultiselect(props: any) {
  const { clientKey, id, sproutaiKey, value } = props;
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      defaultValue={value}
      render={({ field: { onChange, value } }) => (
        <FormControl fullWidth>
          <Select
            id={sproutaiKey}
            multiple
            value={value.split(',')}
            onChange={(event) => {
              onChange(event.target.value.join(',') as string);
            }}
            inputProps={{ 'aria-label': clientKey, sx: { fontSize: 12 } }}
            input={<Input sx={{ fontSize: 12 }} />}
            renderValue={(selected) => selected.join(', ')}
            MenuProps={{
              MenuListProps: {
                dense: true,
              },
              PaperProps: {
                style: {
                  maxHeight: '75vh',
                },
              },
            }}
          >
            {doubts.map((doubt) => (
              <MenuItem key={doubt} value={doubt}>
                <Checkbox
                  checked={
                    value
                      .split(',')
                      .findIndex((item: string) => item === doubt) > -1
                  }
                  size="small"
                />
                <ListItemText primary={doubt} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      name={id}
    />
  );
}

export { HardcodedMultiselect };
