import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'components/toast';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { FormProvider, useForm, useFormState } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { HIL_VALIDATION_FAIL } from 'analytics/events';
import { updateDataLayer } from 'analytics/utils';
import type { DocumentValidation } from 'types/Documents';
import {
  TypographyWithTranslation,
  WithTranslationRoot,
} from 'components/with-translation';
import { useFeatureFlag } from 'components/customHooks/useFeatureFlag';
import { HITL_TOOL_PAGE } from 'constants/translation-keys';
import {
  getIsDocumentValidationStageCompleted,
  getIsDocumentValidationStageError,
  isLineLevelItem,
  isNotValidItem,
} from 'state/selectors/documents';
import { useDocumentValidation } from 'state/queries/documents';
import { HEADER } from 'theme/layout';
import { generateUuid } from 'utils/generate-uuid';
import { unique } from 'utils/array';

import type { FilterTypes, HitLFormProps, HitLFormValues, List } from './types';
import { FormButtonsNew } from './FormButtonsNew';
import { FormItems } from './FormItems';
import { ToolLayout } from './ToolLayout';
import { ALL } from './utils';
import {
  getInvalidItemsAndInvalidLineItems,
  getLastLineIdx,
  initialiseNewForm,
  isItemInSameLine,
  normaliseValidationResults,
  SKIP_INPUT_SUFFIX,
  transformFormValuesToState,
} from './utils';

/**
 * Documenting EN-1945 here
 * Below I've used isNotValidItem as a temporary fix for Axa
 * Ultimately, I'm still using content array to determine how to style input
 * fields. Whereas, I should be letting useForm dictate whether the field is
 * genuinely in error, and then it can handle the styling that way.
 * Right now, there's a lot of manual work dictating when to show the input as
 * red and the skip checkbox.
 */
function HitLFormNew({
  document,
  currentPage,
  disableSubmit,
  fromClaimView,
  isDocumentTypeValid,
  isLockedByCurrentUser,
  isMultipage,
  leftColumn,
  list,
  lockDocument,
  ocrHistoryResource,
  onReject,
  onSave,
  onSkip,
  onSubmit,
  resources,
  setCrop,
  updateList,
  updateResources,
  validationResults,
}: HitLFormProps) {
  const enableValidationResults = useFeatureFlag('enableHILValidationResults');
  const enableValidationResultsEndpoint = useFeatureFlag(
    'enableHILValidationResultsEndpoint'
  );
  const { t } = useTranslation(HITL_TOOL_PAGE);
  const formRef = useRef<HTMLFormElement>(null);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [skippedIds, setSkippedIds] = useState<string[]>([]);
  const [filterItemsBy, setFilterItemsBy] = useState<FilterTypes>(ALL);
  const validateDocument = useDocumentValidation(document.id);
  const { id } = document;

  /**
   * arrange the items, define a schema and get default values
   */
  const { defaultValues, schema, validItems, invalidItems } = useMemo(
    () => initialiseNewForm(list, resources),
    [list, resources]
  );
  const initialErrors = useMemo(() => {
    if (!enableValidationResults || !validationResults) {
      return {};
    }
    return Object.entries(validationResults).reduce((acc, [key, value]) => {
      if (isNotValidItem(resources[key])) {
        return {
          ...acc,
          [key]: { message: unique(value).join('\n'), type: 'custom' },
        };
      }

      return acc;
    }, {});
  }, [enableValidationResults, resources, validationResults]);

  const methods = useForm<HitLFormValues>({
    defaultValues,
    errors: initialErrors,
    resolver: yupResolver(schema),
  });
  const { control, handleSubmit, register, setError, setValue } = methods;
  const { errors, isSubmitting } = useFormState({
    control,
  });

  function setErrorsWithValidationResults(
    normalisedValidationResults: Record<string, string[]>
  ) {
    if (enableValidationResults && normalisedValidationResults) {
      Object.keys(normalisedValidationResults).forEach((key) => {
        const item = resources[key];

        if (isNotValidItem(item)) {
          setError(key, {
            message: unique(normalisedValidationResults[key]).join('\n'),
            type: 'custom',
          });
        }
      });
    }
  }

  useEffect(
    function reapplyValidationResultsErrorOnSubmit() {
      if (enableValidationResults && errors) {
        setErrorsWithValidationResults(validationResults);
      }
    },
    // eslint-disable-next-line
    [errors]
  );

  /**
   * Something wrong with RHF setFocus() function
   * Need to investigate further
   * But using this as a workaround to scroll to error field
   */
  useEffect(
    function scrollToError() {
      // const firstError = (
      //   Object.keys(errors) as Array<keyof typeof errors>
      // ).reduce<keyof typeof errors | null>((field, a) => {
      //   const fieldKey = field as keyof typeof errors;
      //   return errors[fieldKey] ? fieldKey : a;
      // }, null);
      const errorsLength = Object.keys(errors).length;

      if (errorsLength) {
        const errorElements =
          formRef.current?.getElementsByClassName('Mui-error');
        const errorElementsLength = errorElements?.length;

        if (errorElementsLength) {
          const rect = errorElements[0]?.getBoundingClientRect();

          if (rect) {
            window.scrollTo({
              behavior: 'smooth',
              top: rect.top - HEADER.MAIN_DESKTOP_HEIGHT - 85,
            });
          }
        }
      }
    },
    [errors]
  );

  useEffect(
    function bindLockDocumentToInputs() {
      function bindOnBlur(name: string) {
        register(name, { onBlur: () => lockDocument() });
      }

      function bindItems(list: List, onEvent = bindOnBlur) {
        list.forEach((id) => {
          if (id && !Array.isArray(id)) {
            onEvent(id);
          }
        });
      }

      bindItems(list);
    },
    [list] // eslint-disable-line
  );

  useEffect(function bindKeyboardShortcuts() {
    const form = formRef.current;

    function focusInput(inputs: (Element | null)[], index: number) {
      const input = inputs[index] as HTMLInputElement;
      input.focus();
      // input.select();
    }

    function getActiveElement(inputs: (Element | null)[]) {
      return inputs.indexOf(window.document.activeElement);
    }

    function getInputs() {
      return Array.prototype.slice.call(
        form?.querySelectorAll('input[tabindex="0"]')
      );
    }

    function scrollBackToTop() {
      window.scrollTo({
        behavior: 'smooth',
        top: 0,
      });
    }

    function keyboardShortcuts(event: globalThis.KeyboardEvent) {
      const { key } = event;

      if (key === 'ArrowDown' || key === 'Enter') {
        event.preventDefault();
        const inputs = getInputs();
        const index = getActiveElement(inputs);
        const nextIndex = index + 1;

        if (nextIndex < inputs.length) {
          focusInput(inputs, nextIndex);
        }
      }

      if (key === 'ArrowUp') {
        event.preventDefault();
        const inputs = getInputs();
        const index = getActiveElement(inputs);
        const prevIndex = index - 1;

        if (prevIndex >= 0) {
          focusInput(inputs, prevIndex);
        }

        if (prevIndex === 0) {
          scrollBackToTop();
        }
      }
    }

    if (form) {
      form.addEventListener('keydown', keyboardShortcuts, false);
    }

    return () => {
      if (form) {
        form.removeEventListener('keydown', keyboardShortcuts);
      }
    };
  }, []);

  function handleValidationError(response: DocumentValidation) {
    if (getIsDocumentValidationStageError(response)) {
      throw new Error(t('form.validationError') as string);
    }
  }

  async function handleFormSubmit(values: HitLFormValues) {
    if (enableValidationResultsEndpoint) {
      if (onSubmit && !disableSubmit) {
        const content = transformFormValuesToState(list, resources)(values);
        let validationToastId = '';

        try {
          validationToastId = toast.loading(t('form.validationLoading'));
          const response = await validateDocument.mutateAsync({ id, content });

          handleValidationError(response);

          if (getIsDocumentValidationStageCompleted(response)) {
            toast.dismiss(validationToastId);
            onSubmit(values);
            return;
          }

          const failedValidations = response.validationResults.filter(
            ({ result }) => result === 'failed'
          );
          toast.error(t('form.reviewValidationErrors'), {
            id: validationToastId,
          });
          const normalisedValidationResults = normaliseValidationResults({
            validationResults: failedValidations,
            list,
            resources,
          });
          setErrorsWithValidationResults(normalisedValidationResults);
          updateDataLayer({
            event: HIL_VALIDATION_FAIL,
            documentId: id,
          });
        } catch (error) {
          toast.error(t('form.validationError'), {
            id: validationToastId,
          });
        }
      }
    } else {
      onSubmit(values);
      return;
    }
  }

  function handleFormSave() {
    if (onSave && !disableSubmit) {
      onSave(methods.getValues());
    }
  }

  function handleReject(reason: string) {
    onReject(reason);
  }

  function toggleSkippedItems(value: boolean) {
    const getAllInvalidIds = getInvalidItemsAndInvalidLineItems(
      list,
      resources
    );
    const items = getAllInvalidIds(invalidItems);
    items.forEach((id) => {
      setValue(`${id}${SKIP_INPUT_SUFFIX}`, value);
    });
  }

  function toggleCheckAll() {
    const getAllInvalidIds = getInvalidItemsAndInvalidLineItems(
      list,
      resources
    );
    const items = getAllInvalidIds(invalidItems);
    setIsCheckAll((checkAll) => !checkAll);
    setSkippedIds(items);
    toggleSkippedItems(true);

    if (isCheckAll) {
      setSkippedIds([]);
      toggleSkippedItems(false);
    }
  }

  function updateSkippedIds(checked: boolean) {
    return (id: string) => {
      if (checked) {
        setSkippedIds((skippedIds) => [...skippedIds, id]);
      } else {
        setSkippedIds((skippedIds) => skippedIds.filter((item) => item !== id));
      }
    };
  }

  function updateIsCheckList(id: string, checked: boolean) {
    const currentItem = resources[id];
    const isLineItem = isLineLevelItem(currentItem);
    const updateState = updateSkippedIds(checked);

    if (!checked) {
      setIsCheckAll(false);
    }

    if (isLineItem) {
      // ensure all line item is skipped together
      list.forEach((id) => {
        const item = resources[id];
        if (isItemInSameLine(currentItem, item)) {
          updateState(id);
        }
      });
    } else {
      updateState(id);
    }
  }

  function addLineItem() {
    const lastLineIdx = getLastLineIdx(list, resources, currentPage);
    const lastLineItems = list.filter(
      (id) => resources[id].lineIdx === lastLineIdx
    );

    lastLineItems.forEach((id) => {
      const item = resources[id];
      const newId = generateUuid();
      const value = '';
      const resource = {
        ...item,
        lineIdx: lastLineIdx + 1,
        pageIdx: currentPage,
        valid: false,
        value,
      };

      updateList(newId);
      updateResources(newId, resource);
      setValue(newId, value);
    });
  }

  function deleteLineItem(id: string) {
    const currentItem = resources[id];
    const lineItems = list.reduce<string[]>((ids, id) => {
      const item = resources[id];

      if (isItemInSameLine(currentItem, item)) {
        return [...ids, id];
      }

      return ids;
    }, []);

    lineItems.forEach((id) => {
      const item = resources[id];
      updateResources(id, {
        ...item,
        deleted: !item.deleted,
      });
    });
  }

  const hasInvalidItems = !!invalidItems.length;
  const hasValidItems = !!validItems.length;

  if (hasValidItems || hasInvalidItems) {
    const hasLineItems =
      list.findIndex((id) => isLineLevelItem(resources[id])) > 0;
    const rightColumn = (
      <Stack>
        <FormButtonsNew
          isSubmitting={isSubmitting}
          isNotLockedByCurrentUser={!isLockedByCurrentUser}
          handleReject={handleReject}
          isCheckAll={isCheckAll}
          toggleCheckAll={toggleCheckAll}
          hasLineItems={hasLineItems}
          handleNextDocument={onSkip}
          addLineItem={addLineItem}
          filterItemsBy={filterItemsBy}
          setFilterItemsBy={setFilterItemsBy}
          handleSaveDocument={handleFormSave}
          isDocumentTypeValid={isDocumentTypeValid}
          fromClaimView={fromClaimView}
          disableSubmit={disableSubmit}
        />

        <FormItems
          deleteLineItem={deleteLineItem}
          filterItemsBy={filterItemsBy}
          hasInvalidItems={hasInvalidItems}
          hasValidItems={hasValidItems}
          isCheck={skippedIds}
          isMultipage={isMultipage}
          list={list}
          resources={resources}
          setCrop={setCrop}
          updateIsCheckList={updateIsCheckList}
          isDocumentTypeValid={isDocumentTypeValid}
          ocrHistoryResource={ocrHistoryResource}
          fromClaimView={fromClaimView}
        />
      </Stack>
    );

    return (
      <WithTranslationRoot namespace={HITL_TOOL_PAGE}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(handleFormSubmit)} ref={formRef}>
            <ToolLayout leftColumn={leftColumn} rightColumn={rightColumn} />
          </form>
        </FormProvider>
      </WithTranslationRoot>
    );
  }

  return (
    <Alert severity="error" sx={{ m: 2 }}>
      <TypographyWithTranslation
        i18nKey="form.noItems"
        variant="body1"
        namespace={HITL_TOOL_PAGE}
      />
    </Alert>
  );
}

export { HitLFormNew };
