import { useState } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import type { ClaimPagesUrls } from 'types/Claims.ts';
import { CanvasTool } from 'components/CanvasTool';
import { useFeatureFlag } from 'components/customHooks/useFeatureFlag';
import { TypographyWithTranslation } from 'components/with-translation';

interface ReviewToolGalleryProps {
  imageUrls: ClaimPagesUrls;
  initialEnrichmentFormWidth: string | number;
}

function ReviewToolGallery({
  imageUrls,
  initialEnrichmentFormWidth,
}: ReviewToolGalleryProps) {
  const [page, setPage] = useState(0);
  const enableThumbnails = useFeatureFlag('enableReviewToolThumbnails') as true;

  return (
    <Stack
      sx={{
        order: { md: 2, lg: 3 },
        position: 'relative',

        width: {
          xs: '100%',
          md: `100% - ${Number(initialEnrichmentFormWidth)}px`,
        },

        overflow: {
          md: 'hidden',
        },
      }}
    >
      {imageUrls && imageUrls.length > 0 ? (
        <CanvasTool
          hasThumbnails={enableThumbnails}
          urls={imageUrls.map(({ url }) => url)}
          useCtrlZoom
          thumbnails={imageUrls}
          currentPage={page}
          setPage={setPage}
        />
      ) : (
        <Box
          component="section"
          sx={{
            p: 2,
          }}
        >
          <TypographyWithTranslation
            i18nKey="enrichmentTool.noUrls"
            data-testid="no-images-to-show"
          />
        </Box>
      )}
    </Stack>
  );
}

export { ReviewToolGallery };
