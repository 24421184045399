import dayjs from 'locales/dayjs';
import { array, boolean, date, number, object, string } from 'yup';
import type { BaseSchema } from 'yup';
import { LINE } from 'constants/content-level';
import { LIST, SINGLE } from 'constants/field-type';
import { BOOL, DATE, INT, NONETYPE, STR } from 'constants/value-type';
import {
  isLineLevelItem,
  isNotLineLevelItem,
  isNotValidItem,
  isValidItem,
} from 'state/selectors/documents';
import type {
  DocumentContent,
  DocumentValidationResult,
  DocumentValueType,
} from 'types/Documents';

import type { HitLFormValues, List, Resources } from './types';

type SchemaMap = { [k: string]: BaseSchema };

const ALL = 'all';
const INVALID = 'invalid';
const VALID = 'valid';
const SKIP_INPUT_SUFFIX = '-skipped';
const OTHER_REASON = '${other}';

/**
 * hard coding this dictionary until IDP finds a better way to detect whether
 * an item is a string or integer type
 * DELETE THIS WHEN NOT NEEDED
 */
const valueTypes: { [k: string]: { [name: string]: DocumentValueType } } = {
  bono: {
    document_id: STR,
    date: STR,
    affiliate_rut: STR,
    beneficiary_rut: STR,
    vendor_rut: STR,
    code: STR,
    quantity: INT,
    direct_cost: INT,
    amount_reimbursed: INT,
    copayment: INT,
  },
  reembolso: {
    document_id: STR,
    date: STR,
    affiliate_rut: STR,
    beneficiary_rut: STR,
    vendor_rut: STR,
    code: STR,
    quantity: STR,
    direct_cost: STR,
    amount_reimbursed: STR,
    copayment: STR,
    boleta_nb: STR,
    item_date: STR,
  },
};

/**
 * TODO: delete this when not needed
 */
function getValueType(
  item: DocumentContent,
  documentType?: string
): DocumentValueType {
  const { valueType, sproutaiKey } = item;

  if (documentType) {
    if (valueType === NONETYPE && !valueTypes[documentType]) {
      return NONETYPE;
    }

    if (valueType === NONETYPE && valueTypes[documentType][sproutaiKey]) {
      return valueTypes[documentType][sproutaiKey];
    }

    return valueType ?? valueTypes[documentType][sproutaiKey];
  }

  return NONETYPE;
}

function transformFromStateToFormValues(
  valueType: DocumentValueType,
  value: any
) {
  switch (valueType) {
    case BOOL:
      return value === '' ? '' : value === 'True';
    default:
      return value;
  }
}

function getDefaultValues(list: List, resources: Resources) {
  return list.reduce((c, id) => {
    const { value, valueType } = resources[id];

    return {
      ...c,
      [id]: transformFromStateToFormValues(valueType, value) ?? '',
    };
  }, {});
}

function initState(documentType?: string) {
  return (content: DocumentContent[]) =>
    content?.map((value) => ({
      ...value,
      valueType: getValueType(value, documentType),
    }));
}

function getDocumentType(contents: DocumentContent[]) {
  return contents.find((item) => item.sproutaiKey === 'document_type')?.value;
}

function isValidNullItem(content: DocumentContent) {
  return typeof content.valid === 'object' && content.valid === null;
}

function getItems(conditions: (item: DocumentContent) => boolean) {
  return (list: List, resources: Resources) => {
    const newList: string[] = [];

    list.forEach((id) => {
      const item = resources[id];
      if (conditions(item)) {
        newList.push(id);
      }
    });

    return newList;
  };
}

const getIdentifiedItems = getItems(
  (item) =>
    isNotLineLevelItem(item) && (isValidItem(item) || isValidNullItem(item))
);

const getUnidentifiedItems = getItems(
  (item) => isNotLineLevelItem(item) && isNotValidItem(item)
);

function getLineItems(list: List, resources: Resources) {
  function isLineItemsAllValid(items: List) {
    return items.every((id) => resources[id].valid);
  }

  const items = getItems((item) => isLineLevelItem(item))(list, resources);

  let lineItems: { [key: string]: List } = {};
  let identifiedLineItems: List = [];
  let unidentifiedLineItems: List = [];

  items.forEach((id) => {
    const item = resources[id];
    const lineIdx = String(item.lineIdx);

    if (typeof lineItems[lineIdx] === 'undefined') {
      lineItems = {
        ...lineItems,
        [lineIdx]: [],
      };
    }

    lineItems[lineIdx].push(id);
  });

  Object.values(lineItems).forEach((items) => {
    const isValid = isLineItemsAllValid(items);

    if (isValid) {
      identifiedLineItems = [...identifiedLineItems, ...items];
    } else {
      unidentifiedLineItems = [...unidentifiedLineItems, ...items];
    }
  });

  return { unidentifiedLineItems, identifiedLineItems };
}

function getLastLineIdx(list: List, resources: Resources, pageIdx: number) {
  const filteredList = list
    .filter((id) => {
      const item = resources[id];

      if (item.level === LINE && item.pageIdx === pageIdx) {
        return id;
      }

      return null;
    })
    .filter(Boolean);
  const lineIdxes = filteredList
    .map((id) => resources[id].lineIdx)
    .filter((lineIdx) => typeof lineIdx === 'number') as number[];

  return lineIdxes.length ? Math.max(...lineIdxes) : 0;
}

const singleSchemaMap: SchemaMap = {
  bool: boolean()
    .nullable()
    .transform((v) => (typeof v === 'boolean' ? v : null)),
  date: date()
    .nullable()
    .transform((curr, orig) => (orig === '' ? null : curr)),
  float: number()
    .nullable()
    .transform((v) => (isNaN(v) ? null : v)),
  int: number()
    .nullable()
    .transform((v) => (isNaN(v) ? null : v)),
  str: string(),
  array: array(),
};

// for field_type === LIST
const listSchemaMap: SchemaMap = {
  int: string()
    // matching letters and other special characters too in case
    // ocr doesn't pick up the values correctly
    .matches(/^[0-9.,;]+$/, { excludeEmptyString: true })
    .nullable(true),
  str: string(),
  array: array(),
};

const schemaMap: { [k: string]: SchemaMap } = {
  [SINGLE]: singleSchemaMap,
  [LIST]: listSchemaMap,
};
function generateSchema(
  list: List,
  resources: Resources,
  skipInputSuffix: string = SKIP_INPUT_SUFFIX
) {
  let schema = {};

  for (let i = 0; i < list.length; i++) {
    const id = list[i];
    const item = resources[id];
    const { clientKey, fieldType, valueType } = item;
    let validator = schemaMap[fieldType][valueType] ?? string();

    // assume valid item is an identifiedItem
    if (isValidItem(item) || isValidNullItem(item)) {
      // accept empty values as per usual but still validate the value type
      validator = validator.nullable(true);
    } else {
      // unidentified items will have a skipped (bool) checkbox
      // so if the checkbox is true then validation is not required
      validator = validator.label(clientKey).when(`${id}${skipInputSuffix}`, {
        is: true,
        then: validator.notRequired(),
        otherwise: validator.required(),
      });
    }

    schema = {
      ...schema,
      [id]: validator,
    };

    if (isNotValidItem(item)) {
      schema = {
        ...schema,
        [`${id}${SKIP_INPUT_SUFFIX}`]: boolean(),
      };
    }
  }

  return object(schema);
}

function initialiseItems(list: List, resources: Resources) {
  const unidentifiedItems = getUnidentifiedItems(list, resources);
  const identifiedItems = getIdentifiedItems(list, resources);
  const { unidentifiedLineItems, identifiedLineItems } = getLineItems(
    list,
    resources
  );

  return {
    identifiedItems,
    identifiedLineItems,
    unidentifiedItems,
    unidentifiedLineItems,
  };
}

function getFormItems(list: List, resources: Resources) {
  const invalidItems = getItems(isNotValidItem)(list, resources);
  const validItems = getItems(
    (item) => isValidItem(item) || isValidNullItem(item)
  )(list, resources);

  return { invalidItems, validItems };
}

function initialiseForm(list: List, resources: Resources) {
  const items = initialiseItems(list, resources);
  const defaultValues = getDefaultValues(list, resources);

  // line items will have their schema at edit time
  // therefore we should allow the user to submit the form
  const schema = generateSchema(
    [...items.unidentifiedItems, ...items.identifiedItems],
    resources
  );

  return {
    defaultValues,
    ...items,
    schema,
  };
}

function initialiseNewForm(list: List, resources: Resources) {
  const items = getFormItems(list, resources);
  const defaultValues = getDefaultValues(list, resources);
  const schema = generateSchema(list, resources);

  return {
    defaultValues,
    ...items,
    schema,
  };
}

function isSkipped(obj: Record<string, any>, key: string) {
  const value = obj[`${key}${SKIP_INPUT_SUFFIX}`];
  return value ?? undefined;
}
function transformFormValuesToState(list: List, resources: Resources) {
  function transformValue(sproutaiKey: string, type: string, value: string) {
    let newValue: string | boolean;

    switch (type) {
      case DATE:
        newValue = dayjs(value).isValid()
          ? dayjs(value).utc(false).toISOString()
          : '';
        break;
      case STR:
        // TODO: this is a hack for now until IDP can handle non-alphanumeric
        newValue = sproutaiKey.includes('_rut')
          ? value.replace(/\W/g, '')
          : value;
        break;
      case BOOL:
        newValue = value === '' ? '' : value ? 'True' : 'False';
        break;
      default:
        newValue = value;
    }

    return newValue;
  }

  return (values: HitLFormValues) => {
    const newResources = { ...resources };

    Object.entries(values).forEach(([fieldKey, fieldValue]) => {
      const foundItem = newResources[fieldKey];

      if (foundItem) {
        const { sproutaiKey, valueType } = foundItem;
        const valid = isSkipped(values, fieldKey) ? null : foundItem.valid;
        const value = transformValue(sproutaiKey, valueType, fieldValue);
        // given a user enters a value when skip is checked then valueType
        // should be string
        const newValueType =
          valid === null && value
            ? STR
            : value && valueType === 'NoneType'
              ? STR
              : valueType;

        newResources[fieldKey] = {
          ...foundItem,
          valid,
          value,
          valueType: newValueType,
        };
      }
    });

    return list.map((id) => newResources[id]);
  };
}

function hasDocumentTypeItem(resources: Resources) {
  return (id: string) => {
    const { sproutaiKey } = resources[id];
    return sproutaiKey === 'document_type';
  };
}

function getGroupedLineItems(list: List, resources: Resources) {
  return list.reduce<{ [key: string]: List }>((a, id) => {
    const item = resources[id];
    const lineIdx = String(item.lineIdx);
    let acc: { [key: string]: string[] } = { ...a };

    // store array of ids with the same lineIdx into an object
    if (typeof acc[lineIdx] === 'undefined') {
      acc = {
        ...acc,
        [lineIdx]: [],
      };
    }

    acc[lineIdx].push(id);

    return acc;
  }, {});
}

function isItemInSameLine(oldItem: DocumentContent, newItem: DocumentContent) {
  return (
    oldItem.lineIdx === newItem.lineIdx && oldItem.pageIdx === newItem.pageIdx
  );
}

function getInvalidItemsAndInvalidLineItems(list: List, resources: Resources) {
  return (invalidItems: List) =>
    invalidItems.reduce<string[]>((ids, id) => {
      const item = resources[id];

      if (isLineLevelItem(item)) {
        const lineItems = list.filter((itemId) =>
          isItemInSameLine(resources[itemId], item)
        );

        return [...ids, ...lineItems];
      }

      return [...ids, id];
    }, []);
}

function filterValidationResultsByFailedResult(
  validationResults: DocumentValidationResult[]
) {
  return validationResults.filter(({ result }) => result === 'failed');
}

function normaliseValidationResults({
  validationResults,
  list,
  resources,
}: {
  validationResults: DocumentValidationResult[];
  list: List;
  resources: Resources;
}) {
  // given validationResult
  // {
  //   "fields": [
  //     "affiliate_rut",
  //     "beneficiary_rut"
  //   ],
  //   "explanation": "RUTs are the same"
  // }
  // we want to transform it to
  // {
  //   "affiliate_rut": ['RUTs are the same'],
  //   "beneficiary_rut": ['RUTs are the same']
  // }
  // and finally to
  // {
  //   id1: ['RUTs are the same'],
  //   id2: ['RUTs are the same']
  // }
  // since each field uses an id as its key instead of sproutaiKey
  const uniqueByField = validationResults.reduce(
    (acc, curr) => {
      const result = curr.fields.reduce((a, c) => {
        if (!acc[c]) {
          return {
            ...a,
            [c]: [curr.explanation],
          };
        }

        return {
          ...a,
          [c]: [...acc[c], curr.explanation],
        };
      }, {});

      return { ...acc, ...result };
    },
    {} as { [key: string]: string[] }
  );

  return Object.keys(uniqueByField).reduce((acc, curr) => {
    const id = list.find((id) => resources[id].sproutaiKey === curr);

    if (id) {
      return {
        ...acc,
        [id]: uniqueByField[curr],
      };
    }

    return acc;
  }, {});
}

export {
  ALL,
  filterValidationResultsByFailedResult,
  generateSchema,
  getDefaultValues,
  getDocumentType,
  getInvalidItemsAndInvalidLineItems,
  getLastLineIdx,
  getGroupedLineItems,
  hasDocumentTypeItem,
  initialiseForm,
  initialiseNewForm,
  initialiseItems,
  initState,
  INVALID,
  isItemInSameLine,
  isSkipped,
  normaliseValidationResults,
  OTHER_REASON,
  SKIP_INPUT_SUFFIX,
  transformFormValuesToState,
  VALID,
};
