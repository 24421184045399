import { useFeatureFlag } from 'components/customHooks/useFeatureFlag';

import ClaimView from './ClaimView';
import ClaimViewV2 from './ClaimViewV2';

function Claim() {
  const enableClaimsNewView = useFeatureFlag('enableClaimsNewView');

  if (enableClaimsNewView) {
    return <ClaimViewV2 />;
  } else {
    return <ClaimView />;
  }
}

export default Claim;
