import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import {
  ButtonWithTranslation,
  TypographyWithTranslation,
} from 'components/with-translation';
import rollbar from 'rollbar-config';
import { HITL_TOOL_PAGE } from 'constants/translation-keys';

interface FallbackProps {
  error: Error;
  resetErrorBoundary: () => void;
}

function Fallback({ error, resetErrorBoundary }: FallbackProps) {
  rollbar.error('HIL tool failure', error);

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        py: 2,
      }}
    >
      <Container>
        <Box
          role="alert"
          sx={{ width: 1, margin: 'auto', textAlign: 'center' }}
        >
          <TypographyWithTranslation
            i18nKey="fallback.heading"
            variant="h6"
            paragraph
            namespace={HITL_TOOL_PAGE}
          />
          <Typography
            component="pre"
            sx={{ color: 'text.secondary', display: 'none' }}
          >
            {error.message}
          </Typography>
          <ButtonWithTranslation
            i18nKey="fallback.retry"
            onClick={resetErrorBoundary}
            variant="contained"
            sx={{ marginTop: 3 }}
            namespace={HITL_TOOL_PAGE}
          />
        </Box>
      </Container>
    </Box>
  );
}

export { Fallback };
