export function updateDataLayer({
  event,
  ...props
}: {
  event: string;
  [key: string]: any;
}) {
  if (typeof window !== 'undefined' && window.dataLayer) {
    window.dataLayer.push({
      event,
      ...props,
    });
  }
}

export function updateWindowPerformanceObject(eventName: string) {
  if (typeof window !== 'undefined') {
    window.__performance = {
      ...window.__performance,
      [eventName]: Date.now(),
    };
  }
}

export function getEventTimestamp(name: string) {
  if (typeof window !== 'undefined' && window.__performance) {
    return window.__performance[name];
  }
  return 0;
}
