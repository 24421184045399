import type { SyntheticEvent } from 'react';
import TabList from '@mui/lab/TabList';
import Tab from '@mui/material/Tab';
import { generalConfig } from 'config';
import { TabWithTranslation } from 'components/with-translation';
import { SvgIconStyle } from 'components/SvgIconStyle';
import { AWAITING_REVIEW, COMPLETED, REJECTED } from 'constants/claims';
import { LIST_ICON } from 'constants/public-icons';
import { useTranslationRoot } from 'components/with-translation';
import type { ClaimSummaryWidget } from 'types/Widgets.ts';

import { PAGES, iconsMap } from './ClaimViewV2';

export interface ClaimViewTabsProps {
  isClaimCompleted: boolean;
  isSuperAdminUser: boolean;
  claimStatus: string;
  onChangePage: (event: SyntheticEvent, page: string) => void;
  dynamicTabs: { [key: string]: ClaimSummaryWidget[] };
  hasDocumentsInAwaitingReview: boolean;
}

const SHOW_REVIEW_SCREEN = [AWAITING_REVIEW, COMPLETED, REJECTED];

function ClaimViewTabs({
  isClaimCompleted,
  isSuperAdminUser,
  claimStatus,
  onChangePage,
  dynamicTabs,
  hasDocumentsInAwaitingReview,
}: ClaimViewTabsProps) {
  const { t } = useTranslationRoot();
  const getTabLabel = (tab: string) => {
    const tabLabel = `summary.${tab}`;
    return t(tabLabel) === tabLabel ? tab : t(tabLabel);
  };

  return (
    <TabList onChange={onChangePage}>
      {(Object.keys(PAGES) as Array<keyof typeof PAGES>)
        .map((key) => {
          // metadata tab
          if (!isSuperAdminUser && key === 'metadata') {
            return null;
          }

          // review tab
          if (
            key === 'review' &&
            (!SHOW_REVIEW_SCREEN.includes(claimStatus) ||
              generalConfig.disableClaimReviewScreen ||
              hasDocumentsInAwaitingReview)
          ) {
            return null;
          }

          if (isClaimCompleted && key === 'feedback') {
            return null;
          }

          return (
            <TabWithTranslation
              key={key}
              icon={<SvgIconStyle src={iconsMap[key]} />}
              labelI18nKey={`summary.${key}`}
              value={PAGES[key]}
            />
          );
        })
        .filter(Boolean)}
      {Object.keys(dynamicTabs).map((tab) => (
        <Tab
          key={tab}
          icon={<SvgIconStyle src={iconsMap[tab] ?? LIST_ICON} />}
          label={getTabLabel(tab)}
          value={tab}
        />
      ))}
    </TabList>
  );
}

export { ClaimViewTabs };
