import Stack from '@mui/material/Stack';
import type { FeedbackFormValues } from 'pages/Claim/types';
import {
  TypographyWithTranslation,
  useTranslationRoot,
} from 'components/with-translation';
import { toast } from 'components/toast';
import { FeedbackForm } from 'pages/Claim/FeedbackForm';
import { postClaimFeedback } from 'state/queries/claims';

interface FeedbackProps {
  claimId: string;
}

function Feedback({ claimId }: FeedbackProps) {
  const { t } = useTranslationRoot();

  function handleSubmit(values: FeedbackFormValues) {
    const { reason, description } = values;

    toast.promise(postClaimFeedback(claimId, reason, description), {
      loading: t('feedback.submitFeedbackLoading'),
      success: t('feedback.submitFeedbackSuccess'),
      error: (error) =>
        t('feedback.submitFeedbackFail', { errorMsg: error.detail }),
    });
  }

  return (
    <Stack sx={{ p: 2 }}>
      <TypographyWithTranslation
        i18nKey="feedback.title"
        variant="overline"
        sx={{
          borderBottom: ({ palette }) => `1px solid ${palette.grey[500_24]}`,
          pb: 2,
          mb: 2,
        }}
      />
      <FeedbackForm onSubmit={handleSubmit} />
    </Stack>
  );
}

export { Feedback };
