import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { TypographyWithTranslation } from 'components/with-translation';

interface ReceivedInfoProps {
  properties: { [key: string]: string } | null;
}

function ReceivedInfo({ properties }: ReceivedInfoProps) {
  return (
    <Stack spacing={1} sx={{ p: 2 }}>
      <TypographyWithTranslation
        i18nKey="receivedInfo.title"
        variant="overline"
      />

      <Box>
        {properties ? (
          Object.entries(properties).map(([key, value]) => (
            <Typography key={key} variant="body2">
              <Typography
                component="span"
                variant="body2"
                sx={{ fontWeight: 'bold' }}
              >
                {`${key}: `}
              </Typography>
              <Typography component="span" variant="body2">
                {String(value)}
              </Typography>
            </Typography>
          ))
        ) : (
          <TypographyWithTranslation
            i18nKey="receivedInfo.noProperties"
            variant="body2"
          />
        )}
      </Box>
    </Stack>
  );
}

export { ReceivedInfo };
