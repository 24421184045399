import type { ReactNode } from 'react';
import { createContext, useEffect, useState } from 'react';
import { getCurrentUser, fetchAuthSession } from 'aws-amplify/auth';
import { Trans } from 'react-i18next';
import { Hub } from '@aws-amplify/core';
import { Navigate } from 'react-router-dom';
import Link from '@mui/material/Link';
import rollbar from 'rollbar-config';
import api from 'api/api';
import loginRoute from 'pages/Login/login.route';
import { TypographyWithTranslation } from 'components/with-translation';
import { useBroadcastChannel } from 'components/customHooks/useBroadcastChannel';
import { toast } from 'components/toast';
import { AuthTimeoutWarning } from 'components/AuthTimeoutWarning';
import { generalConfig } from 'config';
import { appBC } from 'components/customHooks/useBroadcastChannel';

import Splash from './Splash';
import { getSessionTimestamp, logout } from './logout';

interface AuthenticatorProps {
  children: ReactNode;
}

Hub.listen('auth', async ({ payload }) => {
  switch (payload.event) {
    case 'signedIn':
      localStorage.setItem(
        generalConfig.sessionTimestampKey,
        `${Date.now().toString()}.${payload.data.userId}`
      );
      break;
    case 'signedOut':
      localStorage.removeItem(generalConfig.sessionTimestampKey);
      api.unauthenticate();
      window.location.reload();
      break;
    // case 'signInWithRedirect_failure':
    //   rollbar.error('An error has occurred during the OAuth flow.');
    //   break;
    // case 'tokenRefresh_failure':
    //   rollbar.error(
    //     'An error has occurred during the token refresh.',
    //     payload.data
    //   );
    //   break;
  }
});

const FIVE_MINUTES = 5 * 60 * 1000;

const AuthenticationContext = createContext<NonNullable<unknown>>({});

function ProtectedRoute({ children }: AuthenticatorProps) {
  const [user, setUser] = useState<{ userId: string; username: string } | null>(
    null
  );
  const [payload, setPayload] = useState<any>({});
  const [idToken, setIdToken] = useState<any>({});
  const [loading, setLoading] = useState(true);

  // get user
  async function getUser() {
    if (user) {
      setLoading(false);
      return;
    }

    try {
      const authUser = await getCurrentUser();
      const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};

      setUser(authUser);

      if (accessToken) {
        api.authenticate(accessToken.toString());
        setPayload(accessToken.payload);
        rollbar.configure({ payload: { person: { id: authUser.userId } } });
      }

      if (idToken) {
        setIdToken(idToken.payload);
      }

      setLoading(false);
    } catch (err) {
      // rollbar.critical(
      //   'AWS Amplify getCurrentUser fail',
      //   err as unknown as Error
      // );
      setLoading(false);
    }
  }

  // initialise auth
  useEffect(() => {
    void getUser();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // show session expiration warning toast
  useEffect(() => {
    const [loginTime, userId] = getSessionTimestamp();

    const currentTime = Date.now();
    const timeDifference = currentTime - (loginTime || 0);
    const sessionDuration = generalConfig.sessionExpirationWarningDelay;
    let delay = sessionDuration - FIVE_MINUTES - timeDifference;

    if (delay < 0) {
      delay = 0; // If the delay is negative, set it to 0
    }

    let timeout: NodeJS.Timeout | null = null;

    const showToast = () => {
      toast.warning(
        <Trans
          i18nKey="sessionExpiring"
          components={{
            anchor: (
              <Link
                onClick={logout}
                sx={{
                  cursor: 'pointer',
                }}
              />
            ),
          }}
        />,
        {
          id: 'session-expiration-warning',
          dismissBtn: true,
          duration: Infinity,
        }
      );
    };

    // if user is signed in then activate countdown
    if (userId === user?.userId) {
      // if delay is more than 5 minutes before expiration time, show toast
      if (delay) {
        timeout = setTimeout(showToast, delay);
        // if delay is less than 5 minutes before expiration time, show toast immediately
      } else if (loginTime && currentTime < loginTime + sessionDuration) {
        showToast();
      }
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [user]);

  // use broadcast channel api to sign user out of all open tabs
  useBroadcastChannel(async (event) => {
    const { data } = event;

    if (data === 'signOut') {
      api.unauthenticate();
      window.location.reload();
    }
  });

  // edge case: if user is signedIn without sessionTimestampKey, sign them out
  async function handleSignOut() {
    appBC.postMessage('signOut');

    try {
      await logout();
      setUser(null);
    } catch (error) {
      rollbar.error('Logout failed', error as unknown as Error);
    }
  }

  if (!loading && user) {
    const sessionTimestamp = localStorage.getItem(
      generalConfig.sessionTimestampKey
    );

    // signout if no sessionTimestampKey
    const noLocalStorageItem = !sessionTimestamp;

    // signout if it's a different user from the one in the sessionTimestampKey
    const [loginTime, userId] = getSessionTimestamp();
    const differentUser = userId !== user.userId;

    // signout if session has expired
    const currentTime = Date.now();
    const sessionDuration = generalConfig.sessionExpirationWarningDelay;
    const outOfTime = loginTime && currentTime > loginTime + sessionDuration;

    // writing this out deliberately to do some more rollbar logging
    if (noLocalStorageItem) {
      rollbar.info(
        'ProtectedRoute: No sessionTimestampKey found in localStorage'
      );
      void handleSignOut();
    }

    if (differentUser) {
      rollbar.info('ProtectedRoute: Different user found in localStorage');
      void handleSignOut();
    }

    if (outOfTime) {
      rollbar.info('ProtectedRoute: Session has expired');
      void handleSignOut();
    }
  }

  if (loading)
    return (
      <Splash>
        <TypographyWithTranslation i18nKey="retrievingUserDetails" />
      </Splash>
    );

  if (!user) {
    return <Navigate to={loginRoute.createPath()} />;
  }

  return (
    <AuthenticationContext.Provider value={{ user, payload, idToken }}>
      {children}
      <AuthTimeoutWarning />
    </AuthenticationContext.Provider>
  );
}

export { ProtectedRoute };
