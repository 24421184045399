import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { TypographyWithTranslation } from 'components/with-translation';
import { ClaimHistory } from 'pages/Claim/ClaimHistory';
import type { Claim } from 'types/Claims';

import type { FilesMap } from './types';

interface AuditLogProps {
  claim: Claim;
  filesMap: FilesMap;
}

function AuditLog(props: AuditLogProps) {
  if (props.claim.auditLog.length) {
    return (
      <Card>
        <Box
          sx={{
            alignItems: 'center',
            backgroundColor: 'grey.50024',
            display: 'flex',
            minHeight: 48,
            px: 2,
          }}
        >
          <TypographyWithTranslation
            i18nKey="auditLog.title"
            variant="subtitle2"
          />
        </Box>
        <Box sx={{ p: 2 }}>
          <ClaimHistory {...props} />
        </Box>
      </Card>
    );
  }

  return null;
}

export { AuditLog };
