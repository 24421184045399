import { useEffect } from 'react';
import type { FallbackProps } from 'react-error-boundary';
import { useRollbar } from '@rollbar/react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {
  ButtonWithTranslation,
  TypographyWithTranslation,
  WithTranslationRoot,
} from 'components/with-translation';
import { COMMON } from 'constants/translation-keys';

function GenericErrorFallback({ error, resetErrorBoundary }: FallbackProps) {
  const rollbar = useRollbar();
  useEffect(() => {
    rollbar.error(error, { errorType: 'Generic' });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <WithTranslationRoot namespace={COMMON}>
      <Box role="alert" sx={{ p: 2 }}>
        <TypographyWithTranslation i18nKey="error" variant="body1" />
        <Typography component="pre" variant="body1">
          {error.message}
        </Typography>
        <ButtonWithTranslation
          onClick={resetErrorBoundary}
          i18nKey="tryAgainButton"
          variant="contained"
          sx={{ mt: 2 }}
        />
      </Box>
    </WithTranslationRoot>
  );
}

export { GenericErrorFallback };
