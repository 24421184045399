import ReactMarkdown from 'react-markdown';
// remark plugins
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
// mui components
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

interface MarkdownProps {
  value: string;
}

function Markdown({ value }: MarkdownProps) {
  return (
    <ReactMarkdown
      components={{
        // typography
        h1: ({ children }) => (
          <Typography variant="h1" gutterBottom>
            {children}
          </Typography>
        ),
        h2: ({ children }) => (
          <Typography variant="h2" gutterBottom>
            {children}
          </Typography>
        ),
        h3: ({ children }) => (
          <Typography variant="h3" gutterBottom>
            {children}
          </Typography>
        ),
        h4: ({ children }) => (
          <Typography variant="h4" gutterBottom>
            {children}
          </Typography>
        ),
        h5: ({ children }) => (
          <Typography variant="h5" gutterBottom>
            {children}
          </Typography>
        ),
        h6: ({ children }) => (
          <Typography variant="h6" gutterBottom>
            {children}
          </Typography>
        ),
        p: ({ children }) => (
          <Typography
            variant="body2"
            sx={{ whiteSpace: 'pre-wrap' }}
            gutterBottom
          >
            {children}
          </Typography>
        ),

        // list
        ul: ({ children }) => <List>{children}</List>,
        ol: ({ children }) => <List>{children}</List>,
        li: ({ children }) => (
          <ListItem>
            <ListItemText primary={children} />
          </ListItem>
        ),

        // table
        table: ({ children }) => <Table>{children}</Table>,
        thead: ({ children }) => <TableHead>{children}</TableHead>,
        tbody: ({ children }) => <TableBody>{children}</TableBody>,
        tr: ({ children }) => <TableRow hover>{children}</TableRow>,
        td: ({ children }) => <TableCell>{children}</TableCell>,
        th: ({ children }) => <TableCell>{children}</TableCell>,

        // misc
        hr: () => <Divider />,
        a: ({ children, href }) => (
          <Link href={href} target="_blank" rel="noopener noreferrer">
            {children}
          </Link>
        ),
      }}
      rehypePlugins={[rehypeRaw]}
      remarkPlugins={[remarkGfm]}
    >
      {value}
    </ReactMarkdown>
  );
}

export { Markdown };
