import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import type { ClaimObservation } from 'types/Claims';
import type { DocumentContent } from 'types/Documents';
import {
  TypographyWithTranslation,
  useTranslationRoot,
} from 'components/with-translation';
import { SvgIconStyle } from 'components/SvgIconStyle';
import { HiddenInput } from 'components/form/HiddenInput';
import { CanvasTool } from 'components/CanvasTool';
import { useFeatureFlag } from 'components/customHooks/useFeatureFlag';
import { toast } from 'components/toast';
import { ARROW_DOWNWARD_ICON } from 'constants/public-icons';
import { lockClaim, useValidateClaim } from 'state/queries/claims';
import { isHighLevelItem, isLineLevelItem } from 'state/selectors/documents';
import { getIsClaimAwaitingReview } from 'state/selectors/claims';
import { pipe } from 'utils/pipe';
import { preWarmImageCache } from 'utils/image-cache.ts';
import { transformKeys } from 'utils/object';
import { toCamelCase } from 'utils/string';
import rollbar from 'rollbar-config.ts';
import {
  getEventTimestamp,
  updateDataLayer,
  updateWindowPerformanceObject,
} from 'analytics/utils.ts';
import {
  ENRICHMENT_VALIDATION_END,
  ENRICHMENT_VALIDATION_START,
} from 'analytics/events.ts';

import type {
  ContextState,
  DocumentContentWithId,
  FormValues,
  OnSubmitStateAndDataType,
  ValidationObservation,
  ValidationResponse,
} from './types';
import {
  getGroupedLineItems,
  isForcefullyHiddenItem,
  isHiddenByClaimType,
} from './utils.ts';
import {
  getDefaultValues,
  normaliseData,
  transformValueFromFormToState,
  unmakeFieldsList,
} from './state.ts';
import { useEnrichmentToolContext } from './useEnrichmentToolContext.tsx';
import { FormHeader } from './FormHeader.tsx';
import { ResizableBox } from './ResizableBox.tsx';
import { Fields } from './Fields.tsx';

interface EnrichmentFormProps {
  observations: ClaimObservation[];
  onSubmit: (observations: ClaimObservation[]) => void;
}

function EnrichmentForm({ observations, onSubmit }: EnrichmentFormProps) {
  const { t } = useTranslationRoot();
  const enableToolDebug = useFeatureFlag('enableDebugMode');
  const enableThumbnails = useFeatureFlag('enableReviewToolThumbnails');
  const { claim, claimType, currentUser, setState, state } =
    useEnrichmentToolContext();
  const { fieldsListId, lists } = state;
  const validateClaim = useValidateClaim(claim.id);

  const accordionRef = useRef<HTMLDivElement>(null);
  const bodyRef = useRef<HTMLDivElement>(null);

  const isAwaitingReview = getIsClaimAwaitingReview(claim);

  const normalisedData = useMemo(
    () => normaliseData(observations, claim.clientClaimId),
    [claim.clientClaimId, observations]
  );
  const defaultValues = useMemo(
    () => getDefaultValues(normalisedData),
    [normalisedData]
  );
  const methods = useForm<FormValues>({
    defaultValues,
  });
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  useEffect(function initialiseState() {
    setState({
      __originalState: normalisedData,
      ...normalisedData,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let interval: string | number | NodeJS.Timer | undefined;

    if (isAwaitingReview) {
      interval = setInterval(() => {
        if (enableToolDebug) {
          rollbar.info('Locking claim after 9 minutes', { claimId: claim.id });
        }
        // calling this direction so that the state is not mutated
        void lockClaim(claim.id);
      }, 60000 * 9);

      return () => {
        clearInterval(interval);
      };
    } else {
      if (interval) {
        clearInterval(interval);
      }

      return () => {
        if (interval) {
          clearInterval(interval);
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [claim.id, isAwaitingReview]);

  useLayoutEffect(() => {
    const accordion = accordionRef.current;

    if (accordion && bodyRef.current) {
      const rect = accordion.getBoundingClientRect();
      const offset = 286 + rect.height + 20;
      bodyRef.current.style.height = `calc(100vh - ${offset}px)`;
    }
  }, [fieldsListId]);

  if (fieldsListId === '') {
    return null;
  }

  const list = lists[fieldsListId];
  const fields = list.map((id) => state.fields[id]);

  const hasLineItems = fields.some(isLineLevelItem);
  const hiddenItems = fields.filter(
    (field) =>
      isHighLevelItem(field) ||
      isForcefullyHiddenItem(claimType)(field) ||
      isHiddenByClaimType(claimType)(field)
  );
  const notHiddenItems = fields.filter(
    (field) =>
      !isHighLevelItem(field) &&
      !isForcefullyHiddenItem(claimType)(field) &&
      !isHiddenByClaimType(claimType)(field)
  );

  const renderHiddenItems = (items: DocumentContentWithId[]) => {
    if (items.length) {
      return items.map(({ id }) => <HiddenInput key={id} name={id} />);
    }

    return null;
  };

  const onFormSubmit = async (data: FormValues) => {
    const getOriginalList = (state: ContextState) => {
      const { __originalState, fieldsListId } = state;
      return __originalState.lists[fieldsListId];
    };

    const getCurrentList = (state: ContextState) => {
      const { lists, fieldsListId } = state;
      return lists[fieldsListId];
    };

    const getFieldId =
      (state: ContextState) => (sproutaiKey: string) => (id: string) => {
        const item = state.fields[id];
        return item.sproutaiKey === sproutaiKey;
      };

    // const markDeletedLineItems = ({
    //   state,
    //   formData
    // }: OnSubmitStateAndDataType) => {
    //   const originalList = getOriginalList(state);
    //   const currentList = getCurrentList(state);
    //   const filteredIds = originalList.filter(
    //     (id) => !currentList.includes(id)
    //   );
    //   const newState = { ...state };
    //
    //   filteredIds.forEach((id) => {
    //     newState.fields[id].deleted = true;
    //   });
    //
    //   return {
    //     state: newState,
    //     formData
    //   };
    // };

    // if treat_manually is false, we need to set doubts to empty string
    const handleTreatManuallyAndDoubts = ({
      state,
      formData,
    }: OnSubmitStateAndDataType) => {
      const newFormData = { ...formData };
      const list = getCurrentList(state);
      const findFieldId = getFieldId(state);
      const treatManuallyId = list.find(findFieldId('treat_manually'));
      const doubtsId = list.find(findFieldId('doubts'));

      if (treatManuallyId && doubtsId) {
        const treatManuallyValue = formData[treatManuallyId] as boolean;
        if (!treatManuallyValue) {
          newFormData[doubtsId] = '';
        }
      }

      return {
        state,
        formData: newFormData,
      };
    };

    const handlePreReimbursedAndSelfFundingTaxId = ({
      state,
      formData,
    }: OnSubmitStateAndDataType) => {
      const newFormData = { ...formData };
      const list = getCurrentList(state);
      const findFieldId = getFieldId(state);
      const preReimbursedId = list.find(findFieldId('pre_reimbursed'));
      const selfFundingTaxIdId = list.find(findFieldId('self_funding_tax_id'));

      if (preReimbursedId && selfFundingTaxIdId) {
        const preReimbursementValue = formData[preReimbursedId] as boolean;
        if (!preReimbursementValue) {
          newFormData[selfFundingTaxIdId] = '';
        }
      }

      return {
        state,
        formData: newFormData,
      };
    };

    const updateFormValuesIntoState = ({
      state,
      formData,
    }: OnSubmitStateAndDataType) => {
      const newState = { ...state };

      Object.entries(formData).forEach(([key, value]) => {
        const item = newState.fields[key];
        const transformer = transformValueFromFormToState(item.valueType);
        newState.fields[key].value = transformer(value) as string;
      });

      return {
        state: newState,
        formData,
      };
    };

    const fixLineIdx = ({ state, formData }: OnSubmitStateAndDataType) => {
      const data = { ...state.fields };
      const items = Object.values(data);
      const lineItems = items.filter(isLineLevelItem);
      const lineGroups = getGroupedLineItems(lineItems);

      // Assign new sequential lineIdx
      let newLineIdx = 0;
      const newLineIdxes = Object.keys(lineGroups)
        .sort((a, b) => parseInt(a) - parseInt(b))
        .reduce((acc, oldLineIdx) => {
          acc[oldLineIdx].forEach((item) => {
            item.lineIdx = newLineIdx;
          });
          newLineIdx++;

          return acc;
        }, lineGroups);

      const dict = Object.values(newLineIdxes).reduce(
        (acc, items) => {
          const obj = { ...acc };

          items.forEach((item) => {
            // @ts-ignore
            obj[item.id] = item.lineIdx;
          });

          return obj;
        },
        {} as Record<string, number>
      );

      const newFields = Object.entries(data).reduce((acc, [key, item]) => {
        const lineIdx = dict[item.id];

        if (lineIdx !== undefined) {
          // @ts-ignore
          acc[key] = {
            ...item,
            lineIdx,
          };
        }

        return acc;
      }, data);

      return {
        state: {
          ...state,
          fields: newFields,
        },
        formData,
      };
    };

    const updateObservations = ({
      state,
      formData,
    }: OnSubmitStateAndDataType) => {
      const newState = { ...state };
      const originalList = getOriginalList(state);
      const currentList = getCurrentList(newState);
      const mergedList = [...originalList, ...currentList];
      const uniqueList = [...new Set(mergedList)];
      const newFields = uniqueList.map((id) => newState.fields[id]);
      const listId = unmakeFieldsList(newState.fieldsListId);
      newState.observations[listId].content.fields = newFields;

      return {
        state: newState,
        formData,
      };
    };

    setIsFormSubmitting(true);

    const result = pipe(
      handleTreatManuallyAndDoubts,
      handlePreReimbursedAndSelfFundingTaxId,
      updateFormValuesIntoState,
      fixLineIdx,
      updateObservations
    )({ state, formData: data });

    const getObservations = (state: ContextState) => {
      const listIds = state.lists.observations;
      return listIds.map((id) => state.observations[id]);
    };

    const getValidationResult = (response: ValidationResponse) => {
      const { results } = response;
      const rule = results.find((result) => result.ruleId === 'AllFieldsValid');

      if (rule) {
        return rule.passed;
      }

      // if no validation result just assumed it's passed and send the results to IDP
      return true;
    };

    const updateFieldsFromValidation = (response: ValidationResponse) => {
      const getObservations = (response: ValidationResponse) =>
        response.observations;
      const getObservation = (observations: ValidationObservation[]) => {
        const listId = unmakeFieldsList(state.fieldsListId);
        return observations.find(
          (observation) => observation.observationId === listId
        );
      };
      const getObservationFields = (observation: ValidationObservation) =>
        observation.fields;
      const getItemLevel = (field: DocumentContent) => field.level;
      const matchDocumentItem = (
        field: DocumentContent,
        item: DocumentContent
      ) => field.sproutaiKey === item.sproutaiKey;
      const matchLineItem = (field: DocumentContent, item: DocumentContent) =>
        field.sproutaiKey === item.sproutaiKey &&
        field.lineIdx === item.lineIdx;
      const selectMatcher = (level: string) =>
        level === 'item' ? matchLineItem : matchDocumentItem;

      const observations = getObservations(response);
      const observation = getObservation(observations);

      if (observation) {
        const fields = getObservationFields(observation);
        const newState = { ...state };
        const list = getCurrentList(newState);
        const newResources = list.reduce((acc, key) => {
          const item = state.fields[key];
          const level = getItemLevel(item);
          const field = fields.find((field) =>
            selectMatcher(level)(field, item)
          );

          if (field) {
            const valid = field.valid;

            acc[key] = {
              ...item,
              valid,
            };
          }

          return acc;
        }, state.fields);

        setState((prevState) => ({
          ...prevState,
          fields: {
            ...prevState.fields,
            ...newResources,
          },
        }));
      }
    };

    const lockClaimAgain = async () => {
      if (
        claim.locked &&
        claim.lastLockedBy === currentUser.email &&
        getIsClaimAwaitingReview(claim)
      ) {
        await lockClaim(claim.id);
      } else {
        throw new Error('Claim is not locked by the current user');
      }
    };

    const observations = getObservations(result.state);

    try {
      await lockClaimAgain();
    } catch (error) {
      if (enableToolDebug) {
        rollbar.info('Failed to lock claim onSubmit', {
          stage: claim.stage,
          claimId: claim.id,
          locked: claim.locked,
        });
      }
      toast.error(t('enrichmentTool.lockClaimError') as string);
      setIsFormSubmitting(false);
      return;
    }

    try {
      updateWindowPerformanceObject(ENRICHMENT_VALIDATION_START);
      const validationResult = await validateClaim.mutateAsync(observations);
      updateDataLayer({
        event: ENRICHMENT_VALIDATION_END,
        performance:
          (Date.now() - getEventTimestamp(ENRICHMENT_VALIDATION_START)) / 1000,
        claimId: claim.id,
      });

      const transformedResult = transformKeys(validationResult, toCamelCase);
      const isValid = getValidationResult(transformedResult);

      if (isValid) {
        onSubmit(
          // remove the id from the fields
          observations.map((observation) => {
            if (observation.content?.fields?.length) {
              // @ts-ignore
              if (observation.content.fields.some((field) => field?.id)) {
                return {
                  ...observation,
                  content: {
                    ...observation.content,
                    fields: observation.content.fields.map(
                      // @ts-ignore
                      ({ id, ...rest }) => rest
                    ),
                  },
                };
              }
            }
            return observation;
          })
        );
        return;
      } else {
        toast.error(t('enrichmentTool.validationFail'));
        updateFieldsFromValidation(transformedResult);
      }
    } catch (error) {
      toast.error(
        t('enrichmentTool.validationCatchFail', {
          errorMsg: (error as unknown as { reason_message: string })
            .reason_message,
        })
      );
    }
    setIsFormSubmitting(false);
  };

  const handleKeyDown = (event: any) => {
    // Enter key
    if (event.keyCode === 13) {
      event.preventDefault();
    }

    // CTRL key and Enter key press together then submit
    if (event.ctrlKey && event.keyCode === 13) {
      event.preventDefault();
      methods.handleSubmit(onFormSubmit)();
    }
  };

  // preload images
  preWarmImageCache(normalisedData.urls);
  const reasonMessages = claim.metadata?.reasonDescriptions as string[];

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(onFormSubmit)}
        onKeyDown={handleKeyDown}
      >
        <Stack>
          <FormHeader isFormSubmitting={isFormSubmitting} />

          <Stack direction={{ md: 'row' }}>
            <ResizableBox>
              <Stack>
                <div ref={accordionRef}>
                  <Accordion
                    defaultExpanded
                    disableGutters
                    elevation={0}
                    square
                    sx={{
                      borderRadius: '0 !important',
                      boxShadow: 'none !important',
                      borderBottom: ({ palette }) =>
                        `1px solid ${palette.divider}`,
                    }}
                    onChange={(_, isOpen) => {
                      if (bodyRef.current && accordionRef.current) {
                        if (isOpen) {
                          const rect =
                            accordionRef.current.getBoundingClientRect();
                          const offset = 320 + rect.height;
                          bodyRef.current.style.height = `calc(100vh - ${offset}px)`;
                        } else {
                          bodyRef.current.style.height = 'calc(100vh - 327px)';
                        }
                      }
                    }}
                  >
                    <AccordionSummary
                      expandIcon={
                        <SvgIconStyle
                          src={ARROW_DOWNWARD_ICON}
                          width={18}
                          height={18}
                        />
                      }
                      sx={{
                        backgroundColor: 'background.neutral',
                        minHeight: 40,
                        pl: 1,
                        position: 'relative',
                        '&:before': {
                          // little accent to show a little box to represent resizing
                          content: '""',
                          backgroundColor: 'grey.500',
                          display: 'block',
                          height: 20,
                          width: 3,

                          position: 'absolute',
                          right: -2,
                          top: '50%',
                          transform: 'translateY(-50%)',
                          zIndex: 1,
                        },
                        '& .MuiAccordionSummary-content': {
                          my: 1,
                        },
                      }}
                    >
                      <TypographyWithTranslation
                        i18nKey="enrichmentTool.reasonMessage"
                        variant="subtitle1"
                        sx={{ fontSize: 12 }}
                      />
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        pb: 1,
                      }}
                    >
                      <Stack spacing={2}>
                        {reasonMessages?.length ? (
                          <Stack>
                            <List dense disablePadding>
                              {reasonMessages.map((reasonMessage, index) => (
                                <ListItem key={index} disablePadding>
                                  <ListItemText
                                    primary={reasonMessage}
                                    primaryTypographyProps={{
                                      sx: { fontSize: 12 },
                                    }}
                                  />
                                </ListItem>
                              ))}
                            </List>
                          </Stack>
                        ) : null}
                      </Stack>
                    </AccordionDetails>
                  </Accordion>
                </div>
                <Box
                  component="header"
                  sx={{
                    backgroundColor: 'background.neutral',
                    height: 40,
                    px: 1,
                  }}
                />
                {renderHiddenItems(hiddenItems)}
                <Box
                  ref={bodyRef}
                  sx={{
                    overflowY: 'auto',
                    height: 'calc(100vh - 327px)',
                  }}
                >
                  <Fields
                    hasLineItems={hasLineItems}
                    items={notHiddenItems}
                    list={list}
                  />
                </Box>
              </Stack>
            </ResizableBox>
            <Box
              sx={{
                order: { md: 2, lg: 3 },
                position: 'relative',
                width: 1,
                minWidth: '20%',
              }}
            >
              {state.urls ? (
                <CanvasTool
                  urls={state.urls}
                  useCtrlZoom
                  hasThumbnails={
                    (enableThumbnails &&
                      Object.keys(state.urls).length > 1) as true
                  }
                  thumbnails={Object.entries(state.urls).map(([key, url]) => ({
                    documentId: key,
                    documentGroup: 'enrichment',
                    url,
                  }))}
                />
              ) : (
                <Box
                  sx={{
                    p: 2,
                  }}
                >
                  <TypographyWithTranslation i18nKey="enrichmentTool.noUrls" />
                </Box>
              )}
            </Box>
          </Stack>
        </Stack>
      </form>
    </FormProvider>
  );
}

export { EnrichmentForm };
