import type { ReactNode } from 'react';
import type { SxProps } from '@mui/material/styles';
import type { Theme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { TypographyWithTranslation } from 'components/with-translation';
import { AWAITING_REVIEW } from 'constants/document-stage';
import { Attachment } from 'pages/Claim/Attachment';
import { AutomationRule } from 'pages/Claim/AutomationRule';
import { useGetClaimDocuments } from 'state/queries/claims';
import {
  getIsClaimError,
  getIsClaimAwaitingInfo,
  getIsClaimProcessing,
  getIsClaimCompleted,
} from 'state/selectors/claims';
import { convertArrayToObject } from 'utils/array';
import type { Claim, ClaimsValidateResult } from 'types/Claims';

import { ValidationResultV2 } from './ValidationResultV2';

interface SummaryProps {
  claim: Claim;
}

interface SummaryContainerProps {
  children: ReactNode;
  sx?: SxProps<Theme>;
  titleI18nKey: string;
}

function Explanation({ content }: { content: string | string[] }) {
  return (
    <SummaryContainer
      sx={{
        pb: 1.5,
      }}
      titleI18nKey="summaryTable.message"
    >
      {Array.isArray(content) ? (
        content.map((statusDetail, index) => (
          <Typography
            key={index}
            variant="body1"
            sx={{ whiteSpace: 'pre-line' }}
          >
            {statusDetail}
          </Typography>
        ))
      ) : (
        <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>
          {content}
        </Typography>
      )}
    </SummaryContainer>
  );
}

function SummaryContainer({
  children,
  titleI18nKey,
  ...props
}: SummaryContainerProps) {
  return (
    <Box {...props}>
      <TypographyWithTranslation i18nKey={titleI18nKey} variant="overline" />
      {children}
    </Box>
  );
}

function Summary({ claim }: SummaryProps) {
  const {
    achTasksResults,
    clientRecommendation,
    documents: documentsList,
    files: filesList,
    id,
    message,
    validationResults,
  } = claim;
  const { data: documents, isSuccess } = useGetClaimDocuments(id);
  let documentsMap = {};
  const hasDoneStatus = getIsClaimCompleted(claim);

  if (isSuccess) {
    documentsMap = convertArrayToObject(documents, 'id');
  }

  let explanationMarkup;
  if (message) {
    explanationMarkup = <Explanation content={message} />;
  } else if (clientRecommendation) {
    const recommendations = Array.isArray(clientRecommendation)
      ? clientRecommendation.map(({ statusDetail }) => statusDetail)
      : [clientRecommendation.statusDetail];

    explanationMarkup = <Explanation content={recommendations} />;
  }

  const attachmentsMarkup = isSuccess ? (
    <SummaryContainer
      sx={{
        borderTop: ({ palette }) => `1px solid ${palette.grey[300]}`,
        pt: 1.5,
      }}
      titleI18nKey="summaryTable.attachments"
    >
      <Box sx={{ display: 'flex', marginTop: 1 }}>
        <Stack spacing={1}>
          {documentsList.map(({ documentId, stage }) => (
            <Attachment
              key={documentId}
              claimId={claim.id}
              documentId={documentId}
              documentsMap={documentsMap}
              hasHilStatus={stage === AWAITING_REVIEW}
              hasDoneStatus={hasDoneStatus}
              stage={stage}
            >
              {documentId}
            </Attachment>
          ))}
          {filesList.map(({ fileId, originalFileName }) => (
            <Attachment claimId={claim.id} key={fileId}>
              {originalFileName}
            </Attachment>
          ))}
        </Stack>
      </Box>
    </SummaryContainer>
  ) : null;

  let markup = (
    <>
      {explanationMarkup}
      <SummaryContainer
        sx={{
          borderTop: ({ palette }) => `1px solid ${palette.grey[300]}`,
          py: 1.5,
        }}
        titleI18nKey="summaryTable.automationRules"
      >
        <Stack
          sx={{
            gap: 2,
            pt: 1,
          }}
        >
          {validationResults?.length
            ? validationResults.map((result, index) => (
                <ValidationResultV2
                  key={`${result.ruleId}${index}`}
                  validationResult={result as ClaimsValidateResult}
                />
              ))
            : achTasksResults?.length
              ? achTasksResults.map((rule, index) => (
                  <AutomationRule key={`${rule.taskId}${index}`} rule={rule} />
                ))
              : null}
        </Stack>
      </SummaryContainer>
      {attachmentsMarkup}
    </>
  );

  if (getIsClaimAwaitingInfo(claim)) {
    markup = (
      <TypographyWithTranslation
        i18nKey="summaryTable.awaitingInfo"
        variant="body2"
      />
    );
  }

  if (getIsClaimError(claim)) {
    markup = (
      <>
        {explanationMarkup}
        {attachmentsMarkup}
      </>
    );
  }

  if (getIsClaimProcessing(claim)) {
    markup = (
      <>
        {explanationMarkup}
        {attachmentsMarkup}
      </>
    );
  }

  return <Stack>{markup}</Stack>;
}

export { Summary };
