import { Dispatch, SetStateAction, useState } from 'react';
import type { Canvas as CanvasType, FabricObject } from 'fabric';
import { useCallback, useEffect, useRef } from 'react';
import { FabricImage, FabricText, Group, Rect } from 'fabric';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import { useTranslationRoot } from 'components/with-translation.tsx';
import { useCarousel } from 'components/customHooks/useCarousel.ts';
import { GalleryCollapsibleThumbnails } from 'components/GalleryCollapsibleThumbnails.tsx';
import { COMMON } from 'constants/translation-keys.ts';
import { wrap } from 'utils/math.ts';

import { Canvas } from './Canvas.tsx';
import {
  ADDITIONAL_CROP_SIZE,
  getBoundingBox,
  getImageList,
  getObjectAngle,
  zoomToCrop,
} from './utils.ts';
import { Controls } from './Controls.tsx';
import { ImageNavigation } from './ImageNavigation.tsx';
import { useEventListeners } from './useEventListeners.ts';
import { useResizeCanvas } from './useResizeCanvas.ts';

interface BaseProps {
  currentPage?: number;
  crop?: CropState; // crop data to draw box on top of image
  imageLoadFinishEvent?: () => void; // for analytics
  onImageLoadError?: (error: Error) => void; // for analytics or error logging
  resetCrop?: VoidFunction;
  setPage?: Dispatch<SetStateAction<number>>;
  showFullHeight?: boolean; // don't downsize portrait image to fit within the container
  stickyControls?: boolean;
  stickyControlsTop?: number;
  urls: Urls;
  useCtrlZoom?: boolean; // enable ctrl + mouse wheel zoom, for AdvCare mainly
}

export type Urls = { [key: string]: string } | string[];

interface WithThumbnailsProps extends BaseProps {
  hasThumbnails: true;
  thumbnails: { documentId: string; documentGroup: string; url: string }[];
  order?: {
    canvas: 0 | 1;
    thumbnails: 0 | 1;
  };
}

interface WithoutThumbnailsProps extends BaseProps {
  hasThumbnails?: false;
  thumbnails?: undefined;
  order?: undefined;
}

type CanvasToolProps = WithThumbnailsProps | WithoutThumbnailsProps;

export type CropState = {
  boundingBox: BoundingBox | undefined;
  pageIdx: number;
  valid: boolean;
};

type BoundingBoxCoordinate = [number, number];

export type BoundingBox = {
  bottomLeft: BoundingBoxCoordinate;
  bottomRight: BoundingBoxCoordinate;
  topLeft: BoundingBoxCoordinate;
  topRight: BoundingBoxCoordinate;
};

function CanvasTool({
  currentPage = 0,
  crop,
  hasThumbnails,
  imageLoadFinishEvent,
  onImageLoadError,
  resetCrop,
  setPage,
  showFullHeight,
  thumbnails,
  urls,
  useCtrlZoom,
  order = {
    canvas: 0,
    thumbnails: 1,
  },
}: CanvasToolProps) {
  const { t } = useTranslationRoot(COMMON);
  const { palette } = useTheme();

  // refs
  const ref = useRef<CanvasType>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const imgRef = useRef<FabricImage | undefined>(undefined);
  const rectRef = useRef<Rect | undefined>(undefined);
  const prevPage = useRef<number>(currentPage);

  // hooks
  const [hasLoaded, setHasLoaded] = useState(false);
  const { init: initEventListeners } = useEventListeners({
    useCtrlZoom,
  });

  // handle images
  const imageList = getImageList(urls);
  const noOfImages = imageList.length;
  const { page, nextSlide, previousSlide, setCarouselPage } = useCarousel({
    noOfImages,
  });

  const loadImage = (url: string) =>
    FabricImage.fromURL(url, undefined, {
      hasBorders: false,
      hasControls: false,
      snapAngle: 90,
    });

  const addErrorText = ({
    canvas,
    error,
  }: {
    canvas: CanvasType;
    error: Error;
  }) => {
    onImageLoadError?.(error as Error);

    const text = new FabricText(t('canvas.failedToLoadImage'), {
      top: 8,
      left: 8,
      fontFamily: 'Public Sans,sans-serif',
      fontSize: 16,
    });
    canvas.add(text);
  };

  const addImageAndAdjustCanvas = ({
    canvas,
    img,
    hasAddImage = true,
    containerRect,
  }: {
    canvas: CanvasType;
    img: FabricImage | FabricObject;
    hasAddImage?: boolean;
    containerRect: DOMRect;
  }) => {
    const windowHeight = window.innerHeight;
    const maxHeight = windowHeight - containerRect.top - 40; // 40 for padding

    if (showFullHeight) {
      const scale = containerRect.width / img.width;

      canvas.setDimensions({
        width: containerRect.width,
        height: img.height * scale,
      });

      img.scale(scale);
    } else {
      const isPortrait = img.height > img.width;
      const scale = isPortrait
        ? maxHeight / img.height
        : canvas.width / img.width;
      const isTallerThanContainer = img.height * scale > maxHeight;

      if (isTallerThanContainer) {
        canvas.setDimensions({
          width: containerRect.width,
          height: maxHeight,
        });
      } else {
        canvas.setDimensions({
          width: containerRect.width,
          height: img.height * scale,
        });
      }
      img.scale(scale);
    }

    if (hasAddImage) {
      canvas.add(img);
    }
    canvas.centerObject(img);
    canvas.setActiveObject(img);
  };

  const onLoad = useCallback(async (canvas: CanvasType) => {
    const getCurrentImage = () => urls[currentPage];

    if (containerRef.current) {
      initEventListeners(canvas);

      try {
        const oImg = await loadImage(getCurrentImage());
        imageLoadFinishEvent?.();

        imgRef.current = oImg;

        if (oImg) {
          addImageAndAdjustCanvas({
            canvas,
            img: oImg,
            containerRect: containerRef.current.getBoundingClientRect(),
          });
        }
      } catch (error) {
        addErrorText({ canvas, error: error as unknown as Error });
      }

      containerRef.current?.style?.setProperty('opacity', '1');
    }

    setHasLoaded(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCanvas = () => ref.current as CanvasType;
  const getImage = () => imgRef.current as FabricImage;

  const withResetCrop =
    (fn: (...args: any) => void, updatePage?: VoidFunction) =>
    (...args: any) => {
      resetCrop?.();
      updatePage?.();
      fn(...args);
    };

  const previousSlideAndResetCrop = withResetCrop(previousSlide, () =>
    setPage?.((prevPage) => wrap(0, noOfImages, prevPage - 1))
  );
  const nextSlideAndResetCrop = withResetCrop(nextSlide, () =>
    setPage?.((prevPage) => wrap(0, noOfImages, prevPage + 1))
  );
  const handleThumbnailClick = withResetCrop(setCarouselPage);

  // update image on page change
  useEffect(() => {
    const init = async () => {
      const canvas = getCanvas();

      if (canvas && containerRef.current) {
        canvas.clear();
        canvas.renderAll();

        // reset zoom and pan
        canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);

        try {
          const img = await loadImage(urls[page]);
          imgRef.current = img;

          if (img) {
            addImageAndAdjustCanvas({
              canvas,
              img,
              containerRect: containerRef.current.getBoundingClientRect(),
            });
          }

          canvas.renderAll();

          imageLoadFinishEvent?.();
          prevPage.current = page;
          setPage?.(page);
        } catch (error) {
          addErrorText({ canvas, error: error as unknown as Error });
        }
      }
    };

    if (page !== prevPage.current) {
      void init();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, urls]);

  useEffect(() => {
    if (typeof crop?.pageIdx === 'number' && crop?.pageIdx !== currentPage) {
      setCarouselPage(crop.pageIdx);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crop?.pageIdx, currentPage]);

  // handle crop by adding rect to canvas
  useEffect(() => {
    const configureRect = (boundingBox: BoundingBox) => {
      const { bottom, left, right, top } = getBoundingBox(boundingBox);
      const width = right - left;
      const height = bottom - top;
      const additionalWidth = width * ADDITIONAL_CROP_SIZE;
      const additionalHeight = height * ADDITIONAL_CROP_SIZE;

      return new Rect({
        top: top - additionalHeight / 2,
        left: left - additionalWidth / 2,
        width: width + additionalWidth,
        height: height + additionalHeight,
        fill: 'transparent',
        flipX: false,
        flipY: false,
        stroke: crop?.valid ? palette.success.main : palette.error.main,
        strokeWidth: 3,
      });
    };

    const addRect = async (boundingBox: BoundingBox) => {
      if (!ref.current || !imgRef.current) {
        return;
      }

      const canvas = getCanvas();
      const img = getImage();
      const clonedImg = await img.clone();

      clonedImg.set({
        left: 0,
        top: 0,
        scaleX: 1,
        scaleY: 1,
      });

      const currentAngle = getObjectAngle(canvas.getActiveObject()) || 0;
      const scaleY = img.get('scaleY') || 1;
      const scaleX = img.get('scaleX') || 1;

      const rect = configureRect(boundingBox);
      rectRef.current = rect;

      // group img and rect together
      const group = new Group([clonedImg, rect], {
        hasBorders: false,
        hasControls: false,
        left: 0,
        scaleX,
        scaleY,
        snapAngle: 90,
        top: 0,
      });

      if (currentAngle > 0) {
        group.rotate(currentAngle);
      }

      canvas.add(group);

      // remove previous image after adding new one so that the user
      // doesn't see the flash of changing images
      const activeObject = canvas.getActiveObject();

      if (activeObject) {
        canvas.remove(activeObject);
      } else {
        const objects = canvas.getObjects();

        if (
          (objects.length > 1 && objects[0].isType('image')) ||
          objects[0].isType('group')
        ) {
          canvas.remove(objects[0]);
        }
      }

      zoomToCrop(canvas, rect);

      canvas.setActiveObject(group);
    };

    if (
      crop?.boundingBox &&
      typeof crop?.pageIdx === 'number' &&
      currentPage === crop.pageIdx
    ) {
      void addRect(crop.boundingBox);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crop, currentPage]);

  // resize canvas when parent element is resized
  useResizeCanvas({
    callbackFn: (containerRect) => {
      const canvas = getCanvas();

      if (canvas) {
        const img = canvas.getObjects().find((obj) => obj.type === 'image');

        if (img) {
          addImageAndAdjustCanvas({
            canvas,
            img,
            hasAddImage: false,
            containerRect,
          });
        }
      }
    },
    canvas: getCanvas(),
    element: containerRef.current as Element,
    hasLoaded,
    offsetHeight: 0,
  });

  const canvasMarkup = (
    <Box ref={containerRef} sx={{ height: 1, overflow: 'hidden', opacity: 0 }}>
      <Box sx={{ backgroundColor: 'background.neutral', width: 1 }}>
        <Canvas onLoad={onLoad} ref={ref} />
      </Box>
    </Box>
  );

  const markup = hasThumbnails ? (
    <Stack
      direction="row"
      sx={{
        width: 1,
        height: 1,
        overflow: {
          md: 'hidden',
        },
      }}
    >
      <Box
        sx={{
          flex: '1 1 calc(100% - 136px)',
          p: 2,
          overflow: 'hidden',
          order: order.canvas,
        }}
      >
        {canvasMarkup}
      </Box>
      <Box sx={{ flex: '0 0 136px', px: 0, order: order.thumbnails }}>
        <GalleryCollapsibleThumbnails
          imageUrls={thumbnails}
          handleClick={handleThumbnailClick}
          page={page}
        />
      </Box>
    </Stack>
  ) : (
    <Box sx={{ height: 1, p: 2 }}>{canvasMarkup}</Box>
  );

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (hasThumbnails) {
        if (e.key === 'ArrowDown' && (e.metaKey || e.ctrlKey)) {
          e.preventDefault();
          nextSlideAndResetCrop();
        }
        if (e.key === 'ArrowUp' && (e.metaKey || e.ctrlKey)) {
          e.preventDefault();
          previousSlideAndResetCrop();
        }
      }

      if (e.key === 'ArrowLeft' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        previousSlideAndResetCrop();
      }

      if (e.key === 'ArrowRight' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        nextSlideAndResetCrop();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [page]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Stack
        direction="row"
        sx={{
          alignItems: 'center',
          justifyContent: 'space-between',
          background: ({ palette }) => palette.background.neutral,
          px: 1,
        }}
      >
        <div style={{ height: '40px' }}>
          {hasLoaded && <Controls canvas={ref.current as CanvasType} />}
        </div>

        <ImageNavigation
          nextSlide={nextSlideAndResetCrop}
          noOfImages={noOfImages}
          page={page}
          previousSlide={previousSlideAndResetCrop}
        />
      </Stack>
      {markup}
    </>
  );
}

export { CanvasTool };
