import type { ReactNode, SyntheticEvent } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import {
  Outlet,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';
import TabContext from '@mui/lab/TabContext';
import Typography from '@mui/material/Typography';
import { LoadingIcon } from 'components/icons/LoadingIcon';
import { LinkWithRef } from 'components/Link';
import { Page } from 'components/Page';
import { GenericErrorFallback } from 'components/GenericErrorFallback';
import {
  LinkWithTranslation,
  TypographyWithTranslation,
  WithTranslationRoot,
} from 'components/with-translation';
import { Label } from 'components/Label';
import { usePages } from 'components/customHooks/usePages';
import { PAGE_ID, RESOURCE_ID } from 'constants/route-keys';
import {
  CODE_BROWSER,
  DATA_ENRICHMENT_ADD_ICON,
  FEEDBACK_ICON,
  LIST_ICON,
  POLICY_ICON,
  RECOMMEND_ICON,
} from 'constants/public-icons';
import { CLAIM_PAGE } from 'constants/translation-keys';
import {
  AWAITING_REVIEW,
  STAGE_LABEL_COLOR_MAP,
  statusTranslation,
} from 'constants/claims';
import claimsRoute from 'pages/Claims/claims.route';
import { useGetClaim } from 'state/queries/claims';
import {
  getClaimDocuments,
  getClaimStatus,
  getIsClaimCompleted,
  getIsClaimAwaitingInfo,
  getIsClaimProcessing,
} from 'state/selectors/claims';
import {
  useGetCurrentUser,
  useIsSuperAdminRole,
} from 'state/queries/current-user';
import { formatDocumentDate } from 'utils/date';
import claimRoute from 'pages/Claim/claim.route';
import { useGetWidgetsClaimSummary } from 'state/queries/widgets.ts';
import type { ClaimSummaryWidget } from 'types/Widgets.ts';

import { PagesV2 } from './PagesV2';
import { ClaimViewTabs } from './ClaimViewTabs';

export const PAGES = {
  summary: 'summary',
  metadata: 'metadata',
  review: 'review',
  feedback: 'feedback',
} as const;

export const iconsMap = {
  summary: LIST_ICON,
  metadata: CODE_BROWSER,
  review: DATA_ENRICHMENT_ADD_ICON,
  feedback: FEEDBACK_ICON,
  policy: POLICY_ICON,
  recommendations: RECOMMEND_ICON,
};

function Wrapper({ children }: { children: ReactNode }) {
  return (
    <ErrorBoundary FallbackComponent={GenericErrorFallback}>
      <WithTranslationRoot namespace={CLAIM_PAGE}>
        <Page i18nKeyTitle="meta.title">
          <Outlet />
          {children}
        </Page>
      </WithTranslationRoot>
    </ErrorBoundary>
  );
}

function ClaimViewV2() {
  const navigate = useNavigate();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const claimId = params[RESOURCE_ID] as string;
  const pageId = searchParams.get(PAGE_ID);
  const { handleChangePage, isPending, page } = usePages(
    pageId ? pageId : PAGES.summary
  );

  const {
    data: claim,
    isError,
    isPending: isLoading,
    isRefetching,
    refetch,
  } = useGetClaim(claimId);

  const currentUserQuery = useGetCurrentUser();
  const isSuperAdminQuery = useIsSuperAdminRole();
  const allWidgets = useGetWidgetsClaimSummary();
  let dynamicTabs: { [key: string]: ClaimSummaryWidget[] } = {};

  if (allWidgets.isSuccess && allWidgets.data.length > 0) {
    dynamicTabs = allWidgets.data.reduce(
      (acc, item) => {
        const { tab } = item;
        if (!acc[tab] && !Object.keys(PAGES).includes(tab)) {
          acc[tab] = [];
        }
        if (acc[tab]) {
          acc[tab].push(item);
        }
        return acc;
      },
      {} as { [key: string]: ClaimSummaryWidget[] }
    );
  }

  const navigateToSummaryTab = (event: SyntheticEvent) => {
    handleChangePage(event, PAGES.summary);
  };

  const onChangePage = (event: SyntheticEvent, page: string) => {
    handleChangePage(event, page);

    if (claimId) {
      if (page === PAGES.summary) {
        navigate(`${claimRoute.createPath(claimId)}`, { replace: true });
      } else {
        navigate(
          // @ts-ignore
          `${claimRoute.createPath(claimId)}?${PAGE_ID}=${page}`,
          { replace: true }
        );
      }
    }
  };

  if (isLoading || isSuperAdminQuery.isPending || currentUserQuery.isPending) {
    return (
      <Wrapper>
        <LoadingIcon />
      </Wrapper>
    );
  }

  if (isError || isSuperAdminQuery.isError || currentUserQuery.isError) {
    return (
      <Wrapper>
        <Stack spacing={2} sx={{ p: 3 }}>
          <TypographyWithTranslation
            variant="body1"
            i18nKey="common.loadClaimError"
          />
          <Box>
            <LinkWithTranslation
              i18nKey="common.goBackButton"
              component={LinkWithRef}
              to={claimsRoute.createPath()}
            />
          </Box>
        </Stack>
      </Wrapper>
    );
  }

  const { stage } = claim;
  const hasRefetchButton =
    getIsClaimProcessing(claim) || getIsClaimAwaitingInfo(claim);
  const isSuperAdminUser = isSuperAdminQuery.data;
  const claimStatus = getClaimStatus(claim);
  const isOldReviewScreen = pageId === PAGES.review;
  const claimDocuments = getClaimDocuments(claim);
  const documentsInAwaitingReview = claimDocuments.some(
    ({ stage }) => stage === AWAITING_REVIEW
  );

  const headerMeta = [
    {
      label: 'header.claimId',
      value: claim.id,
    },
    {
      label: 'header.lastUpdateTime',
      value: formatDocumentDate(claim.lastUpdatedOn),
    },
  ];

  return (
    <WithTranslationRoot namespace={CLAIM_PAGE}>
      <TabContext value={page}>
        <Wrapper>
          <Box sx={{ px: 2 }}>
            <Grid container spacing={2}>
              <Grid size={{ xs: 12 }}>
                <Stack
                  sx={{
                    gap: 1,
                  }}
                >
                  <Stack
                    direction="row"
                    spacing={2}
                    sx={{
                      alignItems: 'center',
                      display: isOldReviewScreen ? 'none' : 'flex',
                    }}
                  >
                    <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                      {claim.clientClaimId}
                    </Typography>
                    <Label
                      i18nKey={statusTranslation[stage]}
                      variant="ghost"
                      color={STAGE_LABEL_COLOR_MAP[stage]}
                    />
                  </Stack>
                  <Stack
                    direction={{ md: 'row' }}
                    spacing={2}
                    sx={{
                      display: isOldReviewScreen ? 'none' : 'flex',
                    }}
                  >
                    {headerMeta.map(({ label, value }) => (
                      <Stack
                        key={label}
                        direction={{ sm: 'row' }}
                        spacing={0.5}
                        sx={{
                          alignItems: 'baseline',
                        }}
                      >
                        <TypographyWithTranslation
                          i18nKey={label}
                          color="grey.500"
                          fontSize={12}
                        />
                        <Typography
                          variant="subtitle1"
                          sx={{
                            fontSize: 12,
                          }}
                        >
                          {value}
                        </Typography>
                      </Stack>
                    ))}
                  </Stack>

                  <ClaimViewTabs
                    isClaimCompleted={getIsClaimCompleted(claim)}
                    isSuperAdminUser={isSuperAdminUser}
                    claimStatus={claimStatus}
                    onChangePage={onChangePage}
                    dynamicTabs={dynamicTabs}
                    hasDocumentsInAwaitingReview={documentsInAwaitingReview}
                  />
                </Stack>
              </Grid>

              <Grid size={{ xs: 12 }}>
                <Stack
                  sx={{
                    gap: 4,
                  }}
                >
                  <PagesV2
                    claim={claim}
                    isPending={isPending}
                    currentUser={currentUserQuery.data}
                    navigateToSummaryTab={navigateToSummaryTab}
                    isRefetching={isRefetching}
                    hasRefetchButton={hasRefetchButton}
                    refetchClaim={refetch}
                    additionalTabs={dynamicTabs}
                  />
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </Wrapper>
      </TabContext>
    </WithTranslationRoot>
  );
}

export default ClaimViewV2;
