import type { BoxProps } from '@mui/material/Box';
import type { SxProps, Theme } from '@mui/material/styles';
import { Suspense } from 'react';
import Box from '@mui/material/Box';
import { LoadingIcon } from 'components/icons/LoadingIcon';

type IProps = BoxProps;

interface Props extends IProps {
  alt?: string;
  height?: number;
  onLoad?: any;
  render?: any;
  src: string;
  sx?: SxProps<Theme>;
  width?: number;
}

const cache = new Map();

function imagePromise(src: string): Promise<string> {
  return new Promise((resolve, reject) => {
    const i = new Image();
    i.onload = () => {
      i.decode()
        .then(() => resolve(src))
        .catch(reject);
    };
    i.onerror = reject;
    i.src = src;
  });
}

function useImage(src: string) {
  if (!cache.get(src)) {
    cache.set(src, {
      promise: imagePromise(src),
      cache: 'pending',
      error: null,
    });
  }

  cache
    .get(src)
    .promise.then((src: string) => {
      cache.set(src, {
        ...cache.get(src),
        cache: 'resolved',
        src,
      });
    })
    .catch((error: Error) =>
      cache.set(src, {
        ...cache.get(src),
        cache: 'rejected',
        error,
      })
    );

  if (cache.get(src).cache === 'resolved') {
    return { src: cache.get(src).src, isLoading: false, error: null };
  }

  if (cache.get(src).cache === 'rejected') {
    throw cache.get(src).error;
  }

  throw cache.get(src).promise;
}

function SuspenseImage({ src: source, height, width, alt, style }: Props) {
  const { src } = useImage(source);

  return (
    <img
      alt={alt}
      loading="lazy"
      src={src}
      height={height}
      width={width}
      style={style}
    />
  );
}

function LazyImage(props: Props) {
  const { sx, src: source, height, width, ...other } = props;

  const Loading = () => (
    <Box
      sx={{
        ...sx,
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
      }}
      {...other}
    >
      <LoadingIcon />
    </Box>
  );

  return (
    <Suspense fallback={<Loading />}>
      <SuspenseImage {...props} />
    </Suspense>
  );
}

export { LazyImage };
