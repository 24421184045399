import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query';
import {
  updateDataLayer,
  updateWindowPerformanceObject,
  getEventTimestamp,
} from 'analytics/utils';
import { toast } from 'components/toast';

interface Meta {
  errorMessage?: string | ((error: Error) => string);
  loadingMessage?: string;
  successMessage?: string;
  gtmEventStart?: string;
  gtmEventEnd?: string;
  documentId?: string;
  claimId?: string;
}

const toastIds = new Map<number, string>();
const errorOptions = {
  dismissBtn: true,
  duration: 60000, // default 60 seconds
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: Infinity,
      refetchInterval: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: 0,
    },
  },
  queryCache: new QueryCache({
    onError: (error, query) => {
      if (query.meta?.errorMessage) {
        if (typeof query.meta?.errorMessage === 'function') {
          toast.error(query.meta.errorMessage(error), errorOptions);
        }

        if (typeof query.meta?.errorMessage === 'string') {
          toast.error(query.meta.errorMessage, errorOptions);
        }
      }
    },
  }),
  mutationCache: new MutationCache({
    onMutate: (_variables, mutation) => {
      mutation.meta?.gtmEventStart &&
        updateWindowPerformanceObject(mutation.meta?.gtmEventStart as string);
      if (mutation.meta?.errorMessage) {
        const id = toast.loading((mutation.meta as Meta).loadingMessage);
        toastIds.set(mutation.mutationId, id);
      }
    },
    onSuccess: (_data, _variables, _context, mutation) => {
      if (mutation.meta?.successMessage) {
        toast.success((mutation.meta as Meta).successMessage, {
          id: toastIds.get(mutation.mutationId),
        });
      }
    },
    onError: (error, _variables, _context, mutation) => {
      if (mutation.meta?.errorMessage) {
        if (typeof mutation.meta?.errorMessage === 'function') {
          toast.error(mutation.meta.errorMessage(error), {
            ...errorOptions,
            id: toastIds.get(mutation.mutationId),
          });
        }

        if (typeof mutation.meta?.errorMessage === 'string') {
          toast.error(mutation.meta.errorMessage, {
            ...errorOptions,
            id: toastIds.get(mutation.mutationId),
          });
        }
      }
    },
    onSettled: (_data, _error, _variables, _, mutation) => {
      if (mutation.meta?.gtmEventStart && mutation.meta?.gtmEventEnd) {
        const dataLayerEvent = {
          event: mutation.meta?.gtmEventEnd as string,
          performance:
            (Date.now() -
              getEventTimestamp(mutation.meta?.gtmEventStart as string)) /
            1000,
        } as { event: string; [key: string]: string | number };

        if (mutation.meta?.documentId) {
          dataLayerEvent.documentId = mutation.meta.documentId as string;
        }

        if (mutation.meta?.claimId) {
          dataLayerEvent.claimId = mutation.meta.claimId as string;
        }

        updateDataLayer(dataLayerEvent);
      }
    },
  }),
});

export default queryClient;
export type { Meta };
