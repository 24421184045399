import { createSelector } from 'reselect';
import type {
  Claim,
  ClaimFeatures,
  ClaimObservation,
  ClaimsPage,
} from 'types/Claims';
import {
  AWAITING_INFO,
  AWAITING_OCR_RESULTS,
  AWAITING_REVIEW,
  COMPLETED,
  ERROR,
  ObservationType,
  PROCESSING,
  REJECTED,
} from 'constants/claims';
import {
  transformClaimPagesUrlsResponse,
  transformClaimResponse,
  transformClaimsResponse,
} from 'api/transformers/claims';
import { not } from 'utils/not';

import { selectState } from './utils.ts';

export const selectClaimResponse = createSelector(
  selectState,
  transformClaimResponse
);

export const selectClaimsResponse = createSelector(
  selectState,
  transformClaimsResponse
);

export const selectClaimsPageData = createSelector(
  selectClaimsResponse,
  (response) => response.data
);

export const selectClaimsMetadataSortedColumns = createSelector(
  selectClaimsResponse,
  (response) => response.metadata?.sortedColumns || []
);

export const selectClaimPagesUrlsResponse = createSelector(
  selectState,
  transformClaimPagesUrlsResponse
);

export function getClaimsTotal(data: ClaimsPage) {
  return data.total;
}

export function getClaimStatus(claim: Claim) {
  return claim.stage;
}

export function getIsClaimCompleted(claim: Claim) {
  return getClaimStatus(claim) === COMPLETED;
}

export function getIsClaimAwaitingInfo(claim: Claim) {
  return getClaimStatus(claim) === AWAITING_INFO;
}

export function getIsClaimError(claim: Claim) {
  return getClaimStatus(claim) === ERROR;
}

export function getIsClaimProcessing(claim: Claim) {
  return getClaimStatus(claim) === PROCESSING;
}

export function getIsClaimAwaitingReview(claim: Claim) {
  return getClaimStatus(claim) === AWAITING_REVIEW;
}

export function getIsClaimAwaitingOcrResults(claim: Claim) {
  return getClaimStatus(claim) === AWAITING_OCR_RESULTS;
}

export function getIsClaimRejected(claim: Claim) {
  return getClaimStatus(claim) === REJECTED;
}

function getObservationType(observation: ClaimObservation) {
  return observation.type;
}

function getClaimProperties(claim: Claim) {
  return claim.properties;
}

function getClaimMetadata(claim: Claim) {
  return claim.metadata;
}

export function getClaimType(claim: Claim) {
  return getClaimProperties(claim)?.claimType;
}

export function getIsClaimObservationDocumentType(
  observation: ClaimObservation
) {
  return getObservationType(observation) === ObservationType.DOCUMENT;
}

export function getIsClaimObservationEnrichmentType(
  observation: ClaimObservation
) {
  return getObservationType(observation) === ObservationType.ENRICHMENT;
}

function getObservationContent(observation: ClaimObservation) {
  return observation.content;
}

function getObservationContentStage(observation: ClaimObservation) {
  return getObservationContent(observation).stage;
}

export function getObservationUrls(observation: ClaimObservation) {
  return observation.content.urls;
}

export function getIsClaimObservationContentStageRejected(
  observation: ClaimObservation
) {
  return getObservationContentStage(observation) === REJECTED;
}

export function getIsClaimObservationContentStageCompleted(
  observation: ClaimObservation
) {
  return getObservationContentStage(observation) === COMPLETED;
}

export function getIsNotClaimObservationContentStageRejected(
  observation: ClaimObservation
) {
  return not(getIsClaimObservationContentStageRejected)(observation);
}

export function getClaimFeatures(claim: Claim) {
  return claim.features;
}

export function getClaimFields(features: ClaimFeatures) {
  return features.fields;
}

export function getClaimLines(features: ClaimFeatures) {
  return features.lines;
}

export function getClaimBusinessUnit(claim: Claim) {
  return (
    getClaimProperties(claim)?.businessUnit ??
    (getClaimMetadata(claim)?.businessUnit as string)
  );
}

export function getClaimDocuments(claim: Claim) {
  return claim.documents;
}
