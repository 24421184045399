import {
  matchPath,
  matchRoutes,
  Navigate,
  Outlet,
  useLocation,
} from 'react-router-dom';
import { FeatureFlagsProvider } from 'components/FeatureFlags';
import { useBoolean } from 'components/customHooks/useBoolean';
import { SiteLayout } from 'components/SiteLayout';
import { Toaster } from 'components/toast';
import {
  TypographyWithTranslation,
  useTranslationRoot,
} from 'components/with-translation';
import { useLocalStorage } from 'components/customHooks/useLocalStorage';
import { generalConfig } from 'config';
import {
  ADMIN,
  CLAIM,
  CLAIM_ADMIN,
  CLAIM_REVIEW,
  CLIENT_ADMIN,
  DOCUMENT_ADMIN,
  HITL,
  HITL_AND_MI,
  MI,
  POV,
  SUPER_ADMIN,
} from 'constants/roles';
import { DASHBOARD_PAGE } from 'constants/translation-keys';
import { STAGE } from 'constants/route-keys';
import { AWAITING_REVIEW } from 'constants/claims';
import performanceRoute from 'pages/Performance/performance.route';
import hitlRoute from 'pages/HitL/hitl.route';
import fileUploadRoute from 'pages/FileUpload/file-upload.route.tsx';
import { SomethingWentWrong } from 'pages/Site/SomethingWentWrong';
import claimsRoute from 'pages/Claims/claims.route';
import loginRoute from 'pages/Login/login.route';
import Splash from 'pages/Site/Splash.tsx';
import { routes } from 'routes/routes';
import { useCurrentUserRole } from 'state/queries/current-user';
import type { CustomRouteObject } from 'routes/types';

import { CookieBanner } from './CookieBanner';
import { DashboardHeader } from './DashboardHeader';
import { DashboardNavBar } from './DashboardNavBar';

const roleRedirectPathMap: {
  [k: string]: string;
} = {
  [ADMIN]: performanceRoute.path,
  [CLAIM]: claimsRoute.path,
  [CLAIM_REVIEW]: claimsRoute.createPath({
    search: `?${STAGE}=${AWAITING_REVIEW}`,
  }),
  [HITL]: hitlRoute.path,
  [HITL_AND_MI]: hitlRoute.path,
  [MI]: performanceRoute.path,
  [POV]: fileUploadRoute.path,
  [SUPER_ADMIN]: performanceRoute.path,
  [CLAIM_ADMIN]: fileUploadRoute.path,
  [DOCUMENT_ADMIN]: fileUploadRoute.path,
  [CLIENT_ADMIN]: fileUploadRoute.path,
};

function getPermissions(pathname: string) {
  const matchedRoutes = matchRoutes(routes, pathname);

  if (matchedRoutes?.length === 1) {
    return (matchedRoutes[0].route as CustomRouteObject).permission;
  }

  return (matchedRoutes?.slice(1)[0].route as CustomRouteObject).permission;
}

function DashboardLayout() {
  const { pathname } = useLocation();
  const { t } = useTranslationRoot(DASHBOARD_PAGE);
  const [isOpen, onOpenSidebar, onCloseSidebar] = useBoolean();
  const [localStore, setLocalStore] = useLocalStorage(
    generalConfig.miniNavbarStore,
    false
  );
  const [isMiniNavBarOpen, , , toggleMiniNavbar] = useBoolean(localStore);
  const {
    data: currentUserRole,
    isError,
    isPending,
    isSuccess,
  } = useCurrentUserRole();
  const permissions = getPermissions(pathname);

  if (isPending) {
    return (
      <Splash>
        <TypographyWithTranslation i18nKey="loading" />
      </Splash>
    );
  }

  function getRedirectPath(role: string) {
    return roleRedirectPathMap[role];
  }

  if (isError) {
    return (
      <SomethingWentWrong
        error={new Error(t('common.loginFailedApi') as string)}
      />
    );
  }

  if (matchPath('/*', pathname)) {
    if (
      isSuccess &&
      currentUserRole &&
      !permissions?.includes(currentUserRole)
    ) {
      return (
        <SomethingWentWrong
          error={new Error(t('common.loginFailedPermissions') as string)}
        />
      );
    }
  }

  if (isSuccess && matchPath('/', pathname)) {
    if (currentUserRole) {
      return <Navigate to={getRedirectPath(currentUserRole)} />;
    } else {
      return <Navigate to={loginRoute.createPath()} />;
    }
  }

  return (
    <>
      <FeatureFlagsProvider>
        <CookieBanner>
          <SiteLayout
            isMiniNavBarOpen={isMiniNavBarOpen}
            header={
              <DashboardHeader
                isMiniNavBarOpen={isMiniNavBarOpen}
                onOpenSidebar={onOpenSidebar}
              />
            }
            navbar={
              <DashboardNavBar
                isMiniNavBarOpen={isMiniNavBarOpen}
                isOpenSidebar={isOpen}
                onCloseSidebar={onCloseSidebar}
                setLocalStore={setLocalStore}
                toggleMiniNavbar={toggleMiniNavbar}
              />
            }
          >
            <Outlet />
          </SiteLayout>
        </CookieBanner>
      </FeatureFlagsProvider>
      <Toaster />
    </>
  );
}

export default DashboardLayout;
