import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import type { ClaimSproutRecommendation } from 'types/Claims';
import { SvgIconStyle } from 'components/SvgIconStyle';
import { BADGE_ICON } from 'constants/public-icons';

interface ReviewFormRecommendationProps {
  sproutRecommendation: ClaimSproutRecommendation[];
}

function ReviewFormRecommendation({
  sproutRecommendation,
}: ReviewFormRecommendationProps) {
  if (sproutRecommendation?.length) {
    const recommendationMarkup = sproutRecommendation.reduce(
      (acc, obj: ClaimSproutRecommendation) => {
        return `${acc}${Object.values(obj).join('\n')} `;
      },
      ''
    );

    return (
      <Stack
        direction="row"
        sx={{
          position: 'relative',
          alignItems: 'center',
          justifyItems: 'center',
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Tooltip
            arrow
            title={
              <Typography variant="body2">{recommendationMarkup}</Typography>
            }
            disableInteractive
          >
            <IconButton>
              <SvgIconStyle src={BADGE_ICON} height={20} width={20} />
            </IconButton>
          </Tooltip>
        </Box>
      </Stack>
    );
  }

  return null;
}

export { ReviewFormRecommendation };
