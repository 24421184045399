import { queryOptions, useQuery } from '@tanstack/react-query';
import type { AuthApiResponse } from 'api/transformers/types/auth';
import api, { makeApiLink } from 'api/api';
import { CURRENT_USER } from 'constants/api-endpoints';
import {
  isSuperAdminRole,
  selectCurrentUserResponse,
  selectEmail,
  selectFirstName,
  selectFullName,
  selectRole,
} from 'state/selectors/current-user.ts';

export async function authUser(): Promise<AuthApiResponse> {
  return await api.get(makeApiLink('auth'));
}

const options = {
  queryKey: [CURRENT_USER],
  queryFn: authUser,
  staleTime: Infinity,
  gcTime: Infinity,
};

export function useGetCurrentUser() {
  return useQuery(
    queryOptions({ ...options, select: selectCurrentUserResponse })
  );
}

export function useCurrentUserFullName() {
  return useQuery(queryOptions({ ...options, select: selectFullName }));
}

export function useCurrentUserFirstName() {
  return useQuery(queryOptions({ ...options, select: selectFirstName }));
}

export function useCurrentUserEmail() {
  return useQuery(queryOptions({ ...options, select: selectEmail }));
}

export function useCurrentUserRole() {
  return useQuery(queryOptions({ ...options, select: selectRole }));
}

export function useIsSuperAdminRole() {
  return useQuery(queryOptions({ ...options, select: isSuperAdminRole }));
}
